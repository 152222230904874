import { mean, omit, pick } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

const latestDrybackBundle = createAsyncResourceBundle({
  name: 'latestDryback',
  getPromise: ({ apiFetch }) => {
    const start = getDateTime('now').minus({ days: 1 }).startOf('day').toISO()
    return apiFetch('/dryback/', { start, exclude: 'dryback_nutrients' })
  },
})

export default {
  ...latestDrybackBundle,
  selectLatestDrybackByZone: createSelector(
    'selectLatestDryback',
    latestDryback => {
      if (!latestDryback) return {}
      return latestDryback.reduce((dbz, { dataType, ts, value, zoneId }) => {
        // More recent event already captured
        if (dbz[zoneId] && dbz[zoneId].ts > ts) return dbz
        // Add additional data to dryback event entry
        if (dbz[zoneId] && dbz[zoneId].ts === ts) {
          dbz[zoneId][dataType] = value
        }
        // Add new dryback event entry
        if (!dbz[zoneId] || dbz[zoneId].ts < ts) {
          dbz[zoneId] = { ts, [dataType]: value }
        }
        return dbz
      }, {})
    },
  ),
  selectLatestDrybackByRoom: createSelector(
    'selectActiveRooms',
    'selectLatestDrybackByZone',
    (rooms, latestDrybackByZone) => Object.entries(rooms).reduce((dbr, [roomId, { zones }]) => {
      const zoneData = pick(zones, latestDrybackByZone)
      if (!Object.keys(zoneData).length) return dbr
      const drybackValues = Object.values(zoneData).reduce((values, data) => {
        Object.entries(omit(['ts'], data)).forEach(([dataType, value]) => {
          if (!values[dataType]) {
            values[dataType] = []
          }
          values[dataType].push(value)
        })
        return values
      }, {})
      const drybackAverages = Object.entries(drybackValues).reduce((avg, [dataType, zoneValues]) => ({
        ...avg, [dataType]: mean(zoneValues)
      }), {})
      return {
        ...dbr,
        [roomId]: {
          ...drybackAverages,
          zoneData,
        },
      }
    }, {}),
  ),
  reactFetchLatestDryback: createAppIsReadySelector({
    dependencies: [
      'selectAvailableFeatures',
      'selectLatestDrybackShouldUpdate',
      'selectRouteInfo'
    ],
    resultFn: (availableFeatures, shouldUpdate, { pattern }) => {
      if (availableFeatures.has('DRYBACK_DATA') && shouldUpdate && pattern === '/dashboard') {
        return { actionCreator: 'doFetchLatestDryback' }
      }
      return null
    },
  }),
}
