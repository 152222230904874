import PropTypes from 'prop-types'
import {
  compose,
  evolve,
  pick,
  trim,
} from 'ramda'

import { shape as pesticideShape } from '~/src/IPM/Pesticide/validation'
import {
  nonEmptyStringOrNil,
  toISOOrNil,
  validIdOrNil,
  validIdOrUndefined,
  validNumberOrNil,
} from '~/src/Lib/Data'
import {
  datePropType,
  idAndNameShape,
  idPropType,
  nullType,
} from '~/src/Lib/PropTypes'
import { getDateTime } from '~/src/Lib/Utils'

import { FACILITY_WIDE } from '../constants'

export const attachmentShapeDef = {
  filename: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
  mimetype: PropTypes.string.isRequired,
  name: PropTypes.string,
  url: PropTypes.string.isRequired,
}
export const attachmentShape = PropTypes.shape(attachmentShapeDef)

const shapeConfig = {
  id: idPropType,
  category: PropTypes.oneOfType([
    idAndNameShape,
    idPropType
  ]),
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionMentions: PropTypes.arrayOf(idPropType),
  facility: idPropType,
  facilityWide: PropTypes.bool,
  room: PropTypes.oneOfType([
    idPropType,
    PropTypes.oneOf([FACILITY_WIDE])
  ]),
  zone: idPropType.nullable,
  assigned: idPropType.nullable,
  assignees: PropTypes.arrayOf(PropTypes.shape({
    id: idPropType,
    lastStartTs: PropTypes.string,
    membership: idPropType,
    note: idPropType,
    timeSpent: PropTypes.string
  })),
  completedOn: datePropType,
  startDate: datePropType,
  startedOn: datePropType,
  startedBy: idPropType.nullable,
  dueDate: datePropType,
  ts: datePropType,
  recurrenceRule: PropTypes.shape({
    count: PropTypes.number,
    customEnd: PropTypes.number,
    daysOfWeek: PropTypes.arrayOf(PropTypes.string),
    daysInPhase: PropTypes.arrayOf(PropTypes.string),
    interval: PropTypes.number,
    untilEndOf: datePropType,
    unit: PropTypes.string,
    phaseType: PropTypes.string,
  }),
  relativeStartDay: PropTypes.number,
  relativeStageDay: PropTypes.number,
  relativeStartTime: datePropType,
  reminderUnit: PropTypes.string,
  reminderAmount: PropTypes.number,
  priority: PropTypes.number,
  phase: idPropType.nullable,
  attachment: PropTypes.oneOfType([
    nullType,
    PropTypes.arrayOf(PropTypes.shape({
      filename: PropTypes.string,
      handle: PropTypes.string,
      mimetype: PropTypes.string,
      originalPath: PropTypes.string,
      size: PropTypes.number,
      source: PropTypes.string,
      status: PropTypes.string,
      uploadId: PropTypes.string,
      url: PropTypes.string,
    }))
  ]),
  metadata: PropTypes.shape({
    pesticide: PropTypes.oneOfType([pesticideShape, idPropType]),
    applicationRate: PropTypes.string,
    targetPest: PropTypes.string,
  })
}
export const shape = PropTypes.shape(shapeConfig)

const transforms = {
  id: Number,
  assigned: validIdOrNil,
  category: validIdOrNil,
  description: trim,
  facility: validIdOrNil,
  phase: validIdOrUndefined,
  reminderAmount: validNumberOrNil,
  reminderUnit: nonEmptyStringOrNil,
  room: validIdOrNil,
  title: trim,
  ts: toISOOrNil,
  zone: validIdOrNil,
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick(Object.keys(shapeConfig))
)

export const prepareData = raw => {
  const clean = cleaner(raw)
  const { dueDate: dueDateRaw, startDate: startDateRaw } = clean
  const dueDate = dueDateRaw ? getDateTime(dueDateRaw).toUTC() : null
  const startDate = startDateRaw ? getDateTime(startDateRaw).toUTC() : null

  return {
    ...clean,
    dueDate,
    startDate
  }
}
