import {
  add,
  equals,
  prop,
  uniqBy,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { singularize } from 'inflection'
import { DateTime } from 'luxon'
import { normalize } from 'normalizr'

import { TIER_ALLOWED_ANNOTATION_TYPES } from '~/src/Flags/Tier/constants'
import {
  actionFactory,
  doEntitiesReceived,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { Annotation, annotationSchemaNormalizer } from '~/src/Store/Schemas'

import { NO_MORE_ITEMS, NO_MORE_ITEMS_ID } from './constants'

const logger = createLogger('Journal/bundle')

export const NO_MORE_ITEMS_ID_LOWERCASE = NO_MORE_ITEMS_ID.toLowerCase()
const NO_MORE_BEFORE_ITEM = { uuid: (`${NO_MORE_ITEMS_ID}_BEFORE_0`).toLowerCase(), data: NO_MORE_ITEMS }
const NO_MORE_AFTER_ITEM = { uuid: (`${NO_MORE_ITEMS_ID}_AFTER_0`).toLowerCase(), data: NO_MORE_ITEMS }

const getUniqueJournalItems = uniqBy(prop('uuid'))
export const fetchJournal = getAsyncActionIdentifiers('fetch', 'journal')
export const doClearJournal = actionFactory('clear', 'journal')

const initialState = { loading: false, after: 0, before: 0, params: EMPTY_OBJECT, items: EMPTY_ARRAY }

export default {
  name: 'journal',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case fetchJournal.types.start: {
        const { meta = EMPTY_OBJECT } = action
        return {
          ...state,
          ...(meta.replace ? initialState : state),
          params: action.payload,
          loading: true,
        }
      }
      case fetchJournal.types.succeed: {
        const {
          before: oldBefore = 0,
          after: oldAfter = 0,
          items: oldItems
        } = state
        let { after, before, data: items } = action.payload
        items = items.map(item => (item.schema ? { ...item, schema: annotationSchemaNormalizer(item.schema) } : item))
        const { replace } = action.meta
        const isBefore = before < oldBefore

        before = replace || isBefore ? before : oldBefore
        after = !replace && isBefore ? Math.min(after, oldAfter) : after

        const totalItems = [
          !replace ? (oldItems?.length ?? 0) : 0,
          items?.length ?? 0,
          before ?? 0,
          after ?? 0,
        ].reduce(add)
        if (totalItems && before === 0 && items[items.length - 1] !== NO_MORE_BEFORE_ITEM) {
          items.unshift(NO_MORE_BEFORE_ITEM)
        }
        if (totalItems && after === 0 && items[0] !== NO_MORE_AFTER_ITEM) {
          items.push(NO_MORE_AFTER_ITEM)
        }
        items = getUniqueJournalItems(replace ? items : [...items, ...oldItems])
        /* eslint-disable no-underscore-dangle */
        return {
          ...state,
          lastFetched: Date.now(),
          loading: false,
          after,
          before,
          items: items.sort((a, b) => {
            if (a.timestamp && b.timestamp) {
              return new Date(b.timestamp) - new Date(a.timestamp)
            }
            if (a === NO_MORE_AFTER_ITEM) {
              return -1
            }
            if (b === NO_MORE_AFTER_ITEM) {
              return 1
            }
            if (a === NO_MORE_BEFORE_ITEM) {
              return 1
            }
            if (b === NO_MORE_BEFORE_ITEM) {
              return -1
            }
            return 0
          }),
        }
      }
      /* eslint-enable no-underscore-dangle */
      case fetchJournal.types.fail:
        return {
          ...state,
          loading: false,
          error: action.error,
        }
      case doClearJournal.type:
        return initialState
      default:
        return state
    }
  },
  doClearJournal,
  doJournalFetch: (payloadRaw, options = { replace: true, force: false }) => async ({ store, dispatch, apiFetch }) => {
    const journal = store.selectJournal()
    if (!payloadRaw.start && !payloadRaw.end) {
      return { ...journal, shortCircuit: true }
    }
    const { MINIMUM_FROM: minimumFrom } = store.selectConfig()
    const boundedStart = DateTime.max(getDateTime(payloadRaw.start), getDateTime(minimumFrom)).toISODate()
    const payload = { ...payloadRaw, start: boundedStart }
    // Debounce
    if (equals(journal.params, payload) && (journal.loading || Date.now() - journal.lastFetched < 5000)) {
      return { ...journal, debounced: true }
    }
    dispatch({ type: fetchJournal.types.start, payload, meta: options })
    let response
    try {
      response = await apiFetch('/journal/', payload)
      if (boundedStart === minimumFrom) {
        response.before = 0
      }
      if (Array.isArray(response?.data)) {
        const { entities } = normalize(response.data, [Annotation])
        dispatch(doEntitiesReceived(entities))
      }
      dispatch({
        type: fetchJournal.types.succeed,
        payload: response,
        meta: options
      })
    } catch (error) {
      response = error
      dispatch({ type: fetchJournal.types.fail, error })
    }
    return response
  },
  selectJournal: prop('journal'),
  selectJournalItems: createSelector(
    'selectJournal',
    'selectFacility',
    (journal, facility) => journal?.items.filter(item => TIER_ALLOWED_ANNOTATION_TYPES[facility.tier][singularize(item.schema)])
  ),
  selectJournalParams: createSelector(
    'selectJournal',
    prop('params'),
  ),
  selectJournalIsLoading: createSelector(
    'selectJournal',
    prop('loading'),
  ),
}
