import memoizeOne from 'memoize-one'
import {
  clone,
  groupBy,
  isEmpty,
  map,
  mean,
  omit,
  prop,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { singularize } from 'inflection'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT, getDateTime, parseApiErrors } from '~/src/Lib/Utils'
import { CHART_FETCH } from '~/src/Store/bundles/chart'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { EMPTY_CHART_DATA_OBJECT } from './utils'

const displayName = 'DripDrain/Chart'
const logger = createLogger(displayName)
const name = 'dripDrainChart'

const DRIP_DRAIN_CHART_FETCH_FAILED = 'DRIP_DRAIN_CHART_FETCH_FAILED'
const DRIP_DRAIN_CHART_FETCH_FINISHED = 'DRIP_DRAIN_CHART_FETCH_FINISHED'
const DRIP_DRAIN_CHART_FETCH_STARTED = 'DRIP_DRAIN_CHART_FETCH_STARTED'

const initialState = {
  charts: {},
  errors: {},
  inflight: {},
  shouldFetch: {},
}

const defaultApiParams = {
  start: undefined,
  end: undefined,
  harvest: undefined,
  room: undefined,
}

const groupEventsByDay = groupBy(({ x }) => getDateTime(x).toISO().split('T')[0])
const getEventInterval = memoizeOne(data => {
  if (!data) return 0
  const grouped = groupEventsByDay(data)
  const intervals = Object.values(grouped).flatMap(dayData => dayData.slice(1).map((datum, index) => {
    const { [index]: prev } = dayData
    return getDateTime(datum.x).diff(getDateTime(prev.x)).as('minutes')
  }))
  return mean(intervals)
})

const dripDrainBundle = {
  name,
  reducer: (state = initialState, action = {}) => {
    if (action.type === CHART_FETCH.start) {
      return {
        ...state,
        shouldFetch: { ...state.shouldFetch, [action.payload.chartId]: true }
      }
    }
    if (!action.type || !action.type.startsWith('DRIP_DRAIN_CHART_')) return state
    const { meta, payload, type } = action
    const { chartId } = meta

    switch (type) {
      case DRIP_DRAIN_CHART_FETCH_FAILED:
        return {
          ...state,
          errors: {
            ...state.errors,
            [chartId]: {
              error: action.error,
              params: action.payload,
              ts: Date.now(),
            }
          },
          inflight: { ...state.inflight, [chartId]: false },
        }
      case DRIP_DRAIN_CHART_FETCH_FINISHED:
        return {
          ...state,
          charts: { ...state.charts, [chartId]: payload },
          inflight: { ...state.inflight, [chartId]: false },
        }
      case DRIP_DRAIN_CHART_FETCH_STARTED:
        return {
          ...state,
          errors: omit([chartId], state.errors),
          inflight: { ...state.inflight, [chartId]: true },
          shouldFetch: { ...state.shouldFetch, [chartId]: false },
        }
      default:
        return state
    }
  },
  selectDripDrainChartRoot: prop(name),
  selectDripDrainCharts: createSelector('selectDripDrainChartRoot', prop('charts')),
  selectDripDrainChart: createSelector(
    'selectDripDrainCharts',
    'selectDataTypes',
    'selectUnits',
    'selectZones',
    'selectRoomDashboardRouteData',
    'selectRoomDashboardState',
    (
      dripDrainChartsRaw,
      dataTypeEntities,
      units,
      zones,
      { chartId } = EMPTY_OBJECT,
      { individualSensors = false } = EMPTY_OBJECT
    ) => {
      const chartData = clone(EMPTY_CHART_DATA_OBJECT)

      if (!(dataTypeEntities && units && zones)) {
        logger.warn('Entities required:', map(Boolean, { units, zones }))
        return chartData
      }
      if (!dripDrainChartsRaw || isEmpty(dripDrainChartsRaw) || !(chartId in dripDrainChartsRaw)) {
        logger.warn('Expected dripDrain chart data, received:', dripDrainChartsRaw)
        return chartData
      }
      const { [chartId]: dripDrainChartData } = dripDrainChartsRaw
      const { data, dataTypes, graphBounds, unitBounds } = dripDrainChartData
      const { irrigationDrip, irrigationDrain } = dataTypes
      chartData.dataTypes = [irrigationDrip, irrigationDrain].map(dt => dt.key)

      // Bounds
      if (graphBounds) {
        Object.entries(graphBounds).forEach(([key]) => {
          const { unit } = irrigationDrip
          const { [unit]: bounds } = unitBounds
          const dripKey = `${irrigationDrip.key}:zone:${key}`
          const drainKey = `${irrigationDrain.key}:zone:${key}`
          chartData.bounds.graph[dripKey] = bounds
          chartData.bounds.graph[drainKey] = bounds
          chartData.bounds.dataType[irrigationDrip.key] ??= bounds
          chartData.bounds.dataType[irrigationDrain.key] ??= bounds
        })
      }

      // UnitBounds
      chartData.bounds.unit = unitBounds

      // Data
      Object.entries(data).forEach(([key, entries]) => {
        const dripKey = `${irrigationDrip.key}:zone:${key}`
        const drainKey = `${irrigationDrain.key}:zone:${key}`
        const cleanEntries = entries
          .filter(({ values }) => values.length === 3 && values.every(v => v != null && v >= 0))
          .sort((a, b) => a.x[1].localeCompare(b.x[1]))
        chartData.data = cleanEntries.reduce((
          finalData,
          current,
          index
        ) => {
          const { x: originalX, values, volumeBreakdown } = current
          const [, x] = originalX
          const xDate = getDateTime(x).toISO().split('T')[0]
          const dripDatum = {
            x,
            y: values[0],
            timing: originalX,
            event: 1
          }
          if (index > 0) {
            const previous = finalData[dripKey][finalData[dripKey].length - 1]

            const { x: prevX, event: prevEvent } = previous
            const prevXDate = getDateTime(prevX).toISO().split('T')[0]
            if (xDate !== prevXDate) {
              dripDatum.event = 1
            } else {
              dripDatum.event = prevEvent + 1
            }
          }
          const drainDatum = {
            x,
            y: values[1],
            percentage: values[2],
          }
          if (individualSensors) {
            dripDatum.volumeBreakdown = volumeBreakdown.drip
            drainDatum.volumeBreakdown = volumeBreakdown.drain
          }
          finalData[dripKey].push(dripDatum)
          finalData[drainKey].push(drainDatum)
          return finalData
        }, { [dripKey]: [], [drainKey]: [] })
      })

      // Graphs
      chartData.graphs = Object.entries(chartData.bounds.graph).map(([graphKey, bounds]) => {
        const [key, _, zoneStr] = graphKey.split(':')
        const isDrain = key.includes('drain')
        const zoneId = Number(zoneStr)

        const { [key]: dataType = {} } = dataTypeEntities
        const { color, shortName, template, unit } = dataType
        const { name: title } = zones[zoneId] ?? {}
        return {
          bounds,
          color,
          dataType: key,
          depth: null,
          id: graphKey,
          interval: getEventInterval(chartData.data[graphKey]),
          inverted: isDrain,
          name: shortName,
          template,
          title,
          type: isDrain ? 'drainBar' : 'bar',
          unit,
          zone: Number(zoneId),
        }
      })

      return { [chartId]: chartData }
    },
  ),
  selectIrrigationInflight: createSelector('selectDripDrainChartRoot', prop('inflight')),
  selectIrrigationShouldFetch: createSelector('selectDripDrainChartRoot', prop('shouldFetch')),
  doFetchIrrigation: (params = defaultApiParams) => ({ apiFetch, dispatch }) => {
    const { harvest, room } = params
    let chartId
    if (harvest) {
      chartId = `harvests_${harvest}`
    }
    if (room) {
      chartId = `rooms_${room}`
    }
    dispatch({
      type: DRIP_DRAIN_CHART_FETCH_STARTED,
      payload: params,
      meta: { chartId }
    })
    return apiFetch(`/rooms/${room}/drip_drain_chart/`, { ...defaultApiParams, ...params }).then(response => {
      dispatch({
        type: DRIP_DRAIN_CHART_FETCH_FINISHED,
        payload: response,
        meta: { chartId }
      })
      return response
    }).catch(e => {
      const error = parseApiErrors(e)
      dispatch({
        type: DRIP_DRAIN_CHART_FETCH_FAILED,
        error,
        payload: params,
        meta: { chartId }
      })
    })
  },
  reactIrrigationChartFetch: createAppIsReadySelector(
    {
      dependencies: [
        'selectAvailableFeatures',
        'selectRoomDashboardChartParams',
        'selectIrrigationShouldFetch',
        'selectRoomDashboardViewMode',
      ],
      resultFn: (availableFeatures, [chartId, expectedChartParams], dripDrainShouldFetch) => {
        const allowed = availableFeatures.has('DRIP_DRAIN_CHART')
        const hasParams = Boolean(chartId && expectedChartParams)
        if (!allowed || !hasParams) return null

        const { [chartId]: shouldFetch } = dripDrainShouldFetch
        if (shouldFetch) {
          const [schema, idStr] = chartId.split('_')
          const entityId = Number(idStr)
          const { start: roomChartStart, end } = expectedChartParams
          const start = getDateTime(roomChartStart).toUTC().toISO()

          return { actionCreator: 'doFetchIrrigation', args: [{ [singularize(schema)]: entityId, start, end }] }
        }
        return null
      }
    }
  )
}

export default dripDrainBundle
