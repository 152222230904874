/* eslint-disable no-underscore-dangle */
import { useConnect } from 'redux-bundler-hook'

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import appConfig from '~/src/App/config'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'
import { repr, shallowEqualsMemoizer } from '~/src/Lib/Utils'

import { buildTheme, palettes } from './mui'

const logger = createLogger('UI/Theme')

export { palettes }

const themes = {
  _APP: appConfig.APP,
  get APP() {
    return this._APP
  },
  set APP(value) {
    this._APP = value
  },
  get DEFAULT() {
    return this[this.APP || 'AROYA'] ?? {}
  },
  get CULTIVATION() {
    return this[`${this.APP || 'AROYA'}_CULTIVATION`]
  },
  get AROYA() {
    this._AROYA ??= buildTheme(palettes.AROYA)
    return this._AROYA
  },
  get AROYA_CULTIVATION() {
    if ('_AROYA_CULTIVATION' in this === false) {
      this._AROYA_CULTIVATION = buildTheme(palettes.AROYA_CULTIVATION)
    }
    return this._AROYA_CULTIVATION
  },
  get POMETA() {
    if ('_POMETA' in this === false) {
      this._POMETA = buildTheme(palettes.POMETA)
    }
    return this._POMETA
  },
  get POMETA_CULTIVATION() {
    return this.POMETA
  }
}

export const CultivationTheme = props => {
  const { config } = useConnect('selectConfig')
  const { [`${config.APP}_CULTIVATION`]: theme = buildTheme(palettes.AROYA_CULTIVATION) } = themes
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider {...props} theme={theme} />
    </StyledEngineProvider>
  )
}

const colorsByKeyThemeBuilder = shallowEqualsMemoizer((theme, colorsByKey) => {
  const { augmentColor } = theme.palette

  const newColors = Object.entries(colorsByKey).reduce((colors, [key, color]) => {
    if (key.match(/^[a-z]/) && color?.main) {
      colors[key] = augmentColor({ color, name: key })
      if (key.toUpperCase() in colorsByKey) {
        colors[key.toUpperCase()] = colors[key]
      }
    }
    return colors
  }, {})

  return {
    ...theme,
    palette: new Proxy({
      ...theme.palette,
      ...newColors,
    }, {
      get(target, prop) {
        if (prop in target) return target[prop]
        if (prop === 'vars') return undefined
        if (prop.match(/inherit/i)) return prop
        if (`$${prop}` in target) return target[`$${prop}`]
        console.error('requested color key', prop, 'not found in palette', repr(theme.palette))
        target[`$${prop}`] = augmentColor({ color: { main: '#bbb' }, name: prop })
        return target[`$${prop}`]
      }
    }),
  }
})
export const ColorsByKeyThemeProvider = ({ children, colorMapOverride }) => {
  const { colorsByKey } = useConnect('selectColorsByKey')

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme => colorsByKeyThemeBuilder(theme, colorMapOverride ?? colorsByKey)}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default themes
if (
  (safeStorage('local').getItem('debug') === 'true' && appConfig.ENVIRONMENT !== 'production')
  || (typeof process !== 'undefined' && process.env.NODE_ENV !== 'test')
) {
  getGlobal().themes = themes
}
