import {
  always,
  either,
  groupBy,
  map,
  pipe,
  prop,
  uniq,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { DataType as schema } from '~/src/Store/Schemas'

import { dataTypeSort } from './utils'

const name = 'dataTypes'
const logger = createLogger(name, 'bundle')

const bundle = createEntityBundle({
  name,
  apiConfig: { schema, prepareData: data => data },
})

export default {
  ...bundle,
  selectAllDataTypes: createSelector(
    'selectEntities',
    either(prop('dataTypes'), always(EMPTY_OBJECT))
  ),
  selectDataTypes: createSelector(
    'selectCurrentFacility',
    'selectAllDataTypes',
    'selectDevices',
    'selectModels',
    (facility, allDataTypes, devices, models = EMPTY_OBJECT) => {
      if (!facility) return EMPTY_OBJECT
      const facilityModels = (facility.devices ?? EMPTY_ARRAY).reduce(
        (acc, serial) => {
          const { [serial]: device } = devices
          if (!device) return acc
          const { [device.modelKey]: model } = models
          if (!model) return acc
          return {
            ...acc,
            [device.modelKey]: model,
          }
        },
        EMPTY_OBJECT
      )

      const facilityDataTypes = Object.values(facilityModels).reduce(
        (acc, model) => (Array.isArray(model?.dataTypes) ? {
          ...acc,
          ...model.dataTypes.reduce(
            (dtAcc, dt) => {
              if (dt in acc) return dtAcc
              const { [dt]: dataType } = allDataTypes
              if (!dataType || dataType.hidden || !dataType.active) {
                return dtAcc
              }

              return {
                ...dtAcc,
                [dt]: dataType,
              }
            },
            EMPTY_OBJECT
          ),
        } : acc),
        EMPTY_OBJECT
      )

      return {
        ...facilityDataTypes,
        ...Object.entries(allDataTypes).reduce((acc, [key, dt]) => (
          !dt.disabled && !dt.hidden ? ({ ...acc, [key]: dt }) : acc
        ), EMPTY_OBJECT)
      }
    }
  ),
  selectManualDataTypes: createSelector(
    'selectAllDataTypes',
    allDataTypes => ({
      ...Object.entries(allDataTypes).reduce((acc, [key, dt]) => (
        dt.allowManual ? ({ ...acc, [key]: dt }) : acc
      ), EMPTY_OBJECT)
    })
  ),
  selectModelsByDataType: createSelector(
    'selectModels',
    models => Object.values(models).reduce(
      (acc, { dataTypes, key }) => ({
        ...acc,
        ...dataTypes.reduce(
          (dtAcc, dtKey) => ({
            ...dtAcc,
            [dtKey]: {
              ...dtAcc[dtKey],
              [key]: 1,
            },
          }),
          acc
        ),
      }),
      EMPTY_OBJECT
    )
  ),
  selectModelsById: createSelector(
    'selectModels',
    models => Object.values(models).reduce((res, model) => ({ ...res, [model.id]: model }), {})
  ),
  selectDataTypesByUnit: createSelector(
    'selectDataTypes',
    pipe(Object.values, groupBy(prop('unit')))
  ),
  selectFacilityActiveDataTypes: createSelector(
    'selectDataTypes',
    'selectDevices',
    'selectModels',
    (dataTypes, devices, models) => {
      const activeModels = pipe(
        Object.values,
        map(prop('modelKey')),
        uniq
      )(devices)

      const activeDataTypes = uniq(activeModels.flatMap(modelKey => models?.[modelKey]?.dataTypes ?? []))

      return dataTypeSort(Object.values(dataTypes).filter(dt => dt && activeDataTypes.includes(dt.key)))
    }
  ),
  selectSensorDataTypesByRoom: createSelector(
    'selectDataTypes',
    'selectDevices',
    'selectModels',
    'selectRooms',
    (dataTypes, devices, models, rooms) => Object.values(rooms).reduce((byRoom, room) => {
      if (!Array.isArray(room.devices) || !room.devices.length) return byRoom
      const roomModels = [...new Set(room.devices.map(deviceId => devices[deviceId]?.modelKey).filter(Boolean))]
      const roomDataTypes = roomModels.length
        ? [...new Set(roomModels.flatMap(modelKey => models[modelKey]?.dataTypes ?? []))]
        : EMPTY_ARRAY
      byRoom[room.id] = dataTypeSort(roomDataTypes.map(dt => dataTypes[dt]).filter(Boolean))
      return byRoom
    }, {})
  ),
}
