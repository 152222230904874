import { isValidElement, memo } from 'react'

import PropTypes from 'prop-types'

import createLogger from '~/src/Lib/Logging'
import { renderableType } from '~/src/Lib/PropTypes'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import Null from '~/src/UI/Shared/Null'

import { useHas } from './utils'

const logger = createLogger('Has')

export const HasComponent = ({
  has,
  children = null,
  Fallback = Null,
  FallbackProps = EMPTY_OBJECT
}) => {
  if (has) return children
  return isValidElement(Fallback) || typeof Fallback === 'string'
    ? Fallback
    : <Fallback {...FallbackProps} />
}

HasComponent.propTypes = {
  has: PropTypes.bool.isRequired,
  children: PropTypes.node,
  Fallback: PropTypes.oneOfType([renderableType, PropTypes.element]),
  FallbackProps: PropTypes.shape({})
}
HasComponent.displayName = 'Has'

const Memoized = memo(props => {
  const { flags, any, ...passthru } = props
  const has = useHas(flags, any)

  return <HasComponent {...passthru} has={has} />
})
Memoized.propTypes = {
  flags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
  ]),
  any: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
Memoized.defaultProps = {
  flags: EMPTY_ARRAY,
  any: false,
}

export default Memoized
