// CA values are default for now
export const BEHAVIOR_DEFAULTS = { tagPlantBatches: true, trackVegPlants: false }

export const LOCATIONS_TERMS = {
  AROYA: {
    default: {
      room: 'rooms',
      zone: 'zones',
      facility: 'facilities',
      targetRange: 'targets',
    },
    outdoor: {
      room: 'fields',
      zone: 'zones',
      facility: 'facilities',
      targetRange: 'targets',
    }
  },
  POMETA: {
    default: {
      room: 'climes',
      zone: 'blocks',
      facility: 'farms',
      targetRange: 'ranges',
    }
  }
}

export const TIMEZONES = [
  {
    value: 'America/St_Johns',
    label: 'Newfoundland',
  },
  {
    value: 'America/Halifax',
    label: 'Atlantic',
  },
  {
    value: 'America/New_York',
    label: 'Eastern',
  },
  {
    value: 'America/Chicago',
    label: 'Central'
  },
  {
    value: 'America/Denver',
    label: 'Mountain'
  },
  {
    value: 'America/Phoenix',
    label: 'Arizona'
  },
  {
    value: 'America/Los_Angeles',
    label: 'Pacific'
  },
  {
    value: 'America/Anchorage',
    label: 'Alaska'
  },
  {
    value: 'Pacific/Honolulu',
    label: 'Hawaii-Aleutian'
  },
]
