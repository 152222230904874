import {
  always,
  assocPath,
  either,
  prop,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { HARVESTING_FLOW } from '~/src/Flow/constants'
import { EMPTY_OBJECT, shallowEquals } from '~/src/Lib/Utils'
import { DEFAULT_NEXT } from '~/src/Routes/constants'

import { createAppIsReadySelector } from '../utils'

const UPDATE_MY_SETTINGS = 'UPDATE_MY_SETTINGS'
const UPDATE_MANY_MY_SETTINGS = 'UPDATE_MANY_MY_SETTINGS'

const DEFAULT_SETTINGS = {
  [HARVESTING_FLOW]: {
    weighingIndividually: true,
    systemUidPerWeight: false,
    weightUnit: { id: null, symbol: '' },
  },
}

export default {
  name: 'mySettings',
  reducer: (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
    const { type, payload } = action

    switch (type) {
      case UPDATE_MY_SETTINGS: {
        const { path: settingPath, value } = payload
        return {
          ...state,
          ...assocPath(settingPath, value, { ...state }),
        }
      }
      case UPDATE_MANY_MY_SETTINGS: {
        return payload.reduce((newState, { key, settings }) => (
          assocPath(key.split('.'), settings, newState)
        ), state)
      }
      default: {
        return state
      }
    }
  },
  doUpdateMySettings: payload => ({ store, dispatch }) => {
    const userSettings = store.selectUserSettings()
    const { path, value } = payload
    const payloadPathKey = path.join('.')
    const { [payloadPathKey]: userSetting } = userSettings
    if (userSetting && shallowEquals(value, userSetting.settings)) return false
    dispatch({ type: UPDATE_MY_SETTINGS, payload })
    dispatch({ actionCreator: 'doUserSettingSave', args: [{ key: payloadPathKey, settings: value }] })
    return true
  },
  doUpdateManyMySettings: payload => ({ type: UPDATE_MANY_MY_SETTINGS, payload }),
  selectMySettings: state => state.mySettings,
  selectHarvestingFlowSettings: createSelector(
    'selectMySettings',
    // if harvesting flow settings are not set, return empty object
    either(prop(HARVESTING_FLOW), always(EMPTY_OBJECT)),
  ),
  selectLandingPage: createSelector(
    'selectMySettings',
    mySettings => mySettings.landingPage || DEFAULT_NEXT,
  ),
  reactDefaultHarvestingSettings: createAppIsReadySelector({
    dependencies: ['selectPermittedActions', 'selectAvailableFeatures', 'selectMySettings', 'selectUserSettings'],
    resultFn: (permittedActions, availableFeatures, mySettings, userSettings) => {
      // If user doesn't have permission to harvest plants, do nothing
      if (!permittedActions.has('harvest_flow')) {
        return null
      }
      // If user has harvesting flow settings locally, do nothing
      if (HARVESTING_FLOW in mySettings) {
        return null
      }
      // If user has harvesting flow settings from the cloud
      if (HARVESTING_FLOW in userSettings) {
        return null
      }
      return {
        actionCreator: 'doUpdateMySettings',
        args: [{
          path: [HARVESTING_FLOW],
          value: DEFAULT_SETTINGS[HARVESTING_FLOW]
        }],
      }
    }
  }),
  persistActions: [
    UPDATE_MY_SETTINGS,
  ],
}
