import memoizeOne from 'memoize-one'

import { transform } from 'inflection'
import * as yup from 'yup'

import { EMPTY_OBJECT } from './constants'
import { getDateTime } from './datetime'

export const idValidator = (
  name,
  required = false,
  requiredMessage = name ? `${transform(name, ['underscore', 'humanize'])} is required` : 'Required'
) => yup.lazy(value => {
  if (!required && value == null) return yup.mixed().nullable()
  const schema = (
    yup
      .number()
      .integer()
      .positive()
      .typeError(name
        ? `${transform(name, ['underscore', 'humanize'])} must be a valid ID.`
        : 'Must be valid ID')
  )
  return required ? schema.required(requiredMessage) : schema
})

yup.addMethod(yup.object, 'atLeastOneRequired', function atLeastOneRequired(keys, keyToLabel = EMPTY_OBJECT) {
  // Yup passes in the schema as this
  // eslint-disable-next-line babel/no-invalid-this
  return this.test({
    name: 'atLeastOneRequired',
    message: () => {
      const labels = keys.map(k => keyToLabel[k] || k)
      const { [labels.length - 1]: last } = labels
      return `Please set ${labels.slice(0, -1).join(', ')}${labels.length > 2 ? ',' : ''} or ${last}`
    },
    exclusive: true,
    test: value => value == null || keys.some(f => value[f] != null)
  })
})

export const dateTimeSchema = yup.date().transform(function transformDate(value, original) {
  // eslint-disable-next-line babel/no-invalid-this
  if (this.isType(value)) {
    return value
  }
  return original ? getDateTime(original).toJSDate() : original
})

export const VALID = Object.freeze({})

const validateWithSchema = memoizeOne((validationSchema, data) => {
  if (!data) return null
  const result = Object.entries(validationSchema).reduce(
    (acc, [key, validator]) => {
      try {
        validator.validateSync(data[key])
        return acc
      } catch (validationError) {
        const { errors } = validationError
        return { ...acc, [key]: errors }
      }
    },
    EMPTY_OBJECT
  )
  return result === EMPTY_OBJECT ? null : result
})

export const isNumber = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(Number(n))

const isValid = memoizeOne(
  (validate, data) => !!data && validate(data) === null
)

export const getValidator = memoizeOne(schema => {
  if (typeof schema === 'function') {
    return schema
  }
  const validate = validateWithSchema.bind(null, schema)
  return [validate, isValid.bind(null, validate)]
})

export const useValidate = memoizeOne((factory, props) => {
  const schema = factory(props)
  const shape = yup.object().shape(schema)

  const validator = async (values, context) => {
    const options = { abortEarly: false, context: { ...context, values } }
    const valid = await shape.isValid(values, { ...options })
    if (valid) {
      return null
    }

    try {
      await shape.validate(values, { ...options })
      return null
    } catch (error) {
      if (error.inner && error.inner?.length) {
        return error.inner.reduce(
          (errors, { path, message }) => ({
            ...errors,
            [path]: message,
          }),
          EMPTY_OBJECT
        )
      }
      return { [error.path]: error.message }
    }
  }
  validator.schema = schema

  return validator
})
