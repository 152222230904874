import { prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import { singularize } from 'inflection'

import createLogger from '~/src/Lib/Logging'
import { getDateTime, parseApiErrors } from '~/src/Lib/Utils'
import { CHART_FETCH } from '~/src/Store/bundles/chart'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { getChartDataFromDrybackData } from './utils'

const displayName = 'Room/bundle#dryback'
const logger = createLogger(displayName)

const DRYBACK_FETCH_FAILED = 'DRYBACK_FETCH_FAILED'
const DRYBACK_FETCH_FINISHED = 'DRYBACK_FETCH_FINISHED'
const DRYBACK_FETCH_STARTED = 'DRYBACK_FETCH_STARTED'

const initialState = {
  charts: {},
  errors: {},
  inflight: {},
  shouldFetch: {},
}

const defaultApiParams = {
  start: undefined,
  end: undefined,
  harvest: undefined,
  room: undefined,
  exclude: 'dryback_nutrients',
}

const drybackBundle = {
  name: 'dryback',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case CHART_FETCH.start:
        return {
          ...state,
          shouldFetch: { ...state.shouldFetch, [action.payload.chartId]: true }
        }
      case DRYBACK_FETCH_STARTED:
        return {
          ...state,
          inflight: { ...state.inflight, [action.chartId]: true },
          shouldFetch: { ...state.shouldFetch, [action.chartId]: false },
        }
      case DRYBACK_FETCH_FINISHED:
        return {
          ...state,
          charts: { ...state.charts, ...action.payload },
          inflight: { ...state.inflight, [action.chartId]: false },
        }
      case DRYBACK_FETCH_FAILED:
        return {
          ...state,
          errors: { ...state.errors, ...action.payload },
          inflight: { ...state.inflight, [action.chartId]: false },
        }
      default:
        return state
    }
  },
  selectDrybackRaw: prop('dryback'),
  selectDrybackCharts: createSelector('selectDrybackRaw', prop('charts')),
  selectDrybackInflight: createSelector('selectDrybackRaw', prop('inflight')),
  selectDrybackShouldFetch: createSelector('selectDrybackRaw', prop('shouldFetch')),
  doFetchDryback: (params = defaultApiParams) => ({ apiFetch, dispatch, store }) => {
    const entities = store.select(['selectDataTypes', 'selectUnits', 'selectZones'])
    const { harvest, room } = params
    let chartId
    if (harvest) {
      chartId = `harvests_${harvest}`
    }
    if (room) {
      chartId = `rooms_${room}`
    }
    dispatch({ type: DRYBACK_FETCH_STARTED, chartId, payload: params, meta: { chartKey: chartId } })
    return apiFetch('/dryback/', { ...defaultApiParams, ...params }).then(response => {
      const chartData = getChartDataFromDrybackData(response, entities)
      dispatch({ type: DRYBACK_FETCH_FINISHED, chartId, payload: { [chartId]: chartData }, meta: { chartKey: chartId } })
      return response
    }).catch(e => {
      const error = parseApiErrors(e)
      dispatch({ type: DRYBACK_FETCH_FAILED, chartId, error, payload: { [chartId]: { error, params, ts: Date.now() } }, meta: { chartKey: chartId } })
    })
  },
  doCreateTestDrybackData: (params = {}) => ({ apiFetch }) => {
    logger.debug('** Generating dryback test data')
    return apiFetch('/dryback/', params, { method: 'POST' })
  },
  reactDrybackChartFetch: createAppIsReadySelector({
    dependencies: [
      'selectAvailableFeatures',
      'selectCurrentFacility',
      'selectRoomDashboardChartParams',
      'selectDrybackShouldFetch',
    ],
    resultFn: (availableFeatures, currentFacility, [chartId, expectedChartParams], drybackShouldFetch) => {
      const allowed = availableFeatures.has('DRYBACK_DATA') && currentFacility.tier !== 'GO'
      const hasParams = Boolean(chartId && expectedChartParams)
      if (!allowed || !hasParams) return null

      const { [chartId]: shouldFetch } = drybackShouldFetch
      if (shouldFetch) {
        const [schema, idStr] = chartId.split('_')
        const entityId = Number(idStr)
        const { start: roomChartStart, end } = expectedChartParams
        const start = getDateTime(roomChartStart).minus({ days: 1 }).toUTC().toISO()

        return { actionCreator: 'doFetchDryback', args: [{ [singularize(schema)]: entityId, start, end }] }
      }
      return null
    }
  }),
}

export default drybackBundle
