import { clamp, pick } from 'ramda'

import {
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryFull,
  BatteryUnknown,
  CheckCircle,
  Error as ErrorIcon,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularConnectedNoInternet0Bar,
} from '@mui/icons-material'

import * as yup from 'yup'

import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  idValidator,
  memoize,
} from '~/src/Lib/Utils'
import SleepIcon from '~/src/UI/Icons/Sleep'
import Icon from '~/src/UI/Shared/Icon'

export const CALIBRATION_TYPES = ['DRIP', 'DRAIN', 'FLOW', 'NONE']

const pickSensor = pick(['calibrationType', 'id', 'modelKey', 'serialNumber'])

export const rssiToSignal = rssi => (rssi ? clamp(0, 100, Math.round(rssi / 0.08)) : null)

export const getBattery = memoize(battery => {
  let BatteryIcon = BatteryUnknown
  let className = 'text-normal'
  let group = 0
  let iconTestIdSuffix = ''
  let range = 'Unknown'

  if ([undefined, null].includes(battery)) {
    range = 'N/A'
    group = 1
    className = 'text-danger'
  } else if (battery >= 0 && battery < 20) {
    BatteryIcon = BatteryAlert
    iconTestIdSuffix = 'alert'
    range = '0%-19%'
    group = 2
    className = 'text-danger'
  } else if (battery < 30) {
    BatteryIcon = Battery20
    iconTestIdSuffix = 'twenty'
    range = '20%-29%'
    group = 3
    className = 'text-warning'
  } else if (battery < 50) {
    BatteryIcon = Battery30
    iconTestIdSuffix = 'thirty'
    range = '30%-49%'
    group = 4
  } else if (battery < 60) {
    BatteryIcon = Battery50
    iconTestIdSuffix = 'fifty'
    range = '50%-59%'
    group = 5
  } else if (battery < 80) {
    BatteryIcon = Battery60
    iconTestIdSuffix = 'sixty'
    range = '60%-79%'
    group = 6
  } else if (battery < 90) {
    BatteryIcon = Battery80
    iconTestIdSuffix = 'eighty'
    range = '80-89%'
    group = 7
  } else if (battery < 95) {
    BatteryIcon = Battery90
    iconTestIdSuffix = 'ninety'
    range = '90%-94%'
    group = 8
  } else if (battery <= 100) {
    BatteryIcon = BatteryFull
    iconTestIdSuffix = 'full'
    range = '95%-100%'
    group = 9
  }
  return ({
    group,
    iconTestId: `aroya-battery-icon-${iconTestIdSuffix}`,
    range,
    className,
    get Icon() {
      const { iconTestId } = this
      return props => (
        <Icon
          icon={BatteryIcon}
          className={className}
          {...props}
          data-testid={iconTestId}
        />
      )
    }
  })
})

export const getSignalIcon = memoize(signal => {
  let SignalIcon = SignalCellularConnectedNoInternet0Bar
  let className = 'text-normal'
  let group = 0
  let iconTestIdSuffix = ''
  let range = 'Unknown'

  if (!signal) {
    range = 'N/A'
    group = 1
    className = 'text-danger'
  } else if (signal === 0) {
    range = '0%'
    group = 2
    className = 'text-danger'
  } else if (signal < 20) {
    SignalIcon = SignalCellular0Bar
    iconTestIdSuffix = 'zero'
    range = '1%-19%'
    group = 3
    className = 'text-danger'
  } else if (signal < 40) {
    SignalIcon = SignalCellular1Bar
    iconTestIdSuffix = 'one'
    range = '20%-39%'
    group = 4
    className = 'text-warning'
  } else if (signal < 60) {
    SignalIcon = SignalCellular2Bar
    iconTestIdSuffix = 'two'
    range = '40%-59%'
    group = 5
  } else if (signal < 80) {
    SignalIcon = SignalCellular3Bar
    iconTestIdSuffix = 'three'
    range = '60%-79%'
    group = 6
  } else if (signal >= 80) {
    SignalIcon = SignalCellular4Bar
    iconTestIdSuffix = 'four'
    range = '80%-100%'
    group = 7
  }

  return ({
    className,
    group,
    iconTestId: `aroya-signal-icon-${iconTestIdSuffix}`,
    range,
    get Icon() {
      const { iconTestId } = this
      return props => (
        <Icon
          icon={SignalIcon}
          className={className}
          {...props}
          data-testid={iconTestId}
        />
      )
    }
  })
})
const getSignal = device => {
  const signal = device?.linkQuality ?? rssiToSignal(device?.rssi) ?? 0
  return getSignalIcon(signal)
}

export const getStatus = memoize((online, deepSleep) => {
  let StatusIcon = CheckCircle
  let className = 'text-normal'

  if (deepSleep) {
    StatusIcon = SleepIcon
    className = 'text-warning'
  } else if (!online) {
    StatusIcon = ErrorIcon
    className = 'text-danger'
  }

  return ({ style }) => (
    <Icon
      icon={StatusIcon}
      className={className}
      style={style}
    />
  )
})

export const SORT = {
  name: 'device_name',
  battery: 'battery',
  signal: 'signal',
  model: 'model__name',
  room: 'room__name'
}

export const HARDWARE = {
  battery: getBattery,
  signal: getSignalIcon
}

const getGrouped = (acc, name, device) => {
  if (acc[name]) {
    acc[name].push(device)
  } else {
    acc[name] = [device]
  }
  return acc
}

export const getGroupedByHardware = memoize((key, devices) => devices.reduce((acc, device) => {
  const level = device[key]
  const name = key === 'signal' ? getSignal(device)?.group : HARDWARE[key](level)?.group ?? 0
  return getGrouped(acc, name, device)
}, {}))

export const getGroupedByName = memoize((key, devices, entity = EMPTY_OBJECT, facility = null) => (
  devices.reduce((acc, device) => {
    if (key === 'room' && !device.room) {
      const groupedName = device.assigned ? facility?.name : 'Unassigned'
      return getGrouped(acc, groupedName, device)
    }

    if (key === 'modelKey') {
      const modelKey = device?.sensor?.modelKey ?? device.modelKey
      return getGrouped(acc, entity[modelKey]?.name ?? 'Unknown', device)
    }

    const id = device[key]
    const name = entity[id]?.name || 'Unknown'
    const group = getGrouped(acc, name, device)

    return group
  }, {})
))

export const getBulkInitialFormValues = devices => ({
  assigned: false,
  devices: devices ?? EMPTY_ARRAY,
  room: null,
  zone: null,
})

export const getBulkValidationSchema = ({ requiredZone, requiredRoom }) => (
  yup.object({
    assigned: yup.bool().required(),
    devices: yup.array().required(),
    room: idValidator('Room', requiredRoom),
    zone: idValidator('Zone', requiredZone),
  })
)

export const getSingleDeviceInitialValues = (device = EMPTY_OBJECT) => {
  const sensor = device.sensor ? pickSensor(device.sensor) : null
  if (sensor) {
    sensor.calibrationType = sensor.calibrationType === 'FLOW' ? 'DRIP' : sensor.calibrationType
  }
  return {
    assigned: device.assigned ?? false,
    deviceName: device.deviceName ?? '',
    id: device.id ?? null,
    sensor,
    serialNumber: device.serialNumber ?? '',
    room: device.room ?? null,
    zone: device.zone ?? null,
  }
}

export const getSingleDeviceValidationSchema = ({ requiredZone, requiredRoom }) => (
  yup.object({
    assigned: yup.bool().required(),
    deviceName: yup.string().required('Device name is a required field'),
    id: idValidator('Id', true),
    serialNumber: yup.string().required(),
    room: idValidator('Room', requiredRoom),
    zone: idValidator('Zone', requiredZone),
  })
)
