import {
  cloneElement,
  createElement,
  Fragment,
  isValidElement,
  memo,
  PureComponent,
} from 'react'

import classNames from 'clsx'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { captureException as captureSentryException } from '@sentry/react'

import config from '~/src/App/config'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import { atLeastOneRequired } from '~/src/Lib/PropTypes'
import { defer, shallowEquals, uniqueId } from '~/src/Lib/Utils'
import CloseButton from '~/src/UI/Shared/CloseButton'
import SomethingWentWrong from '~/src/UI/Shared/SomethingWentWrong'

const dialogOmitProps = [
  'autoOverflow',
  'content',
  'contentRight',
  'actions',
  'title',
  'closeButton',
  'classes',
  'dialog',
  'doOpenDialog',
  'doCloseDialog',
  'dense',
  'isMobile',
  'splitActions',
  'titleProps',
  'transparent',
  'withErrorBoundary',
  'Wrapper',
]
const wrapperOmitProps = [
  ...dialogOmitProps,
  'disableBackdropClick',
  'disableEscapeKeyDown',
  'fullScreen',
  'fullWidth',
  'open',
  'maxWidth',
  'PaperProps',
  'TransitionProps',
  'Wrapper',
]

const getMuiProps = memoizeOne(omit(dialogOmitProps))
const wrapperOmitter = omit(wrapperOmitProps)
const getWrapperProps = memoizeOne((classes, passthru, wrapperProps) => ({
  ...wrapperOmitter(passthru),
  ...wrapperProps,
  className: classNames(classes.wrapper, wrapperProps?.className)
}))

const getActionsClasses = memoizeOne(({
  actions: root,
  actionsSpacing: spacing,
}) => ({ root, spacing }))
const getContentClasses = memoizeOne((classes, { dense, classes: propClasses }, { overflowX }) => ({
  root: classNames({
    [classes.content]: true,
    [classes.dense]: dense,
    [propClasses?.content]: true,
    'scrollbar-x': overflowX,
  }),
}))

const getTitleClasses = memoizeOne((classes, { dense }) => ({
  root: classNames({
    [classes.title]: true,
    [classes.dense]: dense
  })
}))

const getDialogProps = memoizeOne(
  (muiProps, onEntering, onExiting) => ({
    ...muiProps,
    TransitionProps: {
      onEntering,
      onExiting,
    }
  }),
  (left, right) => shallowEquals(left, right, 2)
)

const styles = theme => ({
  root: {
    overflow: 'hidden',
    padding: 'clamp(1rem, 4.5vh, 3rem) clamp(1.25rem, 3.3vw, 4rem)',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    '&$contentRight': {
      display: 'grid',
      gridTemplateAreas: [
        '"dialogTitle dialogTitle"',
        '"dialogContent dialogContentRight"',
        '"dialogActions dialogActions"',
      ].join('\n'),
      gridTemplateColumns: '1fr clamp(20rem, 25vw, 30rem)',
      gridTemplateRows: 'auto minmax(0, 1fr) auto',
      gap: '2rem 1rem',
      minWidth: 'clamp(1024px, 75vw, 1280px)',
      '&$noActions': {
        gridTemplateAreas: [
          '"dialogTitle dialogTitle"',
          '"dialogContent dialogContentRight"',
        ].join('\n'),
      },
      '&$noTitle': {
        gridTemplateAreas: [
          '"dialogContent dialogContentRight"',
          '"dialogActions dialogActions"',
        ].join('\n'),
      },
      '&$noActions$noTitle': {
        gridTemplateAreas: [
          '"dialogContent dialogContentRight"',
        ].join('\n'),
      },
    },
    '&[class*=MuiDialog-paperFullScreen]': {
      padding: '1rem',
      '& $content': {
        marginRight: 0,
        paddingRight: '1rem',
        paddingLeft: '1rem',
      }
    },
    '&[class*=MuiDialog-paperFullScreen]:not(.aroya__dialog-fullscreen-scroll) $content': {
      overflowY: 'hidden',
    },
    '&$overflowY': {
      padding: [
        'clamp(1rem, 4.5vh, 3rem)',
        'calc(clamp(1.25rem, 3.3vw, 4rem) - 6px)', // subtract scrollbar width from padding
        'clamp(1rem, 4.5vh, 3rem)',
        'clamp(1.25rem, 3.3vw, 4rem)'
      ].join(' ')
    }
  },
  noActions: {},
  noTitle: {},
  overflowY: {},
  actions: {
    padding: 0,
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& button': {
        width: '100%',
      },
    }
  },
  contentRight: {
  },
  transparent: {
    background: 'transparent',
  },
  wrapper: {
    display: 'contents',
    '$contentRight &': {
      '& $content': {
        gridArea: 'dialogContent',
        maxHeight: 'calc(100vh - 18rem)'
      },
      '& $contentRightCol': {
        gridArea: 'dialogContentRight'
      },
      '& $title': {
        gridArea: 'dialogTitle'
      },
      '& $actions': {
        gridArea: 'dialogActions'
      },
    }
  },
  contentRightCol: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    background: theme.palette.background.paper,
  },
  actionsSpacing: {},
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&.MuiDialogContent-root': {
      padding: 0,
    },
    '&$noActions': {
      paddingBottom: theme.spacing(2),
    },
    '&:empty': {
      display: 'none',
    },
    '&:first-child': {
      paddingTop: 0,
      '& [class*=MuiFormControl-root]:first-child': {
        marginTop: 0,
      }
    },
    '$overflowY &': {
      paddingRight: '0.5rem'
    }
  },
  title: {
    padding: 0,
  },
  dense: {
    padding: theme.spacing(1, 0.5),
  },
  actionsSplit: {
    justifyContent: 'space-between',
  },
  errorIcon: {
    color: theme.palette.warning.main,
  },
})

const displayName = 'Dialog'
const logger = createLogger(displayName)

const useStyles = makeStyles(styles, { name: displayName })

const resetError = () => {
  getGlobal().location.reload()
}
export class DialogComponent extends PureComponent {
  static displayName = displayName

  static propTypes = {
    actions: PropTypes.node,
    autoOverflow: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    contentRight: PropTypes.node,
    dense: PropTypes.bool,
    titleProps: PropTypes.shape({
      variant: PropTypes.string,
      color: PropTypes.string,
      sx: PropTypes.shape({}),
    }),
    withErrorBoundary: PropTypes.bool,
    Wrapper: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
    doOpenDialog: PropTypes.func.isRequired,
    doCloseDialog: PropTypes.func.isRequired,
    ...atLeastOneRequired({
      children: PropTypes.node,
      content: PropTypes.node,
      title: PropTypes.node,
    }),
  }

  static defaultProps = {
    actions: null,
    autoOverflow: false,
    contentRight: null,
    dense: false,
    titleProps: {},
    withErrorBoundary: true,
    Wrapper: 'div',
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  state = {
    hasError: false,
    overflowX: false,
    overflowY: false,
  }

  contentRef = null

  constructor(props) {
    super(props)
    this.idPrefix = uniqueId('dialog-')
  }

  componentDidMount() {
    if (this.props.autoOverflow) {
      defer(() => {
        const { contentRef: content } = this
        if (!content) return

        if (content.offsetWidth < content.scrollWidth) {
          this.setState({ overflowX: true })
        }
      }, defer.priorities.low)
    }
    defer(() => {
      const { contentRef: content } = this
      if (!content) return

      if (content.offsetHeight < content.scrollHeight) {
        this.setState({ overflowY: true })
      }
    }, defer.priorities.low)
  }

  componentDidCatch(error, info) {
    if (this.props.withErrorBoundary) {
      console.error(
        '[Dialog]',
        { error, info }
      )
      if (config.SENTRY_DSN) {
        captureSentryException(error, { extra: info })
      }
    } else {
      throw error
    }
  }

  componentWillUnmount() {
    this.props.doCloseDialog()
  }

  get dialogProps() {
    const muiProps = getMuiProps(this.props)
    const onEntering = (...args) => {
      this.props.doOpenDialog()
      if (muiProps.onEntering) muiProps.onEntering(...args)
    }
    const onExiting = (...args) => {
      this.props.doCloseDialog()
      if (muiProps.onExiting) muiProps.onExiting(...args)
    }
    const dialogProps = getDialogProps(muiProps, onEntering, onExiting)
    if ('title' in this.props && this.props.title) {
      dialogProps['aria-labelledby'] = this.titleId
    }
    return dialogProps
  }

  get titleId() {
    const { title } = this.props
    if (!title) return null
    return `${this.idPrefix}-title`
  }

  setContentRef = el => {
    this.contentRef = el
  }

  render() {
    const {
      actions,
      children,
      classes,
      closeButton,
      content = children,
      contentRight,
      isMobile,
      splitActions,
      title,
      titleProps,
      transparent,
      withErrorBoundary,
      Wrapper,
      onClose,
      ...passthru
    } = this.props
    const { overflowY } = this.state
    const innerContent = [withErrorBoundary && this.state.hasError ? (
      <SomethingWentWrong
        key="dialog-error"
        classes={classes}
        message={this.state.errorMessage}
        onClick={resetError}
      />
    ) : (
      <Fragment key="dialog-inner">
        {title ? (
          <DialogTitle
            {...titleProps}
            classes={getTitleClasses(classes, this.props)}
            data-testid="dialog-title"
            id={this.titleId}
          >
            {title}
          </DialogTitle>
        ) : null}
        <DialogContent
          ref={this.setContentRef}
          classes={getContentClasses(classes, this.props, this.state)}
          data-testid="dialog-content"
        >
          {content}
          {children && children !== content ? children : null}
        </DialogContent>
        {actions ? (
          <DialogActions
            data-testid="dialog-actions"
            classes={getActionsClasses(classes)}
            className={splitActions ? classes.actionsSplit : undefined}
          >
            {actions}
          </DialogActions>
        ) : null}
        {(isMobile || closeButton) && onClose ? <CloseButton noMargin onClose={onClose} /> : null}
      </Fragment>
    ),
    contentRight ? (
      <Fragment key="dialog-contentRight">
        <div className={classes.contentRightCol}>
          {contentRight}
        </div>
        <CloseButton onClose={onClose} />
      </Fragment>
    ) : null]

    const wrapperProps = getWrapperProps(classes, passthru, Wrapper.props)

    return (
      <Dialog
        classes={{
          paper: classNames({
            [classes.root]: true,
            [classes.contentRight]: !!contentRight,
            [classes.transparent]: transparent,
            [classes.overflowY]: overflowY,
            [classes.noActions]: !actions,
            [classes.noTitle]: !title,
          })
        }}
        data-testid="dialog"
        data-time-series__cursor-exclude
        fullScreen={!!isMobile}
        {...this.dialogProps}
        container={() => document.getElementById('modalRoot')}
      >
        {isValidElement(Wrapper)
          ? cloneElement(Wrapper, wrapperProps, innerContent)
          : createElement(Wrapper, wrapperProps, innerContent)}
      </Dialog>
    )
  }
}

/**
 * A Material-UI dialog with Aroya defaults and enhancements added
 * @component
 * @alias Dialog
 * @composes
 * from: '@mui/material/Dialog'
 * link: https://material-ui.com/api/dialog/#props
 * @example
 * import { Form, Formik, Field } from 'formik'
 * import { FormikTextField } from '~/src/UI/Shared/Form/TextField'
 * import Button from '~/src/UI/Shared/Button'
 * {() => {
 *   const [submitted, setSubmitted] = React.useState(null)
 *   const [open, setOpen] = React.useState(false)
 *   const toggleOpen = React.useCallback(() => setOpen(old => !old), [setOpen])
 *   return (
 *     <Box className="default-display">
 *       <Button onClick={toggleOpen}>
 *         {open ? 'Close' : 'Open'} Dialog
 *       </Button>
 *       {submitted ? (
 *         <>
 *           <T>Submitted</T>
 *           <T.Tiny gutterBottom><code>{JSON.stringify(submitted, null, 2)}</code></T.Tiny>
 *         </>
 *       ) : null}
 *       <Formik
 *         initialValues={{ text: 'foo' }}
 *         onSubmit={(data, { setSubmitting }) => {
 *           console.log('Dialog form data:', data)
 *           setTimeout(() => {
 *             setSubmitted(data)
 *             setSubmitting(false)
 *             setOpen(false)
 *           }, 1000)
 *         }}
 *       >
 *         {formikProps => (
 *           <Dialog
 *             open={open}
 *             actions={(
 *               <Button disabled={formikProps.isSubmitting} type="submit">
 *                 Submit{formikProps.isSubmitting ? 'ing…' : null}
 *               </Button>
 *             )}
 *             content={<Field margin="normal" name="text" component={FormikTextField} />}
 *             title="Test Form Dialog"
 *             Wrapper={Form}
 *           />
 *         )}
 *       </Formik>
 *     </Box>
 *   )
 * }}
 */
const Wrapped = memo(props => {
  const classes = useStyles(props)
  const {
    doOpenDialog,
    doCloseDialog,
    isMobile,
  } = useConnect(
    'doOpenDialog',
    'doCloseDialog',
    'selectIsMobile'
  )
  const connectedProps = {
    doOpenDialog,
    doCloseDialog,
    isMobile,
  }

  return <DialogComponent {...connectedProps} {...props} classes={classes} />
})

Wrapped.displayName = `ConnectedStyledMemoized(${displayName})`

export default Wrapped
