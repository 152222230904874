import { pick } from 'ramda'
import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'
import queryString from 'query-string'
import reduceReducers from 'reduce-reducers'

import { createCustomAction } from '~/src/Lib/createEntityBundle'
import createListBundle from '~/src/Lib/createListBundle'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  parseApiErrors,
  shallowEquals,
} from '~/src/Lib/Utils'
import { CultivarStatistic } from '~/src/Store/Schemas'

const {
  actionIdentifiers: topChartFetch,
  actionReducer: topChartFetchReducer,
  defaultState: topChartFetchDefaultState,
} = createCustomAction({
  actionType: 'fetch',
  actionName: 'topChartFetch',
  reducerKey: 'topChartFetch',
})

export const initialState = {
  // eslint-disable-next-line babel/camelcase
  days_before: '',
  facility: '',
  search: '',
  archived: false,
  archivedOnly: false,
  ordering: '',
  page: 1,
}

const initialBundle = createListBundle({
  entityName: 'cultivarStatistic',
  actions: EMPTY_ARRAY,
  initialState,
  schema: CultivarStatistic,
  permissions: { keys: ['view_cultivar_profiles'] },
  staleAfter: ms.hours(12),
  retryAfter: ms.minutes(5),
  urlTest: (_, pattern) => pattern.startsWith('/cultivars'),
  fetchHandler: async ({ apiFetch, params, store }) => {
    const apiParams = pick(Object.keys(initialState), params)
    const fetchResult = await apiFetch('/cultivarStatistic/', apiParams)
    await store.doFetchCultivarStatisticTopChart(apiParams)
    return fetchResult
  },
})

const listBundle = {
  ...initialBundle,
  reducer: reduceReducers(
    initialBundle.reducer,
    (state = topChartFetchDefaultState, action = EMPTY_OBJECT) => {
      if (action.type.startsWith(topChartFetch.types.prefix)) {
        return topChartFetchReducer(state, action)
      }
      return initialBundle.reducer(state, action)
    }
  ),
  selectCurrentCultivarStatisticList: createSelector(
    'selectCultivarStatisticList',
    'selectEntities',
    (list, { cultivarStatistics = EMPTY_OBJECT }) => (list?.results
      ? list.results.map(id => cultivarStatistics[id]).filter(Boolean)
      : EMPTY_ARRAY)
  ),
  selectCultivarStatisticIsLoading: createSelector(
    'selectCultivarStatisticListRaw',
    raw => raw?.isLoading || raw?.topChartFetch?.loading
  ),
  selectCultivarStatisticTopChart: createSelector(
    'selectCultivarStatisticListRaw',
    raw => raw?.topChartFetch?.data
  ),
  doFetchCultivarStatisticTopChart: query => async ({
    dispatch,
    apiFetch,
  }) => {
    dispatch({ type: topChartFetch.types.start })
    let result = false
    try {
      result = await apiFetch('/cultivarStatistic/topChart/', query, {
        method: 'GET',
      })
      dispatch({
        type: topChartFetch.types.succeed,
        payload: result,
      })
    } catch (error) {
      result = error
      dispatch({
        type: topChartFetch.types.fail,
        error: parseApiErrors(error) || 'An unexpected error occurred.',
      })
    }
    return result
  },
  reactCultivarUpdateQuery: createSelector(
    'selectRouteInfo',
    'selectQueryAdvanced',
    'selectCultivarStatisticListParams',
    ({ pattern }, query, params) => {
      const newQuery = Object.entries(params).reduce((newParams, [key, value]) => {
        if (value === initialState[key] || (!(key in initialState)) || (Array.isArray(value) && !value.length)) {
          return newParams
        }

        return { ...newParams, [key]: value }
      }, {})
      const inCultivarsPage = pattern.startsWith('/cultivars') || pattern.startsWith('/facility/cultivars')
      /* eslint-disable eqeqeq */
      const queryUnchanged = shallowEquals(Object.keys(query).sort(), Object.keys(newQuery).sort())
        && Object.entries(query).every(([key, value]) => (
          Array.isArray(value)
            ? value.every((innerValue, index) => innerValue == newQuery[key][index])
            : value == newQuery[key]
        ))
      /* eslint-enable eqeqeq */
      // If we're on the cultivars list page or a cutivar profile page,
      // update the query string with any non-default param values
      if (inCultivarsPage && !queryUnchanged) {
        return { actionCreator: 'doUpdateQuery', args: [queryString.stringify(newQuery)] }
      }
      // if we're not on the cultivars list page or a cutivar profile page and the (non-empty) query matches our params
      // clear the query
      if (!inCultivarsPage && queryUnchanged && !shallowEquals(query, {})) {
        return { actionCreator: 'doUpdateQuery', args: [{}] }
      }
      return undefined
    }
  )
}

export default listBundle
