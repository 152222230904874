import { prop } from 'ramda'
import { createSelector } from 'redux-bundler'

const ACTION = {
  ALERT_DISMISSED: 'ALERT_DISMISSED',
  DISMISSED_ALERTS_CLEARED: 'DISMISSED_ALERTS_CLEARED',
}

const initialState = {
  dismissed: [],
}

const bundle = {
  name: 'alerts',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case ACTION.ALERT_DISMISSED:
        return {
          ...state,
          dismissed: [...state.dismissed, action.payload],
        }
      case ACTION.DISMISSED_ALERTS_CLEARED:
        return { ...state, dismissed: [] }
      default:
        return state
    }
  },
  selectAlerts: prop('alerts'),
  selectDismissedAlerts: createSelector(
    'selectAlerts',
    prop('dismissed'),
  ),
  doDismissAlert: key => ({ type: ACTION.ALERT_DISMISSED, payload: key }),
  doClearDismissedAlerts: () => ({ type: ACTION.DISMISSED_ALERTS_CLEARED }),
  persistActions: Object.values(ACTION),
}

export default bundle
