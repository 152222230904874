import {
  forwardRef,
  memo,
  PureComponent,
  useCallback,
} from 'react'

import classNames from 'clsx'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'

import { Cancel as CancelIcon } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField as MuiTextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

import createLogger from '~/src/Lib/Logging'
import { numberOrEmptyType } from '~/src/Lib/PropTypes'
import {
  defer,
  EMPTY_OBJECT,
  isNumber,
  memoize,
  randomId,
  shallowEquals,
} from '~/src/Lib/Utils'

const displayName = 'TextField'
const logger = createLogger(displayName)

const CLEAR_EVENT = Object.freeze({ target: { value: '' } })

const valuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.array,
])
const exists = value => (typeof value === 'number' && !Number.isNaN(value))
  || !!(Array.isArray(value) && value.length)
  || !!value

const decimalPattern = /\.0?$/
const getValue = (value, type) => {
  if (value === '') return value
  if (type === 'number') {
    if (typeof value === 'string' && value.match(decimalPattern)) {
      return value
    }
    return value === Number(value) ? value : ''
  }
  return isNumber(value) || value ? value : ''
}

const roundToStep = (value, step = 1) => {
  const inv = 1 / step
  return step === 1 ? Math.round(value) : Math.round(value * inv) / inv
}

const getInputProps = memoizeOne(props => {
  const { inputProps, barcode, maxLength, step } = props
  return {
    ...inputProps,
    maxLength,
    step,
    'data-barcode': barcode ? 'true' : undefined,
    'aria-label': props.label === false ? undefined : props.label ?? props.placeholder,
  }
})

const getOnEmptyInputProps = memoizeOne((props, onChange) => {
  const { onEmpty, InputProps, value } = props
  const endAdornment = value != null && value !== '' ? (
    <InputAdornment position="end">
      <IconButton
        aria-label="clear text field"
        color="primary"
        size="small"
        onClick={() => {
          if (onEmpty?.call) {
            onEmpty()
            return
          }
          onChange(CLEAR_EVENT)
        }}
      >
        <CancelIcon />
      </IconButton>
    </InputAdornment>
  ) : undefined

  return { ...InputProps, endAdornment }
})

const getSelectProps = memoizeOne((rawProps, state, self) => ({
  ...rawProps,
  open: state.open,
  onOpen: self.onOpen,
  onClose: self.onClose,
}), (left, right) => shallowEquals(left, right, 2))

const styles = theme => ({
  root: {
    '& .warning': {
      marginRight: 4,
      color: theme.palette?.error.warning ?? 'darkorange',
      cursor: 'default',
    },
    '& .MuiInputBase-root.MuiFilledInput-root input': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    '& label + .MuiInputBase-root.MuiFilledInput-root input': {
      paddingTop: '1.5rem',
      paddingBottom: '0.5rem',
    },
    '& .MuiSelect-filled': {
      paddingTop: '19px',
      '& .MuiListItemText-root .MuiListItemText-primary': {
        color: theme.palette?.text.primary ?? 'white',
      }
    }
  },
  hero: {
    '& .MuiInputBase-root.MuiFilledInput-root': {
      height: '4rem',
      '& input': {
        fontSize: 20,
      }
    },
    '&.MuiTextField-root.MuiFormControl-root .MuiInputLabel-root': {
      fontSize: 20,
    },
  },
  adornedStart: {
    '&:not($hero) .MuiInputLabel-shrink': {
      top: 4,
    },
    '& .MuiInputBase-colorPrimary svg': {
      color: theme.palette?.primary.main ?? 'royalblue',
    },
    '& .MuiInputBase-colorSecondary svg': {
      color: theme.palette?.secondary.main ?? 'cyan',
    },
    '&:has(input:autofill, input:-webkit-autofill) .MuiInputBase-colorPrimary svg': {
      color: theme.palette?.primary.dark ?? 'mediumblue',
    },
    '&:has(input:autofill, input:-webkit-autofill) .MuiInputBase-colorSecondary svg': {
      color: theme.palette?.secondary.dark ?? 'darkcyan',
    }
  },
})

const propTypes = {
  autoComplete: PropTypes.string,
  barcode: PropTypes.bool,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  hero: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  helperText: PropTypes.node,
  innerRef: PropTypes.shape({
    current: PropTypes.shape({
      getElementsByTagName: PropTypes.func,
    })
  }),
  InputProps: PropTypes.shape({
    classes: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    endAdornment: PropTypes.node,
    startAdornment: PropTypes.node,
  }),
  inputProps: PropTypes.shape({ size: PropTypes.number }),
  InputLabelProps: PropTypes.shape({
    classes: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    shrink: PropTypes.bool,
  }),
  max: numberOrEmptyType,
  min: numberOrEmptyType,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  select: PropTypes.bool,
  SelectProps: PropTypes.shape({ className: PropTypes.string }),
  step: PropTypes.number,
  type: PropTypes.string,
  // eslint-disable-next-line consistent-return
  value: (props, ...rest) => {
    const invalid = valuePropType(props, ...rest)
    if (invalid) {
      return new Error(
        `${invalid} -- [value: ${JSON.stringify(props.value)}, name: ${props.name
        }]`
      )
    }
  },
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onEmpty: PropTypes.func,
  onFocus: PropTypes.func,
}

const defaultProps = {
  autoComplete: 'on',
  barcode: false,
  className: '',
  clearable: false,
  disabled: false,
  hero: false,
  error: '',
  helperText: '',
  innerRef: undefined,
  InputProps: EMPTY_OBJECT,
  inputProps: EMPTY_OBJECT,
  InputLabelProps: EMPTY_OBJECT,
  SelectProps: EMPTY_OBJECT,
  max: Infinity,
  min: -Infinity,
  maxLength: Infinity,
  name: '',
  select: false,
  step: undefined,
  type: 'text',
  value: '',
  onBlur: undefined,
  onEmpty: undefined,
  onChange: undefined,
  onFocus: undefined,
}

const getId = memoize(self => self.props.id || [(self.props.name || 'textField'), randomId()].join('-'))
export class TextFieldComponent extends PureComponent {
  static displayName = displayName

  static propTypes = propTypes

  static defaultProps = defaultProps

  state = {
    open: false,
  }

  constructor(props) {
    super(props)
    if (typeof props.onChange !== 'function' && typeof props.inputProps?.onChange !== 'function') {
      console.error([
        'Shared component TextField requires an onChange handler to be defined',
        'either as a direct prop or in inputProps',
      ].join('\n'))
    }
  }

  get id() {
    return getId(this)
  }

  onChange = event => {
    const { name, type, onChange } = this.props
    const {
      target: { value: rawValue },
    } = event
    const value = type === 'number' ? this.getNumberValue(rawValue) : rawValue
    if (typeof onChange === 'function') {
      onChange(name ? { [name]: value } : value)
    }
  }

  onBlur = event => {
    const { name, select, type, value: propsValue, onBlur } = this.props

    if (type === 'number' && String(propsValue).endsWith('.')) {
      const value = this.getNumberValue(String(propsValue).slice(0, -1))
      this.props.onChange(name ? { [name]: value } : value)
    }

    if (select) {
      this.onClose()
    }

    if (onBlur) {
      if (event.persist) {
        event.persist()
      }
      // TODO: revisit this in scope of future task
      defer(() => onBlur(event), defer.priorities.highest)
    }
  }

  onFocus = event => {
    const { select, onFocus } = this.props

    if (select) {
      this.onOpen()
    }

    if (onFocus) {
      if (event.persist) {
        event.persist()
      }
      onFocus(event)
    }
  }

  onOpen = () => {
    if (this.closeTimeout) {
      return
    }
    this.setState({ open: true })
  }

  onClose = () => {
    this.setState({ open: false })
    this.closeTimeout = setTimeout(() => {
      delete this.closeTimeout
    }, 100)
  }

  getNumberValue(rawValue) {
    if (rawValue === '') return rawValue
    const { min, max, step } = this.props
    const validStep = typeof step === 'number' && !Number.isNaN(step) && step > 0
    const value = String(rawValue).replace(/[^-.\d]+/g, '')
    if (value.match(decimalPattern)) {
      return (!validStep || step < 1) ? value : Number(value.slice(0, -1))
    }
    let number = Number(value)
    if (!Number.isNaN(number)) {
      number = Math.min(Math.max(number, min), max)
      if (validStep) {
        number = roundToStep(number, step)
      }
    }
    return number
  }

  render() {
    const {
      autoComplete: acProp,
      barcode: _,
      classes,
      className,
      clearable,
      disabled,
      hero,
      error,
      helperText,
      inputProps,
      InputProps: InputPropsRaw,
      InputLabelProps: InputLabelPropsRaw,
      onEmpty,
      name,
      select,
      SelectProps: SelectPropsRaw,
      type,
      min,
      max,
      maxLength,
      value,
      innerRef,
      required,
      ...props
    } = this.props
    let InputProps = InputPropsRaw
    if ((onEmpty || clearable) && value != null && !InputProps?.endAdornment && !select) {
      InputProps = getOnEmptyInputProps(this.props, this.onChange)
    }

    const InputLabelProps = select && exists(value)
      ? {
        shrink: true,
        ...InputLabelPropsRaw,
      }
      : InputLabelPropsRaw

    const SelectProps = select ? getSelectProps(SelectPropsRaw, this.state, this) : EMPTY_OBJECT

    return (
      <MuiTextField
        data-testid="mui-text-field"
        {...props}
        id={this.id}
        ref={innerRef}
        autoComplete={select ? 'off' : acProp}
        select={select}
        className={classNames({
          [className]: !!className,
          [classes.root]: classes.root,
          [classes.hero]: hero,
          [classes.adornedStart]: Boolean(InputProps?.startAdornment),
        })}
        disabled={disabled}
        error={!!error}
        helperText={error || helperText}
        name={name}
        type={type !== 'number' ? type : 'text'}
        value={getValue(value, type)}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        InputProps={InputProps}
        inputProps={getInputProps(this.props)}
        InputLabelProps={InputLabelProps}
        SelectProps={SelectProps}
        required={Boolean(required)}
      />
    )
  }
}

const useStyles = makeStyles(styles, { name: TextFieldComponent.displayName })

const FormikTextFieldComponent = forwardRef((props, ref) => {
  const {
    alwaysShowError = false,
    customError,
    customValue,
    field,
    form,
    noDirty,
    customOnChange,
    ...passthru
  } = props
  const classes = useStyles(props)
  const onChange = useCallback(
    ({ [field.name]: value }) => form.setFieldValue(field.name, value, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.setFieldValue]
  )
  const { error, touched } = form.getFieldMeta(field.name)

  return (
    <TextFieldComponent
      {...passthru}
      {...field}
      classes={classes}
      error={(noDirty || form.dirty) && (touched || alwaysShowError) && (customError || error)}
      innerRef={ref}
      value={customValue || field.value}
      onChange={customOnChange || onChange}
    />
  )
})
FormikTextFieldComponent.displayName = 'FormikTextField'
FormikTextFieldComponent.propTypes = TextFieldComponent.propTypes
FormikTextFieldComponent.defaultProps = TextFieldComponent.defaultProps
export const FormikTextField = memo(FormikTextFieldComponent)

const Memoized = memo(forwardRef((props, ref) => {
  const classes = useStyles(props)

  return <TextFieldComponent {...props} classes={classes} innerRef={ref} />
}))

export default Memoized
