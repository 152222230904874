import { memo, useMemo } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import { urls as harvestUrls } from '~/src/Harvest/bundle'
import createLogger from '~/src/Lib/Logging'
import { idAndNameShape } from '~/src/Lib/PropTypes'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import HarvestIcon from '~/src/UI/Icons/Batch'
import T from '~/src/UI/Shared/Typography'

const displayName = 'Widgets/HarvestV2'
const logger = createLogger(displayName)

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    '& $harvest': {
      color: theme.palette.appSecondary.main,
    },
    '& $icon': {
      color: theme.palette.appSecondary.main,
    },
    'a&': {
      textDecoration: 'none',
    }
  },
  harvest: {},
  phaseDay: {
    color: theme.palette.contrast.disabled,
    fontSize: '0.875rem',
    paddingTop: theme.spacing(0.15),
    paddingLeft: theme.spacing(1.25)
  },
  icon: {},
}), { name: displayName })

export const WidgetHarvest = ({
  colorsByKey = EMPTY_OBJECT,
  harvest = null,
  link = false,
  phase = null,
  relativeStartDay = null
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const Component = link ? 'a' : 'div'
  let phaseColor = theme.palette.text.primary
  if (phase?.phaseType in colorsByKey) {
    const { [phase.phaseType]: colorDef } = colorsByKey
    phaseColor = colorDef.light ?? colorDef.main
  }
  return (
    <Component
      className={classes.root}
      href={link && harvest ? harvestUrls.getUrl(harvest) : undefined}
    >
      <Box display="flex" fontSize="1rem" gap="0.5ch">
        {harvest?.name ? (
          <>
            {harvest.recipe ? (
              <T fontSize="inherit" color="inherit" component="span" display="inline">{i18n`Recipe:`}</T>
            ) : (
              <HarvestIcon className={classes.icon} />
            )}
            <T fontSize="inherit" noWrap className={classes.harvest}>
              {harvest.name}{harvest.recipe}
            </T>
          </>
        ) : null}
        {phase?.name
          ? (
            <>
              <T
                noWrap
                component="span"
                fontSize="inherit"
                sx={{
                  color: phaseColor,
                }}
              >
                {harvest ? '- ' : null}{phase.name}
              </T>
              {relativeStartDay ? <T.Tiny noWrap className={classes.phaseDay}>{i18n`day ${relativeStartDay}`}</T.Tiny> : null}
            </>
          ) : null}
      </Box>
    </Component>
  )
}

WidgetHarvest.propTypes = {
  colorsByKey: PropTypes.shape({}),
  harvest: idAndNameShape,
  link: PropTypes.bool,
  phase: idAndNameShape,
  relativeStartDay: PropTypes.number
}

const WidgetHarvestWrapper = memo(({
  harvest = null,
  phase = null,
  ...passthru
}) => {
  const {
    colorsByKey,
    harvests,
    phases,
    recipePhases,
    recipes
  } = useConnect('selectColorsByKey', 'selectHarvests', 'selectPhases', 'selectRecipePhases', 'selectRecipes')
  const phaseEntity = useMemo(() => {
    if (phase && phase.id) {
      return phase
    }
    return phases[phase] ?? recipePhases[phase]
  }, [phase, phases, recipePhases])

  const harvestId = phaseEntity?.harvest ?? phaseEntity?.harvestId ?? harvest

  const harvestEntity = useMemo(() => {
    if (typeof harvestId !== 'number' && harvest && harvest.id) {
      return harvest
    }
    return harvests[harvestId] ?? recipes[harvestId]
  }, [harvest, harvestId, harvests, recipes])

  return harvestEntity || phaseEntity
    ? (
      <WidgetHarvest
        {...passthru}
        colorsByKey={colorsByKey}
        harvest={harvestEntity}
        phase={phaseEntity}
      />
    )
    : null
})

export default WidgetHarvestWrapper
