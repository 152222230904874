import ms from 'milliseconds'
import { normalize } from 'normalizr'

import { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import { EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { IrrigationSchedule as schema } from '~/src/Store/Schemas'

const initialState = {
  ...defaultInitialState,
  filter: 'CURRENT',
  sort: ['name', 'asc'],
  page: 1,
  phaseType: null,
  createdBy: null,
  status: null,
  rooms: null,
  harvests: null,
  phases: null,
}

const queryKeyMap = {
  filter: 'currentFilter',
  sort: 'ordering',
}

const queryValueMap = {
  sort: ([field, direction]) => (direction === 'asc' ? field : `-${field}`),
  end: end => end && getDateTime(end).toUTC().toISO(),
  start: start => start && getDateTime(start).toUTC().toISO(),
}

const stateKeys = [
  'filter',
  'page',
  'search',
  'sort',
  'phaseType',
  'createdBy',
  'status',
  'rooms',
  'harvests',
  'phases',
]

const queryBuilder = state => stateKeys.reduce((acc, key) => (state[key] ? {
  ...acc,
  [queryKeyMap[key] ?? key]: (
    key in queryValueMap
      ? queryValueMap[key](state[key])
      : state[key]
  )
} : acc), EMPTY_OBJECT)

const shareBundleConfig = {
  entityName: 'irrigationSchedule',
  initialState: { ...initialState },
  permissions: { keys: ['view_irrigation'] },
  schema,
}

const initialBundle = createListBundle({
  ...shareBundleConfig,
  retryAfter: ms.seconds(5),
  staleAfter: ms.minutes(60),
  fetchHandler: ({ apiFetch, params }) => apiFetch('/irrigationSchedules/', queryBuilder(params)),
})

export const roomIrrigationSchedules = createListBundle({
  ...shareBundleConfig,
  name: 'roomIrrigationSchedules',
  fetchHandler: ({ apiFetch, params }) => apiFetch('/irrigationSchedules/active/', queryBuilder(params)),
  urlTest: (_, pattern) => pattern.startsWith('/rooms')
})

export const facilityIrrigationSchedules = createListBundle({
  ...shareBundleConfig,
  name: 'facilityIrrigationSchedules',
  fetchHandler: ({ apiFetch, params }) => apiFetch('/irrigationSchedules/active/', queryBuilder(params)),
  urlTest: (_, pattern) => pattern.startsWith('/facility')
})

export default {
  ...initialBundle,
  doFetchActiveIrrigationSchedules: params => async ({ dispatch, apiFetch }) => {
    try {
      const result = await apiFetch('/irrigationSchedules/active/', params, { method: 'GET' })
      const { entities } = normalize(result.results, [schema])
      dispatch(doEntitiesReceived(entities, { replace: false }))
      return result.results
    } catch (err) {
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Unable to get irrigation schedules: ${err}`]
      })
      return []
    }
  },
  // TODO: precise endpoint, adjust once the BE is ready
  doFetchFacilityIrrigationSchedules: params => async ({ dispatch, apiFetch }) => {
    try {
      const result = await apiFetch('/irrigationSchedules/active/', { ...params, rooms: 'ALL' }, { method: 'GET' })
      // TODO: going to receive an object where the key is a room id and the value is an object of active irrigation schedules, so
      // we need to adjust/revisit the normalization here once receiving data
      // const { entities } = normalize(result, [schema])
      const entities = { irrigationSchedules: {} }
      dispatch(doEntitiesReceived(entities, { replace: false }))
      return result
    } catch (err) {
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Unable to fetch facility irrigation schedules: ${err}`]
      })
      return []
    }
  },
}
