import memoizeOne from 'memoize-one'
import { map } from 'ramda'

import createLogger from '~/src/Lib/Logging'
import {
  downsampleToMax,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  shallowEquals,
  shallowEqualsMemoizer,
} from '~/src/Lib/Utils'

import { GRAPH_MAX_POINTS } from './constants'

const logger = createLogger('Chart#utils')

export const padBounds = shallowEqualsMemoizer((bounds, index) => {
  const { min: originalMin, max: originalMax } = bounds
  const isEven = index % 2 === 0
  let padding = (originalMax - originalMin) * 0.06
  let offset = 0
  if ((originalMax - originalMin) < (originalMax / 10) || originalMax === originalMin) {
    padding = originalMax / 5
    offset = Math.min(((index % 3) + 1) * (originalMax / 10), padding / 3)
  }

  padding = Math.abs(padding)
  offset = Math.abs(offset)

  const clamp = ({ min, max }) => ({
    min: originalMin >= 0 ? Math.max(0, min) : min,
    max: originalMax < 0 ? Math.min(0, max) : max,
  })

  return clamp({
    min: isEven ? originalMin - padding : (originalMin - padding / 2) - offset,
    max: isEven ? (originalMax + padding / 2) + offset : originalMax + padding,
  })
}, { depth: 2 })

/**
 * Adds padding and offsets to graph bounds so they will layout better within the chart area
 * @param {Object} graphBounds
 * @returns {Object} Padded and offset bounds
 */
export const boundsPadder = shallowEqualsMemoizer((graphBounds = EMPTY_OBJECT) => Object.entries(graphBounds)
  .reduce((acc, [key, bounds], index) => {
    const padded = padBounds(bounds, index)
    acc[key] = padded
    return acc
  }, {}), { depth: 3 })

export const getBounds = memoizeOne(map(boundsPadder), (left, right) => shallowEquals(left, right, 2))

export const defaultModifier = { addend: 0, multiplier: 1 }
export const applyNormalizationModifier = (value, modifier) => (
  modifier ? (modifier.multiplier * value) + modifier.addend : value
)
export const unapplyNormalizationModifier = (value, modifier) => (
  modifier ? (value - modifier.addend) / modifier.multiplier : value
)

/**
 * Given a unit-based value, converts that value into a normalized value based on min/max and modifiers
 * The modifiers allow us to specify values that create a set y-offset (addend) and range (multiplier)
 * @param {number} value
 * @param {Object} bounds
 * @param {Object} modifier
 * @param {number} modifiers.addend
 * @param {number} modifiers.multiplier
 * @returns {number} Normalized value
 */
export const getNormalized = (value, bounds, modifier = defaultModifier) => {
  if (value == null || !bounds) {
    return undefined
  }
  const { min, max } = bounds
  if (max - min) {
    return applyNormalizationModifier((value - min) / (max - min), modifier)
  }
  return Number.isNaN(value) ? null : applyNormalizationModifier(value, modifier)
}
export const getDenormalized = (normalized, { min, max }, modifier = defaultModifier) => {
  if (max - min) {
    return (unapplyNormalizationModifier(normalized, modifier) * (max - min)) + min
  }
  return unapplyNormalizationModifier(normalized, modifier)
}

/**
 * Given the raw line data from the API, normalizes and downsamples it (if necessary)
 */
export const getLineData = shallowEqualsMemoizer(({
  data,
  bounds,
  inverted = false,
  maxPoints = GRAPH_MAX_POINTS,
  modifier = defaultModifier
}) => {
  if (!data || !bounds) return data
  let lineData = data
  if (lineData.length > maxPoints * 1.25) {
    lineData = downsampleToMax(lineData, maxPoints)
    if (lineData.length < data.length) {
      logger.debug(`reducing data points original: ${data.length
        }, resampled:${lineData.length
        }, maxPoints=${maxPoints
        }`)
    }
  }
  const result = Array.isArray(lineData) ? lineData.map(({
    x,
    y,
    _y = getNormalized(inverted ? bounds.max : y, bounds, modifier),
    ...rest
  }) => {
    // eslint-disable-next-line no-underscore-dangle
    let _y0 = _y === null ? _y : rest.y0 ?? getNormalized(0, bounds, modifier)
    if (inverted && _y0 !== null) {
      _y0 = getNormalized(bounds.max - y, bounds, modifier)
    }
    return {
      _x: new Date(x),
      _y,
      _y0,
      ...rest
    }
  }) : EMPTY_ARRAY

  return result
}, { depth: 2, vargs: true })
