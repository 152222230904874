import { createElement } from 'react'

import colorUtil from 'color'

import { Close } from '@mui/icons-material'
import { createTheme } from '@mui/material/styles'

import appConfig from '~/src/App/config'
import createLogger from '~/src/Lib/Logging'
import { getMainColor } from '~/src/Lib/Utils/color'
import { EMPTY_OBJECT } from '~/src/Lib/Utils/constants'
import memoize, { shallowEqualsMemoizer } from '~/src/Lib/Utils/memoizer'

import { muiBreakpoints, spacing, SPACING } from './basics'
import {
  getInputColorStates,
  getStringableColorWithStates,
  palettes,
  states,
} from './colors'
import {
  boldFontFamily,
  defaultFontFamily,
  pxToRem,
  typography as defaultTypography,
} from './typography'

export * from './basics'
export * from './colors'
export * from './main'
export { default as getGlobals } from './main'
export * from './typography'

const DEFAULT_BORDER_RADIUS = 4

const logger = createLogger('UI/Mui')

const transparent = 'transparent'
const { [appConfig.APP ?? 'AROYA']: defaultPalette } = palettes

const severities = ['Error', 'Warning', 'Info', 'Success']
const variants = ['filled', 'outlined']
const getAlertVariantRules = palette => severities.reduce((rules, severity) => {
  const selector = variants.map(variant => `&.MuiAlert-${variant}${severity}`).join(', ')
  rules[selector] = {
    color: palette[severity.toLowerCase()].main,
    borderColor: 'currentColor'
  }
  return rules
}, {})
const addAlertVariantPlaceholders = existing => severities.reduce((placeholders, severity) => {
  variants.forEach(variant => {
    const selector = `${variant}${severity}`
    if (selector in placeholders) return
    placeholders[selector] = {}
  })
  return placeholders
}, existing)

export const buildTheme = memoize((originalPalette, options = EMPTY_OBJECT) => {
  const initialTheme = createTheme({ palette: { mode: 'dark' } })
  let palette = { ...initialTheme.palette, ...defaultPalette, ...originalPalette }
  const { primary, ...restPalette } = palette

  const main = palette?.primary?.main ?? palette.appPrimary.main
  const inputColors = getInputColorStates(main)
  if (!Object.keys(states).every(key => key in palette)) {
    palette = {
      ...Object.entries(restPalette).reduce((colors, [key, color]) => {
        colors[key] = typeof color === 'object' && 'main' in color
          ? initialTheme.palette.augmentColor({ color, name: key })
          : color
        return colors
      }, {}),
      ...inputColors,
      primary: initialTheme.palette.augmentColor({ color: { ...primary, ...inputColors }, name: 'primary' }),
      states,
    }
  }
  const { contrast } = palette
  palette.action = {
    active: contrast.inactive,
    hover: contrast.enabled,
    hoverOpacity: states.enabled,
    selected: contrast.enabled,
    selectedOpacity: states.enabled,
    disabled: contrast.disabled,
    disabledBackground: colorUtil(contrast.main).alpha(states.enabled / 4),
    disabledOpacity: states.disabled,
    focus: contrast.active,
    focusOpacity: states.active,
    activatedOpacity: states.active
  }

  const typography = {
    boldFontFamily,
    fontFamily: defaultFontFamily,
    useNextVariants: true,
    ...Object.fromEntries(Object.entries(defaultTypography).map(([name, config]) => {
      if (name.match(/^(h\d|subtitle)/)) {
        return [name, { ...config, color: palette.text.primary }]
      }

      if (name.startsWith('body')) {
        return [name, { ...config, color: palette.text.secondary }]
      }

      return [name, config]
    })),
  }

  const { defaultBorderRadius: borderRadius = DEFAULT_BORDER_RADIUS } = options

  return createTheme({
    breakpoints: muiBreakpoints,
    components: {
      MuiAlert: {
        styleOverrides: addAlertVariantPlaceholders({
          root: {
            position: 'relative',
            alignItems: 'center',
            ...getAlertVariantRules(palette),
          },
          filledInfo: {
            backgroundColor: 'transparent',
            background: inputColors.active,
            '& .MuiAlert-message > div': {
              color: palette.appPrimary.main,
            },
            '& .MuiAlert-icon': {
              color: palette.appPrimary.main,
            }
          },
          icon: {
            alignItems: 'center',
            alignContent: 'center',
            '.MuiAlert-filledError &': {
              color: palette.text.primary
            }
          },
          message: {
            padding: 0,
          }
        }),
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-shrink + .MuiFilledInput-root': {
              paddingTop: 0,
            },
          },
          clearIndicator: {
            color: main,
          },
          endAdornment: {
            marginRight: '1rem',
          },
          hasClearIcon: {},
          hasPopupIcon: {},
          popupIndicator: {
            color: main,
          },
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            padding: spacing(1.25, 2.5),
          },
          colorDefault: {
            backgroundColor: colorUtil(palette.secondary.light).alpha(states.inactive).string(),
          },
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            transitionDelay: '250ms',
            backgroundColor: palette.contrastInverse.inactive,
            backdropFilter: 'blur(8px)',
          }
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              opacity: states.disabled,
              filter: 'grayscale(0.68)',
            }
          },
          text: {
            padding: spacing(0.5, 2),
            borderRadius: '1rem',
          },
          textPrimary: {
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              color: palette.primary.main,
            }
          },
          textSecondary: {
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              color: palette.secondary.main,
            }
          },
          outlined: {
            backgroundColor: transparent,
            borderColor: 'currentColor',
            '& $label': {
              color: 'inherit',
            },
            '&:before': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              content: '""',
              backgroundColor: transparent,
              borderRadius,
              pointerEvents: 'none',
              opacity: 0,
            },
            '&:active:before': {
              opacity: states.active,
            },
            '&:focus:before': {
              opacity: states.focused,
            },
            '&:hover:before': {
              opacity: states.hovered,
            },
            '&$outlinedPrimary, &$outlinedSecondary': {
              backgroundColor: transparent,
              position: 'relative',
              borderColor: 'currentColor',
              '&:active, &:focus, &:hover': {
                position: 'relative',
                borderColor: 'currentColor',
              },
              '&:active:before, &:focus:before, &:hover:before': {
                backgroundColor: 'currentColor',
              },
            },
          },
          outlinedPrimary: {
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              color: palette.primary.main,
            }
          },
          outlinedSecondary: {
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              color: palette.secondary.main,
            }
          },
          contained: {
            '&:before': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              content: '""',
              backgroundColor: transparent,
              // borderRadius,
              pointerEvents: 'none',
            },
            '&:hover': {
              '&:before': {
                backgroundColor: palette.contrast.hovered,
              },
            },
            '&:active, &:focus': {
              '&:before': {
                backgroundColor: palette.contrast.active,
              },
            },
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              color: palette.contrast.strong,
            }
          },
          containedPrimary: {
            position: 'relative',
            [['hover', 'active', 'focus']
              .map(state => `&:not(.MuiListItem-button, .Mui-disabled):${state}`).join(', ')]: {
              backgroundColor: main,
            },
          },
          containedSecondary: {
            backgroundColor: palette.secondary.main,
          },
          textSizeLarge: {
            padding: '11px 16px',
          },
          textSizeSmall: {
            padding: '7.5px 12px',
          },
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: undefined,
            '&.MuiCheckbox-colorPrimary': {
              color: main,
            },
          },
        }
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: createElement(Close, { color: 'inherit' })
        },
        styleOverrides: {
          root: {
            fontFamily: boldFontFamily,
            transition: 'none',
            '&.MuiChip-outlined': {
              borderWidth: 2,
            },
            '&.MuiChip-outlinedPrimary:active': {
              background: palette.primary.active,
            },
            '&.MuiChip-outlinedPrimary:hover': {
              background: palette.primary.enabled,
            },
          },
        }
      },
      MuiDateTimePickerToolbar: {
        styleOverrides: {
          timeDigitsContainer: {
            alignItems: 'center'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            '&.MuiTypography-root': {
              fontSize: 'unset',
            },
            ...Object.fromEntries(Object.entries(defaultTypography).map(([name, config]) => [
              `&.MuiTypography-root.MuiTypography-${name}`,
              { fontSize: config.fontSize }
            ])),
          },
        }
      },
      MuiFab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              opacity: states.disabled,
              filter: 'grayscale(0.68)',
            }
          },
          primary: {
            position: 'relative',
            color: palette.primary.contrastText ?? palette.text.primary,
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              color: palette.text.primary,
            },
            '&:before': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              content: '""',
              backgroundColor: transparent,
              borderRadius: '50%',
              pointerEvents: 'none',
            },
            '&:hover': {
              backgroundColor: main,
              '&:before': {
                backgroundColor: palette.contrast.hovered
              }
            },
            '&:active, &:focus': {
              backgroundColor: main,
              '&:before': {
                backgroundColor: palette.contrast.active
              }
            }
          },
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&:not(.MuiInputBase-sizeSmall)': {
              minHeight: '3.5rem',
            },
            '&.MuiInputBase-sizeSmall': {
              minHeight: '2.5rem',
              '& .MuiFilledInput-input': {
                padding: spacing(1, 0, 0, 1.5),
              }
            },
          }
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'filled'
        },
        styleOverrides: {
          root: {
            '&:has(input:autofill, input:-webkit-autofill) .MuiFormLabel-root.MuiInputLabel-root': {
              color: primary.dark,
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: spacing(2),
            '&.Mui-disabled': {
              opacity: 0.6
            }
          },
        }
      },
      MuiFormLabel: {
        defaultProps: {
          color: 'primary',
        },
        styleOverrides: {
          root: {
            '&.MuiFormLabel-colorPrimary': {
              color: main,
              '&.Mui-focused': {
                color: main,
              },
            },
          },
        }
      },
      MuiIcon: {
        styleOverrides: {
          colorSecondary: {
            color: palette.text.inactive,
          },
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ ownerState }) => {
            const sharedStyles = {
              maxHeight: '3rem',
              '&.MuiIconButton-sizeSmall': {
                maxHeight: '1.875rem',
              },
              '.MuiInputAdornment-root &': {
                color: 'currentColor'
              }
            }
            if (!ownerState.color || ownerState.color === 'default') {
              return {
                color: 'inherit',
                ...sharedStyles
              }
            }
            return sharedStyles
          },
          sizeSmall: {}
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
            '.MuiInputLabel-shrink + & > .MuiInputBase-input': {
              top: spacing(),
            },
            '.MuiInputLabel-shrink + & > .MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
              top: spacing(),
              marginTop: spacing(0.5),
            },
            '&.Mui-focused .MuiInputBase-input:focused': {
              caretColor: palette.contrast.disabled,
            },
          },
          colorPrimary: ({ ownerState, theme: { palette: colors } }) => {
            const { error, inputProps, value } = ownerState
            let color = colors.contrast.disabled
            let borderColor = color
            let backgroundColor = colors.primary.enabled
            if (error) {
              color = colors.error.main
              borderColor = color
              backgroundColor = colors.error.enabled
            } else if (value || (inputProps && inputProps.value)) {
              color = colors.text.primary
              borderColor = colors.primary.main
            }

            return {
              borderColor,
              color,
              '& input::placeholder': {
                color: colors.contrast.disabled,
                opacity: 1,
              },
              '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor,
              },
              '&.MuiFilledInput-root': {
                backgroundColor,
                '&::before': {
                  borderColor,
                },
                '&:hover': {
                  backgroundColor: colors.primary.active,
                },
                '&.Mui-focused:not(:hover, :has(:autofill, :-webkit-autofill))': {
                  backgroundColor: colors.primary.enabled,
                },
                '&:has(input:autofill, input:-webkit-autofill)': {
                  backgroundColor: 'rgb(232, 240, 254)',
                  '& .MuiInputAdornment-root': {
                    filter: 'brightness(0.8)',
                  },
                },
                '& input:autofill, & input:-webkit-autofill': {
                  boxShadow: 'none',
                  color: colors.background.dark,
                  caretColor: colors.background.dark,
                  textFillColor: colors.background.dark,
                },
              }
            }
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            position: 'relative',
            color: main,
            '.Mui-disabled &': {
              opacity: 0.32,
            },
            '.Mui-error &': {
              color: 'inherit',
            },
          },
          positionEnd: {
            paddingLeft: '0.75rem',
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          formControl: {
            transform: 'translate(16px, 16px) scale(1)',
            '&.MuiInputLabel-sizeSmall': {
              transform: 'translate(13px, 12px) scale(1)',
            },
          },
          shrink: {
            transform: 'translate(12px, 7px) scale(0.75)',
            '&.MuiInputLabel-sizeSmall': {
              transform: 'translate(12px, 2px) scale(0.75)',
            },
          },
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: 6,
            borderRadius: 3,
          },
          bar: {
            borderRadius: 3,
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary?.active ?? defaultPalette.blue.active,
            },
            '&.MuiListItem-padding:not(.MuiListItem-dense, .MuiListItem-secondaryAction)': {
              padding: theme.spacing(1.5, 2)
            },
            '&.MuiListItem-dense': {
              padding: theme.spacing(0.75, 2)
            },
          }),
        }
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: main,
          },
        }
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            lineHeight: 'normal',
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            '&.MuiPopover-root[aria-hidden="true"]': {
              pointerEvents: 'none',
            },
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          }
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            minHeight: 32,
          }
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
          rounded: {
            borderRadius,
          },
        }
      },
      MuiPickerDTTabs: {
        styleOverrides: {
          tabs: { backgroundColor: transparent },
        }
      },
      MuiPickersToolbar: {
        styleOverrides: {
          toolbar: { backgroundColor: transparent },
        }
      },
      MuiPickersToolbarText: {
        styleOverrides: {
          toolbarTxt: {
            '&:not(h6)': { ...typography.h4, fontSize: 40 },
          },
        }
      },
      MuiPopover: {
        defaultProps: {
          slotProps: { root: { sx: { '& .MuiBackdrop-root': { opacity: '0 !important' } } } },
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.MuiRadio-colorPrimary': {
              color: main,
            },
          },
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'filled'
        },
        styleOverrides: {
          disabled: {},
          icon: {
            color: main,
            right: '1rem',
            '[class*=Mui-disabled] &': {
              color: 'inherit'
            }
          },
          select: {
            '&:focus': {
              background: transparent,
            },
          },
          nativeInput: {
            '[class*=MuiSelect-select] + &': {
              pointerEvents: 'none'
            }
          }
        }
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.default,
            color: palette.text.primary,
            fontSize: spacing(1.75),
            paddingRight: spacing(6),
          },
          action: {
            position: 'absolute',
            right: spacing(2),
            top: 8,
          },
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: ({ ownerState }) => {
            if (ownerState.color === 'default' || ownerState.color === 'inherit') {
              return {
                color: 'inherit',
              }
            }
            return undefined
          },
          fontSizeDefault: {
            fontSize: '1.5rem',
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: shallowEqualsMemoizer(({ ownerState, theme: { palette: colors, transitions } }) => {
            const { color: colorName = 'default' } = ownerState
            let { [colorName]: color } = colors
            if (!color) {
              const augmented = colors.augmentColor({ color: colors.grey })
              color = getStringableColorWithStates(augmented.main, 'main', states, augmented)
            }

            return {
              filter: `grayscale(${colors.states.inactive})`,
              transition: transitions.create(['filter'], {
                duration: transitions.duration.shortest,
                easing: transitions.easing.easeInOut
              }),
              '&:has(.Mui-checked)': {
                filter: 'grayscale(0)',
              },
              '& .MuiButtonBase-root.MuiSwitch-switchBase': {
                color: color ? color.main : contrast.inactive,
                '& + .MuiSwitch-track': {
                  backgroundColor: color.disabled,
                }
              },
            }
          }, 4)
        }
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            '&.MuiButtonBase-root.Mui-disabled, &.MuiButtonBase-root:disabled': {
              opacity: states.disabled,
            },
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              background: inputColors.hovered,
              borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
              opacity: 1,
            },
            '&.Mui-disabled': {
              opacity: 0.32,
            },
            '&:not(.Mui-selected):not(.Mui-disabled)': {
              opacity: states.muted,
            }
          },
          textColorPrimary: {
            color: main,
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&.MuiTableCell-body, &.MuiTableCell-head': {
              borderBottomColor: palette.contrastInverse.enabled,
            }
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            background: palette.background.default,
            '&.Mui-TableRow-hover:hover': {
              backgroundColor: palette.background.secondary,
            },
          },
          hover: {},
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'filled'
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: main,
            fontSize: typography.body2.fontSize,
          },
        }
      },
      MuiTypography: {
        defaultProps: {
          variant: 'body1',
        }
      }
    },
    palette,
    pxToRem: options.pxToRem ?? pxToRem,
    shape: {
      borderRadius,
      ...options.shape,
    },
    spacing: options.spacing ?? SPACING,
    spacingValue: options.spacing ?? SPACING,
    typography: { ...typography, ...options?.typography },
  })
})

export const buildThemeFromColor = shallowEqualsMemoizer((initialPrimary = defaultPalette.primary, opts = EMPTY_OBJECT) => {
  let primary = initialPrimary
  if (opts.adjustColor && opts.bg) {
    const mainColor = getMainColor(primary.main, opts.bg)
    primary = { ...primary, main: mainColor.string() }
  }
  const contrastText = primary.contrastText ?? defaultPalette?.white?.strong ?? 'white'

  return buildTheme({
    type: 'dark',
    primary: {
      ...primary,
      ...getStringableColorWithStates(primary.main, 'main', states),
      contrastText,
    },
    secondary: opts.secondary ?? defaultPalette.primary,
    error: opts.error ?? defaultPalette?.error,
    background: opts.background ?? defaultPalette?.background,
    text: opts.text ?? defaultPalette?.text,
  })
})
