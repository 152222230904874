import {
  both,
  curry,
  identity,
  invertObj,
  isEmpty,
  path,
  pipe,
  prop,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import { MAX_CHART_DAYS, QUICK_STALE_AFTER } from '~/src/App/constants'
import { IRRIGATION_VIEW_SPLIT } from '~/src/Chart'
import { isGrowlogRoute, selectZonesForHarvest, urls as harvestUrls } from '~/src/Harvest/bundle'
import {
  capitalizeInitial,
  defer,
  EMPTY_ARRAY as $A,
  EMPTY_OBJECT as $O,
  getDateTime,
  getId,
  shallowEquals,
} from '~/src/Lib/Utils'
import {
  selectFrom,
  selectHarvestForZones,
  selectRoomDashboardGraphs,
  selectTo,
} from '~/src/Room/bundle'
import { isRoomDashboardRoute } from '~/src/Room/bundle/urls'
import { DEFAULT_TIMEFRAME, SETTINGS_PATHS, TIMEFRAME_PRESETS } from '~/src/Room/constants'
import { EMPTY_CHART } from '~/src/Store/bundles/chart'
import { REACTOR_PRIORITIES, URL_ACTION } from '~/src/Store/constants'

import { queryToPatch, rehydrateQuery } from './utils'
import { mergeChartData } from './utils/chart'
import { defaultState } from './utils/defaults'
import { createNamespacedLogger } from './utils/logger'
import { getInitialState, shortCircuitWhenSame } from './utils/reducer'

const logger = createNamespacedLogger('bundle')

const IRRIGATION_EVENT_DATATYPES = ['irrigation_drip_event', 'irrigation_drain_event']
export const PREFIX = 'ROOM_DASHBOARD'
export const INIT_ROOM_STATE = `${PREFIX}_INIT_STATE`
export const PATCH_STATE = `${PREFIX}_PATCH_STATE`
export const NAVIGATE_TO_ROOM = `${PREFIX}_NAVIGATE`
export const ACTIONS = {
  activeAnnotationTypes: `${PREFIX}_SET_ACTIVE_ANNOTATION_TYPES`,
  activeAnnotationUser: `${PREFIX}_SET_ACTIVE_ANNOTATION_USER`,
  activeNotification: `${PREFIX}_SET_ACTIVE_NOTIFICATION`,
  activeTargetType: `${PREFIX}_SET_ACTIVE_TARGET_TYPE`,
  allowedAnnotationTypes: `${PREFIX}_SET_ALLOWED_ANNOTATION_TYPES`,
  chartHeight: `${PREFIX}_SET_CHART_HEIGHT`,
  chartTimeframe: `${PREFIX}_SET_CHART_TIMEFRAME`,
  chartWidth: `${PREFIX}_SET_CHART_WIDTH`,
  cursorMode: `${PREFIX}_SET_CURSOR_MODE`,
  drybackViewOverlay: `${PREFIX}_SET_DRYBACK_VIEW_OVERLAY`,
  individualGraphs: `${PREFIX}_SET_INDIVIDUAL_GRAPHS`,
  individualSensors: `${PREFIX}_SET_INDIVIDUAL_SENSORS`,
  journalOpen: `${PREFIX}_SET_JOURNAL_OPEN`,
  mainGraphZone: `${PREFIX}_SET_MAIN_GRAPH_ZONE`,
  manualFrom: `${PREFIX}_SET_MANUAL_FROM`,
  manualTo: `${PREFIX}_SET_MANUAL_TO`,
  openAnnotation: `${PREFIX}_SET_OPEN_ANNOTATION`,
  selectedDataTypes: `${PREFIX}_SET_SELECTED_DATA_TYPES`,
  selectedHarvest: `${PREFIX}_SET_SELECTED_HARVEST`,
  selectedZones: `${PREFIX}_SET_SELECTED_ZONES`,
  showRoomAvg: `${PREFIX}_SET_SHOW_ROOM_AVG`,
  showYAxis: `${PREFIX}_SET_SHOW_Y_AXIS`,
  viewMode: `${PREFIX}_SET_VIEW_MODE`,
}
const TYPE_TO_STATE_KEY = invertObj(ACTIONS)
export const DEFAULT_STATE = Object.freeze({
  CURRENT: { id: null, ts: 0, viewType: null },
})
export const VIEW_TYPES = {
  GROWLOG: 'growlog',
  ROOM: 'roomDashboard',
}
const CURRENT_KEYS = ['id', 'viewType']
export const NONSTANDARD_ACTIONS = new Set(['selectedHarvest', 'selectedZones'])

const dateFormatter = date => (date ? getDateTime(date).toUTC().toISO() : date)
const selectChartFrom = pipe(selectFrom, dateFormatter)
const selectChartTo = pipe(selectTo, both(identity, dateFormatter))

const sidebarReducer = (state, action) => {
  const { individualGraphs, journalOpen } = state
  if (action.type === ACTIONS.individualGraphs && individualGraphs) {
    return journalOpen ? { ...state, journalOpen: false } : state
  }
  if (action.type === ACTIONS.journalOpen && journalOpen) {
    return individualGraphs ? { ...state, individualGraphs: false } : state
  }
  return state
}
export const defaultReducer = (state = $O, action = $O) => {
  const { [action.type]: stateKey } = TYPE_TO_STATE_KEY
  if (!stateKey) {
    logger.debug('defaultReducer: no stateKey found for action', { type: action?.type, TYPE_TO_STATE_KEY })
    return state
  }
  const { [stateKey]: oldValue } = state
  let { payload } = action
  if (typeof payload === 'function') {
    payload = payload(oldValue)
  }
  if (oldValue === payload) return state

  if (payload === undefined && typeof oldValue === 'boolean') {
    payload = !oldValue
  }
  return {
    ...state,
    [stateKey]: payload
  }
}

const reducers = {
  [ACTIONS.activeNotification]: (state, action) => {
    let { payload } = action
    if (typeof payload === 'function') {
      const { activeNotification: newVal } = defaultReducer(state, action)
      payload = newVal
    }

    if (payload) {
      const from = getDateTime(selectFrom(state))
      const to = getDateTime(selectTo(state) ?? 'now').plus({ hours: 1 })
      const displayedRange = from.until(to)
      const { ts } = payload

      return displayedRange.contains(getDateTime(ts))
        ? { ...state, activeNotification: payload }
        : state
    }
    return defaultReducer(state, action)
  },
  [ACTIONS.chartTimeframe]: (state, action) => {
    const { payload: chartTimeframe } = action

    return {
      ...state,
      chartTimeframe,
      manualFrom: defaultState.manualFrom,
      manualTo: defaultState.manualTo,
    }
  },
  [ACTIONS.individualGraphs]: reduceReducers(
    shortCircuitWhenSame(defaultReducer, 'individualGraphs'),
    sidebarReducer
  ),
  [ACTIONS.journalOpen]: reduceReducers(
    shortCircuitWhenSame(defaultReducer, 'journalOpen'),
    sidebarReducer
  ),
  [ACTIONS.manualFrom]: (state, action) => {
    const { payload: from } = action
    const { manualFrom: oldFrom, manualTo: to } = state

    if (!from) {
      return oldFrom
        ? { ...state, manualFrom: from }
        : state
    }

    const nextState = {
      ...state,
      manualFrom: from,
      chartTimeframe: null,
    }

    const nowDT = getDateTime('now')
    const toDT = to ? getDateTime(to) : nowDT
    const fromDT = getDateTime(from)
    if (toDT.diff(fromDT).as('days') > MAX_CHART_DAYS) {
      logger.debug(
        `manualFrom is too far in the past, setting manualTo to ${MAX_CHART_DAYS} days after manualFrom`,
        JSON.stringify({ manualFrom: fromDT, oldManualTo: toDT, now: nowDT })
      )
      nextState.manualTo = fromDT.plus({ days: MAX_CHART_DAYS })
    }
    return nextState
  },
  [ACTIONS.manualTo]: (state, action) => {
    const { payload: to } = action
    const { chartTimeframe, manualFrom: from, manualTo: oldTo } = state
    if (!to) {
      return oldTo ? {
        ...state,
        manualTo: null,
      } : state
    }
    const nextState = {
      ...state,
      manualTo: to,
      chartTimeframe: null,
    }
    const toDT = getDateTime(to)
    const fromDT = from ? getDateTime(from) : 0
    const diff = fromDT && toDT.diff(fromDT).as('days')

    if (diff > MAX_CHART_DAYS) {
      nextState.manualFrom = toDT.minus({ days: MAX_CHART_DAYS }).startOf('day')
    }
    if (chartTimeframe && !fromDT) {
      nextState.manualFrom = chartTimeframe in TIMEFRAME_PRESETS
        ? toDT.minus(TIMEFRAME_PRESETS[chartTimeframe]).startOf('day')
        : toDT.minus(TIMEFRAME_PRESETS[DEFAULT_TIMEFRAME]).startOf('day')
    }
    return nextState
  },
  [ACTIONS.viewMode]: (state, action) => {
    const nextState = defaultReducer(state, action)
    // If we're switching to irrigation view, and there are multiple zones selected, keep only the first one
    if (nextState.viewMode === IRRIGATION_VIEW_SPLIT) {
      nextState.selectedZones = nextState.selectedZones.slice(0, 1)
      nextState.showRoomAvg = false
    }
    return nextState
  }
}

const patchStateReducer = (state, action) => {
  const patch = Object.entries(action.payload).reduce((acc, [key, value]) => {
    const prev = state[key]
    if (prev === value || !(key in ACTIONS)) {
      return acc
    }

    const { [ACTIONS[key]]: reducer = defaultReducer } = reducers
    const newState = reducer(state, { type: ACTIONS[key], payload: value })

    if (shallowEquals(newState[key], state[key])) {
      return acc
    }
    return {
      ...acc,
      [key]: newState[key],
    }
  }, $O)

  if (patch !== $O) {
    return {
      ...state,
      ...patch,
    }
  }
  return state
}

let trackSelectedZones
const bundle = {
  name: 'roomDashboard',
  reducer: (state = DEFAULT_STATE, action = $O) => {
    if (!action.type || !action.type.startsWith(PREFIX)) return state
    const { type, meta = $O } = action

    if (type === NAVIGATE_TO_ROOM) {
      const validPayload = CURRENT_KEYS.every(k => k in action.payload)
      const changeValues = CURRENT_KEYS.some(k => action.payload[k] !== state.CURRENT[k])
      return validPayload && changeValues
        ? { ...state, CURRENT: { ...action.payload, ts: Date.now() } }
        : state
    }

    const { chartId } = meta
    if (!chartId) {
      logger.debug('roomDashboard reducer: no chartId found in meta', action)
      return state
    }

    if (type === INIT_ROOM_STATE) {
      if (!(chartId in state)) {
        logger.debug('[reducer] initializing room state', { chartId, state, action })
        return {
          ...state,
          [chartId]: getInitialState(action.payload),
        }
      }
      logger.debug('[reducer] not initializing: state already exists', { chartId, state })
      return state
    }

    if (!(chartId in state)) {
      logger.debug('roomDashboard reducer: no state found for chartId, aborting', { chartId, state })
      return state
    }
    const { [chartId]: oldRoomState, ...rest } = state

    if (type === PATCH_STATE) {
      const nextRoomState = patchStateReducer(oldRoomState, action)
      if (shallowEquals(nextRoomState, oldRoomState)) return state
      return {
        ...rest,
        [chartId]: nextRoomState,
      }
    }

    const { [type]: reducer = defaultReducer } = reducers
    const nextRoomState = reducer(oldRoomState, action)
    if (shallowEquals(nextRoomState, oldRoomState)) return state
    return {
      ...rest,
      [chartId]: nextRoomState,
    }
  },
  getMiddleware: () => curry((store, next, action) => {
    // Nothing to do
    if (!action || !action.type) {
      logger.debug('middleware: no action type found, aborting', { action })
      return next({ type: 'NOOP' })
    }
    const routeData = store.selectRoomDashboardRouteData()
    const { chartId, growlog, id } = routeData
    let viewType = growlog ? VIEW_TYPES.GROWLOG : null
    let nextId = id
    const { CURRENT = DEFAULT_STATE.CURRENT, ...roomDashboardStates } = store.selectRoomDashboardRoot()
    let nextChartId = routeData.chartId
    if (action.type === URL_ACTION) {
      const routeMatcher = store.selectRouteMatcher()
      const { pattern, params } = routeMatcher(new URL(action.payload.url, location.href).pathname)
      const isRDRoute = isRoomDashboardRoute({ pattern })
      const isGLRoute = isGrowlogRoute({ pattern })

      if (isRDRoute) {
        nextId = params.id
        viewType = VIEW_TYPES.ROOM
        logger.debug('middleware: navigating to room', { nextId, viewType })
      }
      if (isGLRoute) {
        nextId = params.id
        viewType = VIEW_TYPES.GROWLOG
        logger.debug('middleware: navigating to growlog', { nextId, viewType })
      }
      nextChartId = viewType == VIEW_TYPES.GROWLOG ? `harvests_${nextId}` : `rooms_${nextId}`
    } else {
      const routeInfo = store.selectRouteInfo()
      if (isRoomDashboardRoute(routeInfo)) viewType = VIEW_TYPES.ROOM
      if (routeInfo.pattern === harvestUrls.growlog) viewType = VIEW_TYPES.GROWLOG
    }
    if (action.type !== 'BATCH_ACTIONS') {
      const entering = Boolean(!id && nextId)
      const switching = Boolean(nextId && CURRENT.id && CURRENT.id !== nextId)
      if ((nextId && CURRENT.id !== nextId) || (viewType && viewType !== CURRENT.viewType)) {
        logger.debug('middleware: new CURRENT', { nextId, nextChartId, viewType, action })
        const nextSettingsPath = viewType === VIEW_TYPES.ROOM ? SETTINGS_PATHS.roomDashboard : SETTINGS_PATHS.growlog
        if (entering) {
          const nextSettingsKey = nextSettingsPath.join('.')
          defer(() => {
            store.doUserSettingFetch(nextSettingsKey).then(nextSettings => {
              logger.debug('middleware: fetched user settings', { nextSettings })
              if (!(nextChartId in roomDashboardStates)) return
              store.dispatch({
                type: PATCH_STATE,
                meta: { chartId: nextChartId },
                payload: queryToPatch(nextSettings.settings),
              })
            })
          }, defer.priorities.highest)
        }
        const actions = [
          action,
          {
            type: NAVIGATE_TO_ROOM,
            payload: {
              entering,
              id: nextId,
              viewType
            }
          },
        ]
        if (switching && nextChartId in roomDashboardStates) {
          const transferrableState = rehydrateQuery(path(nextSettingsPath, store.selectMySettings()), true)
          logger.debug('middleware: transferring state', transferrableState)
          actions.push({
            type: PATCH_STATE,
            meta: { chartId: nextChartId },
            payload: transferrableState,
          })
        }
        return store.dispatch({ type: 'BATCH_ACTIONS', actions })
      }
      if (chartId && !(chartId in store.selectRoomDashboardRoot())) {
        const nextReaction = store.getNextReaction()
        if (nextReaction?.result) {
          const { type, meta } = nextReaction.result
          if (type === INIT_ROOM_STATE && meta && meta.chartId === chartId) {
            return store.dispatch({
              type: 'BATCH_ACTIONS',
              actions: [nextReaction.result, action]
            })
          }
        }
      }
    }
    // No need to check meta if we're not dealing with a room dashboard action
    if (!action.type.startsWith(PREFIX)) {
      return next(action)
    }

    const { meta = $O } = action
    if (!meta.chartId || meta.growlog == null) {
      const finalChartId = meta.chartId || chartId
      const finalGrowlog = meta.growlog ?? growlog
      const finalAction = {
        ...action,
        meta: { ...meta, chartId: finalChartId, growlog: finalGrowlog }
      }

      return next(finalAction)
    }
    return next(action)
  }),
  // Add action creators
  ...Object.entries(ACTIONS).reduce((acc, [key, value]) => {
    const stateKeyFragment = capitalizeInitial(key)
    acc[`selectRoomDashboard${stateKeyFragment}`] = createSelector('selectRoomDashboardState', prop(key))
    if (NONSTANDARD_ACTIONS.has(key)) return acc
    acc[`doRoomDashboardSet${stateKeyFragment}`] = (payload, meta) => ({
      type: value,
      payload,
      meta,
    })
    return acc
  }, {}),
  doRoomDashboardSetSelectedHarvest: (selectedHarvest, meta) => ({ store }) => {
    // console.log('setting selectedHarvest', selectedHarvest)
    const { growlog, room, selectedZones: oldZones, viewMode, ...state } = store.selectRoomDashboard()
    const isIrrigationView = viewMode === IRRIGATION_VIEW_SPLIT
    // All room zones
    const allZoneIds = new Set(room.zones?.map(getId) ?? $A)

    if (!growlog && selectedHarvest) {
      const from = selectFrom(state)
      const to = selectTo(state) ?? getDateTime('now')
      const currentTimeframe = from.until(to)

      const activePhaseTypes = [...new Set(selectedHarvest.phases.filter(p => (
        getDateTime(p.startDate).until(getDateTime(p.endDate)).overlaps(currentTimeframe)
      )).map(p => p.phaseType.toLowerCase()))]

      // Harvest zones that are part of this room
      const selectedZones = isIrrigationView
        ? oldZones
        : Array.from(new Set(activePhaseTypes.flatMap(phaseType => selectedHarvest.cultivars.flatMap(hc => (
          hc[`${phaseType}Zones`] ?? $A
        )))))

      store.dispatch({
        type: PATCH_STATE,
        payload: { selectedHarvest, selectedZones },
        meta,
      })
      return
    }

    store.dispatch({
      type: PATCH_STATE,
      payload: {
        selectedHarvest,
        selectedZones: isIrrigationView ? oldZones : Array.from(allZoneIds)
      },
      meta,
    })
  },
  doRoomDashboardSetSelectedZones: (rawPayload, meta) => ({ store }) => {
    let payload = rawPayload
    const { room, growlog } = store.selectRoomDashboardContext()
    if (!growlog && (!room || !Array.isArray(room.zones))) return
    const state = store.selectRoomDashboardState()
    if (typeof payload === 'function') {
      payload = payload(state.selectedZones)
    }
    const allowedZones = growlog
      ? selectZonesForHarvest(state.selectedHarvest) : room.zones.map(getId)
    const selectedZones = payload.filter(z => allowedZones.includes(z)) ?? $A

    if (!growlog) {
      let { chartTimeframe } = state
      const selectedHarvest = selectHarvestForZones({
        growlog,
        room,
        selectedHarvest: state.selectedHarvest,
      }, payload)
      if (chartTimeframe === 'selectedHarvest' && !selectedHarvest) {
        chartTimeframe = DEFAULT_TIMEFRAME
        store.dispatch({
          type: PATCH_STATE,
          payload: {
            selectedZones,
            selectedHarvest,
            chartTimeframe,
          }
        })
        return
      }
    }

    store.dispatch({
      type: ACTIONS.selectedZones,
      payload: selectedZones,
      meta,
    })
  },
  doRoomDashboardPatchState: (payload, meta) => ({
    type: PATCH_STATE,
    payload,
    meta,
  }),
  selectRoomDashboardRoot: state => {
    if (!state || !('roomDashboard' in state) || state.roomDashboard == null) {
      return DEFAULT_STATE
    }
    return typeof state.roomDashboard === 'object' && !Array.isArray(state.roomDashboard)
      ? state.roomDashboard
      : DEFAULT_STATE
  },
  selectRoomDashboardAllowedDataTypes: createSelector(
    'selectAvailableFeatures',
    'selectRoomDashboardRouteData',
    'selectCharts',
    'selectDataTypes',
    'selectDrybackCharts',
    (availableFeatures, system, charts, dataTypes, drybackCharts) => {
      if (!system?.chartId || !charts || !dataTypes) {
        return $A
      }
      const { chartId } = system
      const { [chartId]: chart = EMPTY_CHART } = charts
      const { data: chartData } = chart
      if (!chartData || !Array.isArray(chartData.dataTypes)) {
        return Object.values(dataTypes).map(prop('key'))
      }
      const { [chartId]: drybackChart } = drybackCharts
      if (availableFeatures.has('DRYBACK_DATA') && drybackChart) {
        return [...chartData.dataTypes, ...drybackChart.dataTypes]
      }
      return chartData.dataTypes
    }
  ),
  selectRoomDashboardContext: createSelector(
    'selectIsLandscape',
    'selectIsMobile',
    'selectMySettings',
    'selectQueryAdvanced',
    'selectRoomDashboardRouteData',
    'selectRoomDashboardRoom',
    'selectHarvests',
    'selectPhasesByHarvest',
    'selectRouteInfo',
    'selectSensorDataTypesByRoom',
    (
      isLandscape,
      isMobile,
      mySettings,
      query,
      routeData = $O,
      room = $O,
      harvests = $O,
      phasesByHarvest = $O,
      { params = $O } = $O,
      sensorDTByRoom = $O
    ) => {
      if (!('growlog' in routeData) || !('id' in params)) {
        logger.debug('[selectRoomDashboardContext]: either no growlog in routeData or no id in params', { routeData, params })
        return $O
      }
      const { growlog } = routeData
      const { id } = params
      if (growlog && !(id in harvests)) return $O
      if (!growlog && room === $O) return $O
      const settings = path(SETTINGS_PATHS[growlog ? 'growlog' : 'roomDashboard'], mySettings) ?? $O
      if (growlog) {
        const { [id]: harvest = $O } = harvests

        const phases = harvest.payloadType === 'entity'
          ? phasesByHarvest[id] ?? $A
          : null

        return {
          ...routeData,
          defaultTimeframe: DEFAULT_TIMEFRAME,
          harvest: harvest !== $O && harvest.payloadType === 'entity' ? { ...harvest, phases } : $O,
          isLandscape,
          isMobile,
          query,
          room: $O,
          settings,
        }
      }

      return {
        ...routeData,
        defaultTimeframe: DEFAULT_TIMEFRAME,
        isLandscape,
        isMobile,
        query,
        roomDataTypes: sensorDTByRoom[id] ?? $A,
        room,
        settings,
      }
    }
  ),
  selectRoomDashboardChart: createSelector(
    'selectAvailableFeatures',
    'selectRoomDashboardState',
    'selectRoomDashboardRouteData',
    'selectCharts',
    'selectDrybackCharts',
    'selectDripDrainChart',
    (availableFeatures, { viewMode }, { chartId } = $O, charts = $O, drybackCharts = $O, dripDrainCharts = $O) => {
      const { [chartId]: chart = EMPTY_CHART } = charts

      const hasDryback = availableFeatures.has('DRYBACK_DATA') && chartId in drybackCharts
      const hasIrrigation = availableFeatures.has('DRIP_DRAIN_CHART') && viewMode === IRRIGATION_VIEW_SPLIT && !isEmpty(dripDrainCharts)
      // TODO: `chart.data` should always exist (?), but something is setting `charts: { rooms_1: { data: undefined } }`
      // (EMPTY_CHART has `chart.data.graphs: []` defined)
      if (!(hasDryback || hasIrrigation) || !chart.data?.graphs?.length) {
        return { ...chart, id: chartId }
      }

      const { [chartId]: drybackChartData } = drybackCharts
      const { [chartId]: irrigationChartData } = dripDrainCharts

      let data = chart.data
      if (hasDryback) {
        data = mergeChartData(data, drybackChartData)
      }
      if (hasIrrigation) {
        data = mergeChartData(data, irrigationChartData)
      }

      const mergedChart = {
        id: chartId,
        ...chart,
        data,
      }

      return mergedChart
    }
  ),
  selectRoomDashboardChartParams: createSelector(
    'selectRoomDashboardRouteData',
    'selectRoomDashboardState',
    ({ chartId } = $O, { individualSensors, initialized, ...state } = $O) => (
      chartId && initialized
        ? [chartId, {
          start: selectChartFrom(state),
          end: selectChartTo(state),
          grouping: individualSensors ? 'DEVICE' : undefined,
        }]
        : $A
    )
  ),
  selectRoomDashboardGraphZones: createSelector(
    'selectRoomDashboardChart',
    chart => {
      const { data } = chart
      if (!data) return $A
      const { graphs } = data
      if (!Array.isArray(graphs)) return $A
      return graphs.reduce((acc, { zone, id }) => {
        if (typeof zone === 'number') {
          if (!acc.includes(zone)) {
            acc.push(zone)
          }
          return acc
        }
        const [type, identifier] = id.split(':').slice(-2)
        const graphZone = id.includes('room') || String(zone).includes('room')
          ? 'room'
          : `${type}_${identifier}`
        if (!acc.includes(graphZone)) {
          acc.push(graphZone)
        }
        return acc
      }, [])
    }
  ),
  selectRoomDashboardHarvests: createSelector(
    'selectRoomDashboardRoot',
    'selectRoomDashboardRouteData',
    'selectRoomHarvestsRaw',
    'selectCurrentRoomHarvests',
    'selectRoomCultivarsRaw',
    'selectRoomCultivarsByHarvest',
    'selectLastHarvestRaw',
    'selectLastHarvest',
    'selectNextHarvestRaw',
    'selectNextHarvest',
    (
      { CURRENT } = DEFAULT_STATE,
      { growlog, id: routeId } = $O,
      { lastSuccess: harvestsFetched, room: harvestRoom } = $O,
      roomHarvests = $A,
      { lastSuccess: cultivarsFetched, room: cultivarRoom } = $O,
      cultivarsByHarvest = $O,
      { room: lastHarvestRoom } = $O,
      lastHarvest = $O,
      { room: nextHarvestRoom } = $O,
      nextHarvest = $O
    ) => {
      const { id, ts } = CURRENT
      if (growlog || !id || id !== routeId) return $O

      const harvestsStale = harvestRoom != id || harvestsFetched < (ts - (QUICK_STALE_AFTER * 1.1))
      const cultivarsStale = cultivarRoom != id || cultivarsFetched < (ts - (QUICK_STALE_AFTER * 1.1))
      if (harvestsStale || cultivarsStale) {
        return {
          currentHarvests: $A,
          lastHarvest: null,
          nextHarvest: null,
        }
      }

      return {
        currentHarvests: Array.isArray(roomHarvests)
          ? roomHarvests.map(harvest => ({
            ...harvest,
            cultivars: cultivarsByHarvest[harvest.id] ?? $A,
          }))
          : $A,
        lastHarvest: lastHarvestRoom == id ? (lastHarvest?.id ?? null) : null,
        nextHarvest: nextHarvestRoom == id ? (nextHarvest?.id ?? null) : null,
      }
    }
  ),
  selectRoomDashboardRoom: createSelector(
    'selectRoomDashboardRouteData',
    'selectDevicesByRoomAndZone',
    'selectRoomDashboardHarvests',
    'selectRooms',
    'selectZones',
    (
      { growlog, id } = $O,
      devicesByRoomAndZone = $O,
      roomHarvests = $O,
      rooms = $O,
      zones = $O
    ) => {
      if (!id || growlog) return $O

      const { [id]: room } = rooms
      if (!room) return $O

      const { [id]: roomDevices = $O } = devicesByRoomAndZone
      const { devices, ...byZone } = roomDevices
      return {
        ...room,
        ...roomHarvests,
        devices,
        deviceCount: room?.devices?.length ?? 0,
        zones: Array.isArray(room.zones)
          ? room.zones.map(zoneId => (
            zoneId in zones
              ? { ...zones[zoneId], devices: byZone[zoneId] ?? $A }
              : null
          )).filter(Boolean)
          : $A,
      }
    }
  ),
  selectRoomDashboardRouteData: createSelector(
    'selectRouteInfo',
    routeInfo => {
      const { params = $O, pattern = $O } = routeInfo
      const growlog = pattern === harvestUrls.growlog
      const roomDashboardRoute = isRoomDashboardRoute(routeInfo)
      if (!growlog && !roomDashboardRoute) {
        return $O
      }
      let chartId = ''
      const { id } = params
      if (id) {
        if (growlog) {
          chartId = `harvests_${id}`
        }
        if (roomDashboardRoute) {
          chartId = `rooms_${id}`
        }
      }

      return {
        chartId,
        growlog,
        id: chartId ? id : '',
      }
    }
  ),
  selectRoomDashboardState: createSelector(
    'selectRoomDashboardRouteData',
    'selectRoomDashboardRoot',
    ({ chartId }, roomDashboard = $O) => {
      const { [chartId]: rdState } = roomDashboard
      if (!rdState) {
        logger.debug('selectRoomDashboardState: no state found for chartId', { chartId, roomDashboard })
        return { ...defaultState, initialized: false }
      }
      return { ...rdState, initialized: true }
    }
  ),
  // combines local state and external data into a single object
  selectRoomDashboard: createSelector(
    'selectRoomDashboardState',
    'selectRoomDashboardRouteData',
    'selectRoomDashboardChart',
    'selectRoomDashboardAllowedDataTypes',
    'selectRoomDashboardGraphZones',
    'selectRoomDashboardRoom',
    'selectHarvests',
    'selectPhasesByHarvest',
    (rdState, routeData, chart, allowedDataTypes, graphZones, room, harvests, phasesByHarvest) => {
      const { data: chartData } = chart

      const { viewMode, selectedDataTypes } = rdState

      const combinedState = {
        ...rdState,
        ...routeData,
        allowedDataTypes,
        graphZones,
        room,
      }

      if (routeData.growlog && routeData.id) {
        const { id } = routeData
        const { [id]: harvest } = harvests
        const { [id]: phases } = phasesByHarvest

        combinedState.harvest = { ...harvest, phases }
      }

      if (!shallowEquals(trackSelectedZones, rdState.selectedZones)) {
        logger.debug('selectedZones changed', { prev: trackSelectedZones, next: rdState.selectedZones })
        trackSelectedZones = rdState.selectedZones
      }

      return {
        ...combinedState,
        chartRange: chartData?.range ?? $O,
        ...selectRoomDashboardGraphs({
          ...combinedState,
          chartData,
          allowedDataTypes: viewMode === IRRIGATION_VIEW_SPLIT
            ? [...new Set([
              ...allowedDataTypes,
              ...IRRIGATION_EVENT_DATATYPES
            ])]
            : combinedState.allowedDataTypes,
          selectedDataTypes: viewMode === IRRIGATION_VIEW_SPLIT
            ? [...new Set([
              ...selectedDataTypes,
              ...IRRIGATION_EVENT_DATATYPES
            ])]
            : combinedState.selectedDataTypes,
        })
      }
    }
  ),
  selectRoomDashboardDataTypes: createSelector(
    'selectAllDataTypes',
    'selectDataTypes',
    'selectRoomSelectedDataTypes',
    (allDataTypes, dataTypes, defaultDataTypes) => ({
      allDataTypes,
      dataTypes,
      defaultDataTypes,
    })
  ),
  reactRoomDashboardInitialize: createSelector(
    'selectRoomDashboardRoot',
    'selectRoomDashboardContext',
    'selectRoomDashboardDataTypes',
    'selectCharts',
    'selectQueryAdvanced',
    'selectUsers',
    'selectHarvests',
    'selectPhasesByHarvest',
    'selectUserSettings',
    'selectUserSettingsDirty',
    (
      root,
      dashboard,
      dataTypes,
      charts,
      query,
      users,
      harvests,
      phasesByHarvest,
      userSettings,
      userSettingsDirty = $O
    ) => {
      const { chartId, growlog, harvest, room } = dashboard
      if (growlog && harvest === $O) {
        logger.debug('unable to initialize because harvest data is not loaded')
        return null
      }
      if (!growlog && room === $O) {
        logger.debug('unable to initialize because room data is not loaded')
        return null
      }
      const { CURRENT } = root
      // Already initialized
      if (!chartId || chartId in root) {
        if (chartId) logger.debug('[reactRoomDashboardInitialize]: already initialized', { chartId, root, state: root[chartId] })
        else logger.debug('[reactRoomDashboardInitialize]: no chartId')
        return null
      }
      const settingsKey = (CURRENT.viewType === VIEW_TYPES.ROOM ? SETTINGS_PATHS.roomDashboard : SETTINGS_PATHS.growlog).join('.')
      const { [settingsKey]: setting = $O } = userSettings
      if (CURRENT.entering) {
        const { [settingsKey]: settingsDirty = $O } = userSettingsDirty
        if (
          (settingsDirty.lastError?.ts ?? 0) < CURRENT.ts
            && (setting.fetchedAt ?? 0) < CURRENT.ts) {
          return null
        }
      }

      const payload = {
        ...dashboard,
        ...dataTypes,
        chart: charts[chartId] ?? EMPTY_CHART,
        query,
        users,
      }
      if (setting !== $O) {
        payload.settings = setting.settings
      }

      logger.debug('[reactRoomDashboardInitialize]: initializing', payload)
      return {
        type: INIT_ROOM_STATE,
        payload,
        meta: { chartId },
        priority: REACTOR_PRIORITIES.HIGH,
      }
    }
  ),
}

export default bundle
