import PropTypes from 'prop-types'
import {
  compose,
  evolve,
  pick,
  trim,
} from 'ramda'

import { validIdOrNil } from '~/src/Lib/Data'

import { CALIBRATION_TYPES } from '../utils'

const aqualabSettingsShape = PropTypes.shape({
  userCalibrationFrequency: PropTypes.string,
  maxOffset: PropTypes.number,
  standards: PropTypes.arrayOf(PropTypes.string),
})

const shapeConfig = {
  assigned: PropTypes.bool,
  aqualab: aqualabSettingsShape,
  deviceName: PropTypes.string,
  id: PropTypes.number,
  provisionedOn: PropTypes.string,
  sensor: PropTypes.shape({
    calibrationType: PropTypes.oneOf(CALIBRATION_TYPES),
    id: PropTypes.number,
    modelKey: PropTypes.string
  }),
  serialNumber: PropTypes.string,
  room: PropTypes.number,
  zone: PropTypes.number,
}
export const shape = PropTypes.shape(shapeConfig)

const transforms = {
  id: Number,
  deviceName: trim,
  serialNumber: trim,
  room: validIdOrNil,
  zone: validIdOrNil,
  assigned: Boolean,
  provisionedOn: String,
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick(Object.keys(shapeConfig))
)

export const prepareData = raw => {
  const cleanData = cleaner(raw)
  if (cleanData.deviceName.length === 0) {
    cleanData.deviceName = cleanData.serialNumber
  }
  return cleanData
}
