import { createSelector } from 'redux-bundler'

import { seconds } from 'milliseconds'
import { normalize } from 'normalizr'

import connectWebSocket from '~/src/IO/Socket'
import { ENTITIES_RECEIVED } from '~/src/Lib/createEntityBundle'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import {
  debounce,
  defer,
  EMPTY_OBJECT,
  getClientId,
  uniqueId,
} from '~/src/Lib/Utils'
import * as schemas from '~/src/Store/Schemas'

import { REACTOR_PRIORITIES } from '../constants'

const logger = createLogger('Store/sockets')
const debouncedWarn = debounce(logger.warn, 5_000, true, 30_000)
const defaultState = {
  connecting: {},
  connections: {},
  lastError: null,
}
let nextReaction = 0
let lastReactionMembership = 0
let job = null
let received = 0
let handled = 0
let mine = 0
let invalid = 0

const CONNECT_SOCKET = 'CONNECT_SOCKET'
const CONNECTING_SOCKET = 'CONNECTING_SOCKET'
const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET'
const PACKET_HANDLERS = {
  DEFAULT: packet => {
    logger.warn('unknown packet configuration', { packet, schemas })
  },
  PERFORM_ACTION: (packet, store, dispatch) => {
    const { function: functions } = packet
    if (!Array.isArray(functions)) return
    functions.forEach(f => {
      if (typeof f === 'string' && f in store) {
        store[f]()
        return
      }
      if (f?.actionCreator && f.actionCreator in store) {
        dispatch(f)
        return
      }
      invalid += 1
      logger.debug('PERFORM_ACTION with invalid configuration', {
        packet,
        actionCreators: Object.keys(store).filter(k => k.match(/^do[A-Z]/))
      })
    })
  },
  ENTITY_CHANGE: (packet, store, dispatch) => {
    const { entity, payload, many } = packet

    if (entity in schemas) {
      const { [entity]: entitySchema } = schemas
      const { entities } = normalize(
        payload,
        many ? [entitySchema] : entitySchema
      )
      dispatch({
        type: ENTITIES_RECEIVED,
        payload: entities,
        meta: { ws: 1 },
      })
      return
    }
    invalid += 1
    debouncedWarn('ENTITY_CHANGE with invalid data:', packet, schemas)
  }
}
const PACKET_ID = {
  PERFORM_ACTION: () => uniqueId('performAction_'),
  ENTITY_CHANGE: packet => {
    const { entity, payload, many } = packet
    const { [entity]: entitySchema } = schemas
    if (many || !entitySchema) return uniqueId(entity)
    const id = entitySchema.getId(payload)
    if (id) {
      return `${entity}_${id}`
    }
    return uniqueId(entity)
  },
}

class Queue {
  offset = 0

  data = new Map()

  constructor(idHandlers = PACKET_ID) {
    this.idHandlers = idHandlers
  }

  push(item) {
    const { [item.action]: getId } = this.idHandlers
    const id = getId(item)
    this.data.set(id, item)
  }

  shift() {
    if (this.data.size > 0) {
      const [key, item] = this.data.entries().next().value
      this.data.delete(key)
      return item
    }
    return undefined
  }

  get length() {
    return this.data.size
  }
}

const queue = new Queue(PACKET_ID)

const jobHandler = (store, dispatch, deadline) => {
  const { didTimeout } = deadline
  // If we're running due to a timeout, give ourselves half the time, otherwise
  // give ourselves a 10ms buffer to do housekeeping
  const offset = Math.max(didTimeout ? deadline.timeRemaining() / 2 : 10, 10)
  while (queue.length && ((deadline.timeRemaining() - offset) > 0 || didTimeout)) {
    const work = queue.shift()
    const { [work.action]: handler = PACKET_HANDLERS.DEFAULT } = PACKET_HANDLERS
    handler(work, store, dispatch)
    handled += 1
    if (didTimeout) break
  }
  // If there's still work to do, queue next job
  if (queue.length) {
    job = requestIdleCallback(jobHandler.bind(null, store, dispatch), { timeout: 1000 })
    return
  }
  // No work left
  // Clear job ID
  job = null
}
const socketMessageHandler = ({ packet, store, dispatch }) => {
  received += 1
  if (packet?.client === getClientId()) {
    mine += 1
    logger.debug('event source is me; aborting')
    return
  }
  if (!packet?.action) {
    invalid += 1
    logger.debug('invalid packet shape', packet)
    return
  }
  queue.push(packet)
  if (!job) {
    job = requestIdleCallback(jobHandler.bind(null, store, dispatch), { timeout: 1000 })
  }
  if (received % 100 === 0) {
    logger.debug(store.selectSocketStats())
  }
}

export default {
  name: 'sockets',
  init: store => {
    const { ENVIRONMENT } = store.selectConfig() ?? {}
    const { location } = getGlobal()
    if (ENVIRONMENT !== 'production' && !location.hostname.includes('app.aroya.io')) {
      const channel = new BroadcastChannel('FAKE_WEBSOCKET')
      const fakeWSHandler = event => {
        socketMessageHandler({
          packet: event.data,
          store,
          dispatch: store.dispatch
        })
      }
      channel.addEventListener('message', fakeWSHandler)
      return () => {
        channel.removeEventListener('message', fakeWSHandler)
        channel.close()
        if (job) {
          cancelIdleCallback(job)
        }
      }
    }
    return () => {
      if (job) {
        cancelIdleCallback(job)
      }
    }
  },
  reducer: (state = defaultState, action = EMPTY_OBJECT) => {
    switch (action.type) {
      case CONNECT_SOCKET: {
        const [facilityId, socket] = action.payload
        const { [facilityId]: _, ...nextConnecting } = state.connecting
        return {
          connecting: nextConnecting,
          connections: {
            ...state.connections,
            [facilityId]: socket,
          },
          lastError: null,
        }
      }
      case CONNECTING_SOCKET:
        return {
          ...state,
          connecting: {
            ...state.connecting,
            [action.payload]: Date.now(),
          }
        }
      case DISCONNECT_SOCKET: {
        const {
          connecting: { [action.payload]: _, ...nextConnecting },
          connections: { [action.payload]: oldSocket, ...nextSockets },
        } = state
        if (oldSocket && oldSocket.readyState < 2 && oldSocket.close) {
          defer(() => {
            try {
              oldSocket.close()
            } finally {
              // do nothing
            }
          }, defer.priorities.lowest)
        }
        const nextState = {
          connecting: nextConnecting,
          connections: nextSockets,
        }
        if (action.error) {
          nextState.lastError = action.error
        }
        return nextState
      }
      default:
        return state && 'connecting' in state ? state : { ...state, ...defaultState }
    }
  },
  selectSocketsRoot: state => state.sockets,
  selectSockets: createSelector(
    'selectSocketsRoot',
    sockets => sockets.connections
  ),
  selectSocketStats: () => [
    'WS Message Handler Status:',
    `received packets: ${received}`,
    `my packets:       ${mine}`,
    `invalid packets:  ${invalid}`,
    `handled packets:  ${handled}`,
    `queued packets: ${queue.length}`,
  ].join('\n'),
  reactConnectSocket: createSelector(
    'selectFullMe',
    'selectSocketsRoot',
    'selectCurrentFacilityId',
    (me, sockets, currentFacilityId) => {
      if (!me || !currentFacilityId) {
        logger.warn('Missing data, aborting:', { me, currentFacility: currentFacilityId })
        return undefined
      }

      const now = Date.now()
      if (
        now < nextReaction
        && lastReactionMembership === currentFacilityId
        && currentFacilityId in sockets.connections
      ) {
        logger.debug('too soon to retry for this membership')
        return undefined
      }

      if (currentFacilityId in sockets.connecting) {
        const { [currentFacilityId]: connectingTS } = sockets.connecting
        const connectingMS = now - connectingTS
        if (connectingMS < seconds(10)) {
          logger.debug(
            'already trying to connect to this membership, waiting until',
            new Date(connectingTS + seconds(10)).toISOString(),
            'to try again'
          )
          return undefined
        }
      }

      nextReaction = now + seconds(30)
      lastReactionMembership = currentFacilityId
      return currentFacilityId
        && (
          !(currentFacilityId in sockets)
          || (sockets[currentFacilityId]?.readyState ?? 10) > 1
        )
        ? {
          actionCreator: 'doConnectSocket',
          args: [currentFacilityId],
          priority: REACTOR_PRIORITIES.HIGH,
        }
        : undefined
    }
  ),
  doDisconnectSocket: (facility, error) => ({ dispatch }) => {
    dispatch({
      type: DISCONNECT_SOCKET,
      payload: facility,
      error,
    })
  },
  doConnectSocket: currentFacilityId => async ({ store, dispatch }) => {
    dispatch({
      type: CONNECTING_SOCKET,
      payload: currentFacilityId,
    })
    const sockets = store.selectSockets()
    Object.entries(sockets)
      .filter(([facId, socket]) => Number(facId) !== currentFacilityId || socket.readyState > 1)
      .forEach(([facId, socket]) => {
        if (socket && typeof socket.close === 'function') {
          socket.close(1000, 'Switched facilities')
        }
        store.doDisconnectSocket(facId)
      })
    if (!currentFacilityId) {
      logger.warn('doConnectSocket unable to connect due to missing or invalid membership:', currentFacilityId)
    }
    const socket = await connectWebSocket(
      packet => socketMessageHandler({ packet, store, dispatch }),
      error => store.doDisconnectSocket(currentFacilityId, error)
    )
    if (!socket) return
    dispatch({
      type: CONNECT_SOCKET,
      payload: [currentFacilityId, socket],
    })
  },
}
