import { isEmpty } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { createCustomAction } from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { defer, EMPTY_ARRAY } from '~/src/Lib/Utils'
import { KpiProfile as schema } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

import cultivarUrls from './urls'

const logger = createLogger('cultivarKpiProfile/bundle')

const SET_CULTIVAR_KPI_PROFILE_PARAMS = 'SET_CULTIVAR_KPI_PROFILE_PARAMS'

const QUICK_STALE_AFTER = ms.minutes(5)
const NONE = Object.freeze({})

const initialState = {
  cultivar: null
}

const {
  actionIdentifiers: saveProfilePhoto,
  actionReducer: profilePhotoReducer,
} = createCustomAction({
  actionType: 'save', actionName: 'profile_photo', reducerKey: 'photo', loadingKey: 'saving'
})

const initialBundle = createAsyncResourceBundle({
  name: 'cultivarKpiProfile',
  actionBaseType: 'CULTIVAR_KPI_PROFILE',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  schema,
  getPromise: ({ apiFetch, store }) => {
    const { cultivar } = store.selectCultivarKpiProfileRaw()
    if (!cultivar) {
      return Promise.reject(new Error('No cultivar selected'))
    }
    return apiFetch(`/cultivars/${cultivar}/kpi_profile/`).then(res => res ?? NONE)
  },
})

export default {
  ...initialBundle,
  reducer: reduceReducers(initialBundle.reducer, (state, action) => {
    if (action.type && action.type.startsWith(saveProfilePhoto.types.prefix)) {
      return profilePhotoReducer(state, action)
    }
    switch (action.type) {
      case SET_CULTIVAR_KPI_PROFILE_PARAMS: {
        const { cultivar } = action.payload
        const newState = { ...state, cultivar, data: cultivar && cultivar == state.data?.organizationCultivar ? state.data : null }
        return newState
      }
      default:
        if (!Object.keys(initialState).every(key => key in state)) {
          return { ...initialState, ...state }
        }
        return state
    }
  }),
  doKpiProfilePhotoSave: ({ kpiProfileId, photo }) => async ({ store, dispatch, apiFetch }) => {
    dispatch({ type: saveProfilePhoto.types.start })
    const { images } = store.selectCultivarKpiProfile()
    let result = false
    try {
      result = await apiFetch(
        `/kpiProfiles/${kpiProfileId}/`,
        { id: kpiProfileId, images: { ...images, profile: photo } },
        { method: 'PATCH' }
      )
      dispatch({ type: saveProfilePhoto.types.succeed, payload: result })
      defer(() => store.doFetchCultivarKpiProfile(kpiProfileId), defer.priorities.low)
    } catch (error) {
      result = error
      dispatch({ type: saveProfilePhoto.types.fail, error })
    }
    return result
  },
  doKpiBackgroundPhotoSave: ({ kpiProfileId, photo }) => async ({ store, dispatch, apiFetch }) => {
    dispatch({ type: saveProfilePhoto.types.start })
    const { images } = store.selectCultivarKpiProfile()
    let result = false
    try {
      result = await apiFetch(
        `/kpiProfiles/${kpiProfileId}/`,
        { id: kpiProfileId, images: { ...images, background: photo } },
        { method: 'PATCH' }
      )
      dispatch({ type: saveProfilePhoto.types.succeed, payload: result })
      defer(() => store.doFetchCultivarKpiProfile(kpiProfileId), defer.priorities.low)
    } catch (error) {
      result = error
      dispatch({ type: saveProfilePhoto.types.fail, error })
    }
    return result
  },
  doCultivarKpiProfileSetParams: payload => ({ dispatch, store }) => {
    if (!payload) return
    const cultivarKpiProfileRaw = store.selectCultivarKpiProfileRaw()
    // Don't thrash when the same value is getting set
    if (Object.entries(payload).every(([key, value]) => cultivarKpiProfileRaw[key] === value)) return
    dispatch({ type: 'SET_CULTIVAR_KPI_PROFILE_PARAMS', payload })
    if (payload.cultivar && payload.cultivar != cultivarKpiProfileRaw.data?.organizationCultivar) {
      dispatch({ actionCreator: 'doMarkCultivarKpiProfileAsOutdated' })
    }
  },
  selectCultivarImageGallery: createSelector(
    'selectCultivarKpiProfile',
    'selectKpiRecipes',
    'selectCultivarKpis',
    (cultivarKpiProfile, kpiRecipes, cultivarKpis) => {
      const kpiImageGalleryRecipeId = Object.values(kpiRecipes).find(({ view }) => view === 'Image Gallery')?.id
      const imageGalleryKpi = cultivarKpis?.find(({ kpiProfile, kpiRecipe }) => kpiProfile == cultivarKpiProfile?.id && kpiRecipe == kpiImageGalleryRecipeId) ?? EMPTY_ARRAY
      if (isEmpty(imageGalleryKpi)) return EMPTY_ARRAY
      return imageGalleryKpi.value
    }
  ),
  selectCultivarKpiProfileShouldNormallyUpdate: initialBundle.selectCultivarKpiProfileShouldUpdate,
  selectCultivarKpiProfileShouldUpdate: createSelector(
    'selectCultivarKpiProfileRaw',
    'selectCultivarKpiProfileShouldNormallyUpdate',
    'selectRouteInfo',
    ({ isLoading, lastSuccess, cultivar }, shouldNormallyUpdate, { pattern }) => {
      if (isLoading || !cultivar || pattern !== cultivarUrls.view) {
        logger.debug('should not update, already fetching or no cultivar or not in cultivar profile')
        return false
      }
      if (shouldNormallyUpdate) {
        logger.debug('should update, normally')
        return true
      }
      const isQuickStale = (!lastSuccess || Date.now() - lastSuccess >= QUICK_STALE_AFTER)
      if (isQuickStale) {
        logger.debug('should update, quick stale?', { isQuickStale })
      }
      return isQuickStale
    }
  ),
  reactSetCultivarKpiProfileParams: createSelector(
    'selectCultivarKpiProfileRaw',
    'selectRouteInfo',
    ({ cultivar }, routeInfo) => {
      const { pattern, params } = routeInfo
      if (pattern !== cultivarUrls.view) {
        logger.debug('[reactSetCultivarKpiProfileParams] not in cultivar profile')
        return cultivar ? { actionCreator: 'doCultivarKpiProfileSetParams', args: [initialState] } : null
      }
      const { id } = params

      if (cultivar != id) {
        const nextParams = { cultivar: id }
        logger.debug('[reactSetCultivarKpiProfileParams] params changed, updating', nextParams)
        return { actionCreator: 'doCultivarKpiProfileSetParams', args: [nextParams] }
      }
      return null
    }
  ),
  reactCultivarKpiProfileFetch: createAppIsReadySelector({
    dependencies: [
      'selectCultivarKpiProfileShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (pattern === '/cultivars/:id' && shouldUpdate) {
        return { actionCreator: 'doFetchCultivarKpiProfile' }
      }
      return undefined
    }
  }),

}
