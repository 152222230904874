import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Helmet } from 'react-helmet'

import appConfig from '~/src/App/config'

/**
 * Renders its children into the document's head
 * @component
 * @alias Head
 */
const HeadComponent = ({ children }) => {
  const { config } = useConnect('selectConfig')
  const appName = config.APP || appConfig.APP
  return (
    <Helmet titleTemplate={`%s | ${appName}`} defaultTitle={appName}>
      {children}
    </Helmet>
  )
}
HeadComponent.displayName = 'Head'
HeadComponent.propTypes = {
  children: PropTypes.node,
}

HeadComponent.defaultProps = {
  children: null,
}

export default HeadComponent
