import { equals, omit, path } from 'ramda'
import { createSelector } from 'redux-bundler'

import { singularize } from 'inflection'
import { normalize } from 'normalizr'

import { annotationTypes } from '~/src/Annotations/constants'
import { fetchJournal } from '~/src/Journal/bundle'
import { doEntitiesReceived, getAsyncActionIdentifiers } from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import {
  defer,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
} from '~/src/Lib/Utils'
import { Annotation } from '~/src/Store/Schemas'

import { getDefaultState, getFetchReducer, provideChartId } from './utils'

const logger = createLogger('Annotations/bundle')

const fetch = getAsyncActionIdentifiers('fetch', 'annotations')

const fetchReducer = getFetchReducer(fetch.types)

export const handleAnnotations = (annotations, store) => {
  if (!annotations) return EMPTY_ARRAY
  const categories = store.selectNoteCategories()
  const { entities, result } = normalize(annotations, [Annotation])

  store.dispatch(doEntitiesReceived(entities))
  return result.map(item => {
    if (!item.data) {
      return null
    }
    const { data: { id, schema }, uuid, ...entry } = item

    return {
      ...entry,
      id,
      schema,
      uuid,
      category:
        schema === 'notes'
          ? (categories?.[entities?.[schema]?.[id]?.category] ?? null)
          : null,
    }
  }).filter(Boolean)
}

const timestampKeys = [
  'harvestDate',
  'completedOn',
  'dueDate',
  'startDate',
  'ts',
  'createdOn',
]

const handleInvalidateChart = (id, url, dispatch, options) => {
  if (!options.invalidateChart || !id) return

  let chartId

  if (url.match(/growlog/)) {
    chartId = `harvests_${id}`
  }

  if (url.match(/rooms/)) {
    chartId = `rooms_${id}`
  }

  if (chartId) {
    if (options.invalidateChart) {
      defer(() => dispatch({
        actionCreator: 'doMarkChartOutdated',
        args: [chartId],
      }), 133)
    }
  }
}

const defaultAnnotationsOutdatedOption = { invalidateChart: false }
export const markAnnotationsOutdatedWrapper = (handler, options = defaultAnnotationsOutdatedOption) => async kwargs => {
  const {
    params: { id },
    url,
  } = kwargs.store.selectRouteInfo()

  const result = await handler(kwargs)
  if (!url.match(/^\/(rooms\/\d+|harvests\/\d+\/growlog|dashboard|journal)/)) {
    return result
  }
  const { dispatch, payload, store } = kwargs
  handleInvalidateChart(id, url, dispatch, options)
  const journal = store.selectFacilityJournal()

  if (result == null || typeof result !== 'object') {
    const { uuid } = payload ?? EMPTY_OBJECT

    if (!uuid) {
      console.warn('[markAnnotationsOutdatedWrapper] no uuid, unable to proceed', { result, payload, id, url })
    }

    dispatch({
      type: fetchJournal.types.succeed,
      payload: {
        ...journal,
        data: journal.items.filter(item => item.uuid !== uuid),
      },
      meta: { replace: true }
    })
    return result
  }

  let timestampSource = (result || kwargs.payload)
  if (Array.isArray(timestampSource)) {
    timestampSource = timestampSource[0]
  }
  const timestamp = timestampSource[timestampKeys.find(key => timestampSource[key])]
  if (timestamp) {
    const datetime = getDateTime(timestamp)
    defer(() => {
      kwargs.store.doJournalFetch({
        ...journal.params,
        start: datetime.minus({ days: 1 }).toISODate(),
        end: datetime.plus({ days: 1 }).toISODate(),
      }, { replace: false })
    }, defer.priorities.low)
  }
  return result
}

export const ANNOTATIONS_CLEAR = 'ANNOTATIONS_CLEAR'
export const ANNOTATIONS_MARK_OUTDATED = 'ANNOTATIONS_MARK_OUTDATED'
export const ANNOTATIONS_UPDATE = 'ANNOTATIONS_UPDATE'

export default {
  name: 'annotations',
  reducer: (state = getDefaultState(), action) => {
    if (action.type && action.type.startsWith(fetch.types.prefix)) {
      return fetchReducer(state, action)
    }
    switch (action.type) {
      case ANNOTATIONS_UPDATE: {
        const { chartId, params } = action.payload
        return {
          ...state,
          params: {
            ...state.params,
            [chartId]: params,
          },
          stale: {
            ...state.stale,
            [chartId]: true,
          },
        }
      }
      case ANNOTATIONS_CLEAR: {
        const { payload: chartId } = action
        const omitter = chartId ? omit([chartId]) : null
        return chartId
          ? Object.entries(state).reduce((newState, [stateKey, stateSlice]) => ({
            ...newState,
            [stateKey]: chartId in stateSlice ? omitter(stateSlice) : stateSlice
          }), EMPTY_OBJECT)
          : getDefaultState()
      }
      case ANNOTATIONS_MARK_OUTDATED: {
        const { chartId } = action.payload
        return {
          ...state,
          stale: {
            ...state.stale,
            [chartId]: true,
          },
        }
      }
      default:
        return state
    }
  },
  selectAnnotationsRoot: state => state.annotations,
  selectAnnotations: createSelector(
    'selectAnnotationsRoot',
    root => root && root.data
  ),
  selectRoomAnnotationTypes: createSelector(
    'selectJournal',
    ({ items: annotations }) => annotationTypes.filter(({ schema }) => !annotations || Boolean(annotations.find(a => a.schema === schema)))
  ),
  doAnnotationsFetch: provideChartId((chartId, params) => async ({ dispatch, store }) => {
    if (!chartId) {
      console.warn('no chartId or invalid chartId:', { chartId, params })
      return false
    }

    const { currentRoomId } = store.select(['selectCurrentRoomId'])
    const isRoomChart = chartId.startsWith('room')
    const [, roomIdRaw] = isRoomChart ? chartId.split(/[-_]/g) : EMPTY_ARRAY
    const roomId = Number(roomIdRaw)

    // If we're trying to fetch annotations for a room that's not active, don't.
    if ((isRoomChart && Number(currentRoomId) !== roomId)) {
      logger.warn('chartId invalid:', { isRoomChart, chartId, currentRoomId })
      dispatch({ actionCreator: 'doAnnotationsClear', args: [chartId] })
      return false
    }

    const {
      inflight: { [chartId]: inflight },
      params: { [chartId]: prevParams }
    } = store.selectAnnotationsRoot()
    if (inflight && equals(params, prevParams)) return true
    dispatch({ type: fetch.types.start, payload: { chartId, params } })
    // chartId => rooms_543
    const [key, id] = chartId.split('_')
    const response = await store.doJournalFetch({ ...params, [singularize(key)]: id })
    if (response.isIOError) {
      dispatch({ type: fetch.types.fail, error: response, payload: chartId })
      return null
    }
    const data = response.data ? handleAnnotations(response.data, store) : null
    defer(() => {
      dispatch({
        type: fetch.types.succeed,
        payload: {
          ...response,
          chartId,
          data,
        },
      })
    }, 0)
    return data
  }),
  doAnnotationsUpdate: provideChartId((chartId, params) => ({
    type: ANNOTATIONS_UPDATE,
    payload: { chartId, params },
  })),
  doAnnotationsClear: (chartId = null) => ({ type: ANNOTATIONS_CLEAR, payload: chartId }),
  doMarkAnnotationsOutdated: provideChartId(chartId => ({
    type: ANNOTATIONS_MARK_OUTDATED,
    payload: { chartId, type: 'annotations' },
  })),
  reactUpdateStaleAnnotations: createSelector(
    path(['annotations', 'stale']),
    path(['annotations', 'inflight']),
    path(['annotations', 'params']),
    (stale, inflight, params) => {
      const needsUpdate = Object.keys(stale).find(key => !inflight[key])
      if (!needsUpdate) return undefined
      return {
        actionCreator: 'doAnnotationsFetch',
        args: [needsUpdate, params[needsUpdate]],
      }
    }
  ),
}
