import { clone, map, mean } from 'ramda'

import createLogger from '~/src/Lib/Logging'
import { createGuardedSelector } from '~/src/Store/utils'

import { isRoomDashboardRoute } from './urls'

const displayName = 'Room/bundle#utils'
const logger = createLogger(displayName)

export const createRoomDashboardReactor = kwargs => createGuardedSelector({
  ...kwargs,
  guardDependencies: ['selectRouteInfo'],
  guardFn: isRoomDashboardRoute,
})

export const EMPTY_CHART_DATA_OBJECT = {
  bounds: {
    dataType: {},
    graph: {},
    unit: {},
  },
  data: {},
  dataTypes: [],
  graphs: [],
  latest: {},
}
export const getChartDataFromDrybackData = (drybackData, entities = {}) => {
  const data = clone(EMPTY_CHART_DATA_OBJECT)

  const { dataTypes, units, zones } = entities
  if (!(dataTypes && units && zones)) {
    logger.warn('Entities required:', map(Boolean, { dataTypes, units, zones }))
    return data
  }
  if (!Array.isArray(drybackData)) {
    logger.warn('Expected an array of dryback data, received:', drybackData)
    return data
  }

  const roomData = {}  // roomData[zone.room][dataType.key][ts] = [zoneValue, zoneValue, ...]

  drybackData.sort((a, b) => (a.ts < b.ts ? -1 : 1))
  drybackData.forEach(({ ts, zoneId, value, dataType: dataTypeKey }) => {
    const dataType = dataTypes[dataTypeKey]
    const unit = units[dataType?.unit]
    const zone = zones[zoneId]
    if (!dataType || !unit || !zone) return

    const zoneGraphKey = `${dataType.key}:zone:${zone.id}`

    // Room data for aggregation
    if (!(zone.room in roomData)) {
      roomData[zone.room] = {}
    }
    if (!(dataType.key in roomData[zone.room])) {
      roomData[zone.room][dataType.key] = {}
    }
    if (!(ts in roomData[zone.room][dataType.key])) {
      roomData[zone.room][dataType.key][ts] = []
    }
    roomData[zone.room][dataType.key][ts].push(value)

    // Bounds
    if (dataType.key in data.bounds.dataType) {
      const { min, max } = data.bounds.dataType[dataType.key]
      data.bounds.dataType[dataType.key].min = Math.min(min, value)
      data.bounds.dataType[dataType.key].max = Math.max(max, value)
    } else {
      data.bounds.dataType[dataType.key] = { min: value, max: value }
    }
    if (zoneGraphKey in data.bounds.graph) {
      const { min, max } = data.bounds.graph[zoneGraphKey]
      data.bounds.graph[zoneGraphKey].min = Math.min(min, value)
      data.bounds.graph[zoneGraphKey].max = Math.max(max, value)
    } else {
      data.bounds.graph[zoneGraphKey] = { min: value, max: value }
    }
    if (unit.id in data.bounds.unit) {
      const { min, max } = data.bounds.unit[unit.id]
      data.bounds.unit[unit.id].min = Math.min(min, value)
      data.bounds.unit[unit.id].max = Math.max(max, value)
    } else {
      data.bounds.unit[unit.id] = { min: value, max: value }
    }

    // Data
    if (!(zoneGraphKey in data.data)) {
      data.data[zoneGraphKey] = []
    }
    data.data[zoneGraphKey].push({ x: ts, y: value })

    // Data types
    if (!data.dataTypes.includes(dataType.key)) {
      data.dataTypes.push(dataType.key)
    }

    // Latest
    data.latest[zoneGraphKey] = value
  })

  const colorIndexByDataType = {}

  // Graphs (zones)
  data.graphs = Object.entries(data.bounds.graph).map(([graphKey, bounds]) => {
    const [dataTypeKey, _, zoneStr] = graphKey.split(':')
    const zoneId = Number(zoneStr)

    const colorIndex = (colorIndexByDataType[dataTypeKey] ?? -1) + 1
    colorIndexByDataType[dataTypeKey] = colorIndex

    const { alternateColors, color: mainColor, shortName: name, template, unit } = dataTypes[dataTypeKey] ?? {}

    const colorList = alternateColors?.split(',') ?? []
    const color = colorList[colorIndex % colorList.length] ?? mainColor
    const { name: title } = zones[zoneId] ?? {}
    return {
      id: graphKey,
      bounds,
      color,
      dataType: dataTypeKey,
      depth: null,
      name,
      template,
      title,
      type: 'dottedLine',
      unit,
      zone: Number(zoneId),
    }
  })

  Object.entries(roomData).forEach(([roomId, dataByDT]) => {
    Object.entries(dataByDT).forEach(([dataTypeKey, dataByTS]) => {
      const roomGraphKey = `${dataTypeKey}:room:${roomId}`
      let dataTypeMin = Infinity
      let dataTypeMax = -Infinity

      Object.entries(dataByTS).forEach(([ts, values]) => {
        const average = mean(values)

        // Data (room)
        if (!(roomGraphKey in data.data)) {
          data.data[roomGraphKey] = []
        }
        data.data[roomGraphKey].push({ x: ts, y: average })

        // Latest (room)
        data.latest[roomGraphKey] = average

        const min = Math.min(...values)
        const max = Math.max(...values)
        dataTypeMin = Math.min(dataTypeMin, min)
        dataTypeMax = Math.max(dataTypeMax, max)
      })

      const { color, shortName: name, template, unit } = dataTypes[dataTypeKey] ?? {}
      const bounds = { min: dataTypeMin, max: dataTypeMax }

      // Bounds (room)
      data.bounds.graph[roomGraphKey] = bounds

      // Graphs (room)
      data.graphs.push({
        id: roomGraphKey,
        bounds,
        color,
        dataType: dataTypeKey,
        depth: null,
        name,
        template,
        title: 'Room Average',
        type: 'dottedLine',
        unit,
        zone: null,
      })
    })
  })

  return data
}
