import { states } from './colors'
import { defaultFontFamily } from './typography'

export const APP_BAR_HEIGHT = 'clamp(5rem, 8.888vh, 6rem)'

const fontsBucket = 'https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts'
const meterColors = new Set([
  // color names
  'blue', 'green', 'orange', 'purple', 'yellow',
  'denim', 'fig', 'moss', 'royal', 'stone', 'tan', 'terracotta',
  // concept names
  'error',
])
const skipVars = new Set(['batch', 'ipm', 'plantscore'])
const renameVars = {
  background: 'surface',
  appPrimary: 'app-primary',
  contrastInverse: 'contrast-inverse'
}

const skipTypography = new Set([
  'boldFontFamily',
  'button',
  'fontFamily',
  'fontSize',
  'fontWeightBold',
  'fontWeightLight',
  'fontWeightMedium',
  'fontWeightRegular',
  'htmlFontSize',
  'useNextVariants',
])
const renameTypography = {
  subtitle1: 'h5.subtitle',
  subtitle2: 'h6.subtitle',
  body1: 'p',
  body2: 'p.small',
}

export default ({ palette, typography }) => ({
  ':root': Object.entries(palette).reduce((cssVars, [key, config]) => {
    if (skipVars.has(key) || key.match(/^font/) || typeof config === 'function') {
      return cssVars
    }
    if (meterColors.has(key)) {
      const { main, ...rest } = config
      return {
        ...cssVars,
        [`--meter-${key}`]: main,
        ...Object.entries(rest).reduce((varMap, [variant, value]) => (
          typeof value === 'function' ? varMap : {
            ...varMap,
            [`--meter-${key}-${variant}`]: value,
          }), {})
      }
    }

    if (key in renameVars) {
      const name = renameVars[key]
      return typeof config === 'string' ? {
        ...cssVars,
        [`--${name}`]: config,
      } : {
        ...cssVars,
        ...Object.entries(config).reduce((varMap, [variant, value]) => (
          typeof value === 'function' ? varMap : {
            ...varMap,
            [`--${name}-${variant}`]: value,
          }), {})
      }
    }

    if (typeof config === 'object') {
      const { main: _, [config[Symbol.for('defaultColor')]]: defaultColor, ...rest } = config

      const newVars = {
        ...(defaultColor ? { [`--${key}`]: defaultColor } : undefined),
        ...Object.entries(rest).reduce((varMap, [variant, value]) => (
          value === 'function' ? varMap : {
            ...varMap,
            [`--${key}-${variant}`]: value,
          }), {})
      }
      return { ...cssVars, ...newVars }
    }

    return {
      ...cssVars,
      [`--${key}`]: config,
    }
  }, {
    '--app-bar-height': APP_BAR_HEIGHT,
    '--scroll-fg': palette.primary.disabled,
    '--scroll-bg': palette.primary.hovered,
  }),
  '@font-face': [{
    fontFamily: 'Akkurat',
    src: [
      `url('${fontsBucket}/lineto-akkurat-pro-regular.ttf') format('truetype')`,
      `url('${fontsBucket}/lineto-akkurat-pro-regular.woff') format('woff')`,
      `url('${fontsBucket}/lineto-akkurat-pro-regular.woff2') format('woff2')`
    ].join(',\n')
  }, {
    fontFamily: '"Akkurat Bold"',
    src: [
      `url('${fontsBucket}/lineto-akkurat-pro-bold.ttf') format('truetype')`,
      `url('${fontsBucket}/lineto-akkurat-pro-bold.woff') format('woff')`,
      `url('${fontsBucket}/lineto-akkurat-pro-bold.woff2') format('woff2')`
    ].join(',\n')
  }],
  ...Object.entries(typography).reduce((rules, [key, styles]) => (
    skipTypography.has(key) || typeof styles === 'function' ? rules : {
      ...rules,
      [key in renameTypography ? renameTypography[key] : key]: styles,
    }), {}),
  // Begin global mui overrides
  '.Mui-disabled': {
    opacity: states.inactive
  },
  '.Mui-disabled .Mui-disabled': {
    opacity: 1
  },
  '.aroya-login-banner': {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    textAlign: 'center',
    maxHeight: '4rem',
    overflow: 'hidden'
  },
  'html, body': {
    color: 'var(--contrast)',
    minHeight: '100%'
  },
  body: {
    backgroundColor: 'var(--surface-default)',
    fontFamily: defaultFontFamily,
    overflowY: 'auto !important',
    paddingRight: '0 !important',
    scrollbarWidth: 'thin',
    scrollbarColor: 'var(--scroll-fg) var(--scroll-bg)'
  },
  '#root': {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    minHeight: '100vh',
    letterSpacing: '0.25px',
  },
  '#root> :not(#wrapper)': {
    flex: '0 0 auto'
  },
  '#wrapper': {
    flex: '1 1 100%',
    transition: 'backdrop-filter 250ms ease-in-out'
  },
  '#wrapper.fullscreen': {
    alignItems: 'center',
    alignContent: 'center'
  },
  fieldset: {
    border: 'none'
  },
  '#modalRoot>div': {
    alignItems: 'center',
    justifyContent: 'center'
  },
  '#root .hidden': {
    visibility: 'hidden'
  },
  '.pointer': {
    cursor: 'pointer'
  },
  '.linebreaksbr': {
    whiteSpace: 'pre-line'
  },
  '.mar-t-0': {
    marginTop: '0 !important'
  },
  '.mar-b-0': {
    marginBottom: '0 !important'
  },
  '.mar-t-sxl': {
    marginTop: '5rem !important',
    fallbacks: [
      {
        marginTop: '80px !important'
      }
    ]
  },
  '.mar-t-xl': {
    marginTop: '2.5rem !important',
    fallbacks: [
      {
        marginTop: '40px !important'
      }
    ]
  },
  '.mar-t-lg': {
    marginTop: '2rem !important',
    fallbacks: [
      {
        marginTop: '32px !important'
      }
    ]
  },
  '.mar-t-md': {
    marginTop: '1.5rem !important',
    fallbacks: [
      {
        marginTop: '24px !important'
      }
    ]
  },
  '.mar-t-sm': {
    marginTop: '1rem !important',
    fallbacks: [
      {
        marginTop: '16px !important'
      }
    ]
  },
  '.mar-t-xs': {
    marginTop: '0.5rem !important',
    fallbacks: [
      {
        marginTop: '8px !important'
      }
    ]
  },
  '.mar-b-xl': {
    marginBottom: '2.5rem !important',
    fallbacks: [
      {
        marginBottom: '40px !important'
      }
    ]
  },
  '.mar-b-lg': {
    marginBottom: '2rem !important',
    fallbacks: [
      {
        marginBottom: '32px !important'
      }
    ]
  },
  '.mar-b-md': {
    marginBottom: '1.5rem !important',
    fallbacks: [
      {
        marginBottom: '24px !important'
      }
    ]
  },
  '.mar-b-sm': {
    marginBottom: '1rem !important',
    fallbacks: [
      {
        marginBottom: '16px !important'
      }
    ]
  },
  '.mar-b-xs': {
    marginBottom: '0.5rem !important',
    fallbacks: [
      {
        marginBottom: '8px !important'
      }
    ]
  },
  '.pad-t-xl': {
    paddingTop: '2.5rem !important',
    fallbacks: [
      {
        paddingTop: '40px !important'
      }
    ]
  },
  '.pad-t-lg': {
    paddingTop: '2rem !important',
    fallbacks: [
      {
        paddingTop: '32px !important'
      }
    ]
  },
  '.pad-t-md': {
    paddingTop: '1.5rem !important',
    fallbacks: [
      {
        paddingTop: '24px !important'
      }
    ]
  },
  '.pad-t-sm': {
    paddingTop: '1rem !important',
    fallbacks: [
      {
        paddingTop: '16px !important'
      }
    ]
  },
  '.pad-t-xs': {
    paddingTop: '0.5rem !important',
    fallbacks: [
      {
        paddingTop: '8px !important'
      }
    ]
  },
  '.pad-b-xl': {
    paddingBottom: '2.5rem !important',
    fallbacks: [
      {
        paddingBottom: '40px !important'
      }
    ]
  },
  '.pad-b-lg': {
    paddingBottom: '2rem !important',
    fallbacks: [
      {
        paddingBottom: '32px !important'
      }
    ]
  },
  '.pad-b-md': {
    paddingBottom: '1.5rem !important',
    fallbacks: [
      {
        paddingBottom: '24px !important'
      }
    ]
  },
  '.pad-b-sm': {
    paddingBottom: '1rem !important',
    fallbacks: [
      {
        paddingBottom: '16px !important'
      }
    ]
  },
  '.pad-b-xs': {
    paddingBottom: '0.5rem !important',
    fallbacks: [
      {
        paddingBottom: '8px !important'
      }
    ]
  },
  '.alert': {
    borderRadius: '.25rem',
    padding: '1rem'
  },
  '.aroya-login-banner .alert': {
    borderRadius: '0'
  },
  '.nowrap': {
    whiteSpace: 'nowrap'
  },
  '.alert.alert-danger': {
    backgroundColor: 'var(--state-danger) !important',
    color: 'var(--contrast-strong)',
    border: '1px solid var(--state-danger)'
  },
  '.alert.alert-success': {
    backgroundColor: 'var(--batch-8)',
    color: 'var(--contrast-strong)',
    border: '1px solid var(--batch-8)'
  },
  '.text-size-md': {
    fontSize: 16
  },
  '.text-white': {
    color: 'var(--contrast-strong) !important'
  },
  '.text-primary': {
    color: 'var(--app-primary) !important'
  },
  '.text-danger': {
    color: 'var(--meter-error) !important'
  },
  '.text-warning': {
    color: 'var(--warning) !important'
  },
  '.text-bold': {
    fontWeight: '600',
    fontFamily: '"Akkurat Bold" !important'
  },
  '.text-center': {
    textAlign: 'center'
  },
  '.text-left': {
    textAlign: 'left'
  },
  '.text-right': {
    textAlign: 'right'
  },
  '.text-harvest': {
    color: 'var(--batch-5) ! important'
  },
  '.float-left': {
    float: 'left'
  },
  '.float-right': {
    float: 'right'
  },
  '.input-hint': {
    fontSize: 12,
    letterSpacing: 0.1,
    marginTop: 5
  },
  '.input-focused .input-hint': {
    color: `var(--app-primary, var(--primary-main, ${palette.primary.main}))`
  },
  '.subtext': {
    fontSize: 12,
    color: 'var(--contrast-inactive)'
  },
  'p.subheader': {
    fontFamily: 'Akkurat',
    fontSize: 16.7,
    letterSpacing: 0.5,
    color: 'var(--contrast-strong)'
  },
  'a:any-link': {
    textDecoration: 'none'
  },
  'a:not([class])': {
    color: 'inherit !important'
  },
  'img[alt]': {
    color: `var(--${palette.mode === 'light' ? 'black' : 'white'}-faded)`,
    font: '.75rem Akkurat, sans-serif',
    textAlign: 'center'
  },
  '.selected_room': {
    backgroundColor: 'var(--meter-batch-faded) !important'
  },
  '.button-inverse': {
    fontFamily: '"Akkurat Bold"',
    backgroundColor: 'transparent',
    color: `var(--app-primary, var(--primary-main, ${palette.primary.main}))`,
    fontSize: 14,
    border: 'none'
  },
  '.button-inverse:hover': {
    backgroundColor: palette.appPrimary.hovered,
    color: `var(--app-primary, var(--primary-main, ${palette.primary.main}))`
  },
  '.scrollbar': {
    overflowY: 'scroll'
  },
  '.scrollbar-x': {
    overflowX: 'scroll'
  },
  '.rt-card-cardTitle .rt-card-title': {
    textTransform: 'uppercase',
    letterSpacing: '-0.1rem',
    color: 'var(--contrast-disabled)',
  },
  '.rt-card-cardTitle.rt-card-large .rt-card-title': {
    fontSize: '1.4rem'
  },
  '.rt-card-cardTitle>div': {
    position: 'relative',
    width: '100%'
  },
  '.rt-card-cardTitle .rt-button-button': {
    position: 'absolute',
    top: -10,
    right: 0
  },
  '.rt-dropdown-values li': {
    fontSize: '1.2rem'
  },
  '#login': {
    maxWidth: 500,
    margin: '0 auto'
  },
  '.gc-foreign-object-inner-container': {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  '.room-error-flag': {
    transition: 'fill-opacity 0.5s ease-in-out'
  },
  '.room-associated.active .room-error-flag': {
    fillOpacity: '0'
  },
  '.zone-associated.active .room-error-flag': {
    fillOpacity: '1'
  },
  '.hello': {
    position: 'relative'
  },
  '.hello::after': {
    content: '""',
    backgroundColor: palette.yellow.active,
    opacity: '0',
    pointerEvents: 'none',
    position: 'absolute',
    top: -1,
    right: -1,
    bottom: -1,
    left: -1,
    boxShadow: `4px 4px 4px ${palette.yellow.active}`,
    animation: '4s ease-in-out 0.15s wavehello',
    borderRadius: 'inherit'
  },
  '.hello.hello-soft::after': {
    backgroundColor: palette.yellow.enabled,
    boxShadow: `4px 4px 4px ${palette.yellow.enabled}`
  },
  '@keyframes wavehello': {
    '0%': {
      opacity: '0'
    },
    '25%': {
      opacity: '1'
    },
    '75%': {
      opacity: '1'
    },
    '100%': {
      opacity: '0'
    }
  },
  '._pendo-badge._pendo-resource-center-badge-container._pendo-badge_': {
    transform: 'translate(-14px, -1rem)',
    '@media print': {
      display: 'none !important'
    }
  },
  '._pendo-image._pendo-badge-image._pendo-resource-center-badge-image': {
    width: '72px !important',
    padding: '12px 20px 8px !important',
    alignItems: 'center'
  },
  '._pendo-badge._pendo-resource-center-badge-container._pendo-badge_ .pendo-resource-center-badge-notification-bubble': {
    borderRadius: '10px !important',
    height: '21px !important',
    top: '2px !important',
    left: '42px !important',
    padding: '0 7px !important'
  },
  '.aroya .fsp-picker__brand-container, .aroya .fsp-modal__sidebar, .aroya .fsp-header, .aroya .fsp-summary__header': {
    display: 'none'
  },
  '.aroya .fsp-content': {
    height: '100%'
  },
  '.aroya .fsp-picker': {
    backdropFilter: `blur(5px) brightness(${palette.states.strong * 100}%)`,
    backgroundColor: 'var(--contrast-inverse-active)'
  },
  '.aroya .fsp-modal, .aroya .fsp-drop-area, .aroya .fsp-content, .aroya .fst-sidebar, .aroya .fsp-footer, .aroya .fsp-drop-area:hover': {
    backgroundColor: 'transparent'
  },
  '.aroya .fsp-drop-area__title.fsp-text__title': {
    color: 'var(--contrast-strong)',
    marginTop: '0',
    font: 'bold 1.125rem / 1.5rem "Akkurat Bold", Helvetica, Arial, sans-serif'
  },
  '.aroya .fsp-drop-area__subtitle.fsp-text__subheader': {
    color: 'var(--contrast-strong)',
    marginTop: '0',
    font: 'bold 0.875rem / 1.125rem "Akkurat Bold", Helvetica, Arial, sans-serif'
  },
  '.aroya .fsp-select-labels': {
    backgroundImage: 'none'
  },
  '.aroya .fst-sidebar__option, .aroya .fsp-button--cancel': {
    color: 'var(--contrast-strong)'
  },
  '.aroya .fst-sidebar': {
    paddingTop: '2.5rem',
    borderColor: 'transparent !important'
  },
  '.aroya .fst-sidebar .fst-sidebar__option--disabled': {
    background: 'transparent'
  },
  '.aroya .fst-icon.fst-icon--crop-black, .aroya .fst-icon.fst-icon--crop-blue': {
    background: 'url(/images/crop.svg) no-repeat',
    filter: 'invert(1)',
  },
  '.aroya .fst-icon.fst-icon--rotate-black': {
    background: 'url(/images/rotate.svg) no-repeat',
    filter: 'invert(1)',
  },
  '.aroya .fsp-transformer__go-back': {
    background: 'url(/images/back.svg) no-repeat',
    filter: 'invert(1)',
  },
  '.aroya .fsp-picker__close-button.fsp-icon--close-modal, .aroya .fsp-picker__close-button.fsp-icon--close-modal:hover': {
    background: 'none',
    backgroundImage: 'url(/images/close.svg)',
    opacity: '1',
    filter: 'invert(1)',
  },
  '.aroya .fsp-button--primary': {
    backgroundColor: `var(--app-primary, var(--primary-main, ${palette.primary.main}))`
  },
  '.aroya .fsp-button--outline': {
    color: `var(--app-primary, var(--primary-main, ${palette.primary.main}))`,
    borderColor: `var(--app-primary, var(--primary-main, ${palette.primary.main}))`
  },
  '.aroya .fsp-picker--inline': {
    minHeight: '10rem'
  },
  '.aroya .fsp-modal': {
    borderBottom: 'none',
    minHeight: '18rem'
  },
  '.aroya button.filestack-react': {
    display: 'none'
  },
  '.aroya .fsp-modal__sidebar': {
    width: '0'
  },
  '.aroya .fsp-modal__body': {
    width: '100%',
    backgroundColor: `var(--surace-default, ${palette.background.paper})`
  },
  '.aroya .fsp-summary': {
    paddingBottom: '0.5rem'
  },
  '.aroya .fsp-summary__item': {
    background: 'var(--grey)',
    borderRadius: 'var(--default-border-radius)'
  },
  '.aroya .fsp-footer': {
    borderTop: 'none'
  },
  '.aroya .fsp-drop-area-container .fsp-drop-area': {
    borderRadius: 'var(--default-border-radius)',
    border: 'none',
    background: 'var(--contrast-enabled)'
  },
  '.aroya .fsp-drop-area-container .fsp-drop-area:hover': {
    background: 'var(--contrast-hovered)'
  },
  '.aroya .fsp-drop-area-container .fsp-drop-area:active': {
    background: 'var(--contrast-active)'
  },
  '.aroya .fsp-transformer .fsp-transformer__rotate-left': {
    left: 60
  },
  '.aroya .fsp-transformer .fsp-transformer__rotate-right': {
    right: 60
  },
  '.aroya .ar-zone .fsp-drop-area': {
    background: 'var(--contrast-enabled)',
    border: '0',
    boxShadow: 'none'
  },
  '.aroya .ar-zone .fsp-drop-area-container': {
    padding: '0'
  },
  '.aroya .ar-zone .fsp-modal__body': {
    borderRadius: 6,
    height: '100%'
  },
  '.aroya .ar-zone.lab-status': {
    height: '100%'
  },
  '.aroya .ar-zone.lab-status .fsp-drop-area__subtitle, .aroya .ar-zone.lab-status .fsp-drop-area__title': {
    textIndent: -9999,
    lineHeight: '0'
  },
  '.aroya .ar-zone.lab-status .fsp-drop-area__title:after, .aroya .ar-zone.lab-status .fsp-drop-area__subtitle:after': {
    display: 'block',
    color: 'var(--contrast)',
    fontFamily: 'Akkurat',
    textIndent: '0',
    lineHeight: '1',
    fontWeight: 'bold'
  },
  '.aroya .ar-zone.lab-status .fsp-drop-area__title:after': {
    content: '"Click here to upload pdf"',
    fontSize: 18
  },
  '.aroya .ar-zone.lab-status .fsp-drop-area__subtitle:after': {
    content: '"or drag file here"',
    fontSize: 14
  },
})
