import { inflect } from 'inflection'

import {
  durationTypes,
  getDurationInputSelectValue,
  getDurationTypeSelectValue,
  getRepeatAfterSelectValue,
  TIME_DURATION,
  TIME_UNIT_OPTIONS,
} from '~/src/Irrigation/constants'
import {
  formatDuration,
  getDurationInSecondsPerProgram,
  getTotalDurationInSeconds,
} from '~/src/Irrigation/utils'
import createLogger from '~/src/Lib/Logging'
import {
  convertDateTimeToSeconds,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formattedDate,
  getDateTime,
} from '~/src/Lib/Utils'

const logger = createLogger('Irrigation/Dialog#utils')

export const prepareApiPayload = (formState, durationUnits) => {
  const {
    editing,
    id,
    name,
    confirmed,
    confirmAt,
    unconfirmAt,
    relatedPhaseType,
    runOnce,
    roomLevel,
    phaseLevel,
    room,
    zones,
    phase,
    programs,
  } = formState

  const apiPayload = {
    ...(editing ? { id } : {}),
    ...(phase?.irrigationSchedule?.id && typeof phase?.irrigationSchedule?.id === 'number'
      ? { id: phase?.irrigationSchedule?.id } : {}),
    facility: null, // we don't have an option on the FE side to decide if this schedule is either facility or organization related, sending always null so far
    name,
    // confirmed flag applies the changes to the controller right away, confirmAt and unconfirmAt - at the specified datetime
    ...(typeof confirmed === 'boolean' ? { confirmed } : {}),
    ...(confirmAt ? { confirmAt } : {}),
    ...(unconfirmAt ? { unconfirmAt } : {}),
    phaseType: runOnce ? null : relatedPhaseType ?? phase?.phaseType,
    ...(roomLevel || phaseLevel ? { room: room || null, zones: zones || null } : {}),
    ...(phaseLevel ? { phase: phase?.id ?? phase } : {}),
    programs: programs.map(program => {
      const {
        id: programId,
        occurrences,
        durationValue,
        durationTypeSelectValue,
        durationInputSelectValue,
        repeatAfter,
        repeatAfterSelectValue,
        startTime,
      } = program

      // TODO: revisit units management approach, there has to be way easier solution!
      const durationType = durationTypes.find(dType => dType.value === durationTypeSelectValue)
      const durationUnitObj = durationType.type === TIME_DURATION
        ? durationUnits.find(unitObj => unitObj.name === TIME_UNIT_OPTIONS.find(m => m.value === durationInputSelectValue)?.name)
        : durationUnits.find(unitObj => unitObj.category.name === durationType.name)
      const intervalType = TIME_UNIT_OPTIONS.find(m => m.value === repeatAfterSelectValue) || {}
      const intervalUnitObj = durationUnits.find(unitObj => unitObj.name === intervalType.name)

      return {
        ...(editing ? { id: programId } : {}),
        count: occurrences || 1,
        durationUnit: durationUnitObj?.id,
        duration: durationValue,
        intervalUnit: intervalUnitObj?.id,
        interval: repeatAfter,
        startTime: startTime?.toISOTime({ includeOffset: false }),
      }
    })
  }

  return apiPayload
}

export const getProgramsFormShape = (programs, durationUnits) => {
  const scheduleStartTime = getDateTime(programs[0].startTime).setZone('utc', { keepLocalTime: true })

  return programs.map(program => {
    let currentProgramStartTime = getDateTime(program.startTime).setZone('utc', { keepLocalTime: true })
    if (convertDateTimeToSeconds(currentProgramStartTime) < convertDateTimeToSeconds(scheduleStartTime)) {
      currentProgramStartTime = currentProgramStartTime.plus({ days: 1 })
    }
    return {
      id: program.id,
      startTime: currentProgramStartTime,
      durationValue: program.duration,
      durationTypeSelectValue: getDurationTypeSelectValue(program.durationUnit, durationUnits),
      durationInputSelectValue: getDurationInputSelectValue(program.durationUnit, durationUnits),
      repeatAfter: program.interval,
      repeatAfterSelectValue: getRepeatAfterSelectValue(program.intervalUnit, durationUnits),
      occurrences: program.count,
    }
  })
}

// when trying to edit a schedule or use a template - we transform the schedule shape to appropriate form state shape
export const mapIrrigationToFormState = (schedule, durationUnits) => {
  if (!schedule) {
    return {}
  }
  const {
    id,
    facility,
    phaseType,
    name,
    room,
    zones,
    phase,
    programs,
  } = schedule

  return {
    id,
    facility: facility || null,
    runOnce: !phaseType,
    relatedPhaseType: phaseType,
    name,
    room: room || null,
    zones: zones || [],
    phase: phase || null,
    programs: programs ? getProgramsFormShape(programs, durationUnits) : [],
  }
}

export const getPhaseCultivarZones = ({ harvest, phase, phaseType }) => {
  const reqs = harvest?.cultivars && [phase, phaseType].some(Boolean)
  if (!reqs) return EMPTY_ARRAY
  const zonesKey = [(phaseType ?? phase.phaseType).toLowerCase(), 'Zones'].join('')
  const zoneSet = new Set()
  harvest.cultivars.forEach(({ [zonesKey]: zones }) => zones?.forEach(zone => zoneSet.add(zone)))
  return Array.from(zoneSet)
}

export const verifyIfIsLinked = (program, prevProgram) => {
  if (!prevProgram) return program
  const prevProgramEndTime = prevProgram.startTime.plus({ seconds: prevProgram.durationInSeconds })
  const isLinked = prevProgramEndTime.plus({ minutes: 1, seconds: (60 - prevProgramEndTime.second) % 60 }).toMillis() - program.startTime.toMillis() === 0
  return { ...program, isLinked }
}

export const getIrrigationInitialFormValues = ({
  accessedFromRoomDashboard = false,
  cachedSchedule,
  durationUnits,
  editing,
  harvest,
  isTemplate = false,
  phase,
  phases = EMPTY_OBJECT,
  room,
  schedule,
}) => {
  const mappedScheduleToFormState = mapIrrigationToFormState(cachedSchedule || schedule, durationUnits)

  // TODO: This implicit setting creates unexpected behavior when opening a schedule outside of the room dashboard
  // figuring out the context of the schedule before moving forward
  const roomLevel = accessedFromRoomDashboard

  const phaseLevel = Boolean(phase && !roomLevel)
  const template = isTemplate

  if (editing) {
    const programDurationInSeconds = getDurationInSecondsPerProgram(mappedScheduleToFormState.programs)

    const programs = mappedScheduleToFormState.programs.map((p, index) => {
      const prevProgram = mappedScheduleToFormState.programs[index - 1] ? { ...mappedScheduleToFormState.programs[index - 1], durationInSeconds: programDurationInSeconds[index - 1] } : null
      const program = { ...p, durationInSeconds: programDurationInSeconds[index] }
      return verifyIfIsLinked(program, prevProgram)
    })

    return {
      ...mappedScheduleToFormState,
      editing: true,
      saveAsTemplate: false,
      hasControllerErrors: false,
      roomLevel,
      phaseLevel,
      template,
      phase: phase ?? phases[mappedScheduleToFormState.phase] ?? null,
      relatedPhaseType: mappedScheduleToFormState.relatedPhaseType,
      programs
    }
  }

  const name = (roomLevel && `${room?.name} - Irrigation${schedule?.name ? ` (${schedule?.name})` : ''}`)
  || (phaseLevel && phase.name)
  || (template && (schedule?.name ? schedule.name : 'Irrigation template'))
  || ''
  const roomValue = roomLevel ? room?.id : null

  const roomZones = room?.zones && room.zones.filter(zone => zone.portId).map(zone => zone.id)
  const phaseZones = getPhaseCultivarZones({ harvest, phase })
  const zones = (roomLevel ? roomZones : phaseZones) ?? EMPTY_ARRAY

  // we can either create a schedule from scratch or use a template
  return schedule ? {
    ...mappedScheduleToFormState,
    id: null,
    editing: false,
    name,
    saveAsTemplate: false,
    hasControllerErrors: false,
    roomLevel,
    phaseLevel,
    template,
    phase: phase ?? null,
    room: roomValue,
    zones,
  } : {
    editing: false,
    saveAsTemplate: false,
    hasControllerErrors: false,
    roomLevel,
    phaseLevel,
    template,
    phase: phase ?? null,
    room: roomValue,
    runOnce: false,
    relatedPhaseType: null,
    name,
    zones,
    programs: [{ // we need to have at least one program card when entering the timing step
      startTime: getDateTime('08:00:00').setZone('utc', { keepLocalTime: true }),
      durationValue: null,
      durationTypeSelectValue: 1,
      durationInputSelectValue: 1,
      repeatAfter: null,
      repeatAfterSelectValue: 2,
      occurrences: null,
    }],
  }
}

export const getEmitterLabel = (room, units) => {
  let emitterLabel = ''
  if (room?.emittersPerPlant && room?.emitterFlowRate) {
    const {
      emitterFlowRate: flowRate,
      emitterFlowRateTimeUnit: timeUnit,
      emitterFlowRateVolumeUnit: volumeUnit,
      emittersPerPlant,
    } = room
    emitterLabel += `${emittersPerPlant} ${inflect('emitters', emittersPerPlant)}/plant`
    const volumeUnitSymbol = units[volumeUnit]?.symbol ?? '?'
    const timeUnitSymbol = units[timeUnit]?.symbol ?? '?'
    emitterLabel += ` @ ${flowRate}${volumeUnitSymbol}/${timeUnitSymbol}`
  }
  return [
    room?.name,
    room?.substrateName,
    emitterLabel,
  ].filter(Boolean).join(' - ')
}

// TODO: Moved from old directory: src/Harvest/Recipe/Phases/Features/Irrigation/utils.jsx
// (Irrigation utilities are due for a refactor. These aren't strictly dialog utils but they depend on form state mapping.)

export const getScheduleLabel = (schedule, durationUnits) => {
  const mappedSchedule = mapIrrigationToFormState(schedule, durationUnits)
  const duration = getTotalDurationInSeconds(mappedSchedule.programs)
  const { 0: firstProgram } = mappedSchedule.programs ?? EMPTY_ARRAY

  return firstProgram
    ? `Starts ${formattedDate(firstProgram.startTime, 'SHORT_TIME')} for ${formatDuration(duration)}`
    : ''
}

export const getStartTimeLabel = (schedule, durationUnits) => {
  const mappedSchedule = mapIrrigationToFormState(schedule, durationUnits)
  const { 0: firstProgram } = mappedSchedule.programs ?? EMPTY_ARRAY
  return firstProgram
    ? `Irrigation starts ${formattedDate(firstProgram.startTime, 'SHORT_TIME')}`
    : ''
}

export const getDurationLabel = (schedule, durationUnits) => {
  const mappedSchedule = mapIrrigationToFormState(schedule, durationUnits)
  const durationInSeconds = getTotalDurationInSeconds(mappedSchedule.programs)
  return `(for ${formatDuration(durationInSeconds)})`
}
