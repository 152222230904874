import memoize from 'memoize-one'

import * as yup from 'yup'

import {
  dateTimeSchema,
  getDateTime,
  getValidator,
  idValidator,
} from '~/src/Lib/Utils'

// Converts API startTime to minutes after midnight
export const parseStart = raw => {
  if (!raw || typeof raw === 'number') return raw
  const [rawHours, rawMinutes] = raw.split(':')
  return (Number(rawHours) || 0) * 60 + Number(rawMinutes || 0)
}

// converts startTime and endTime of a schedule and returns us startTime in minutes and duration in hours
export const getLightSchedule = roomLights => {
  if (roomLights) {
    const startTime = getDateTime(roomLights.startTime)
    const startTimeInMinutes = startTime.hour * 60 + startTime.minute
    const endTime = getDateTime(roomLights.endTime)
    const durationInHours = endTime.diff(startTime, 'hours').hours

    return {
      startTime: startTimeInMinutes,
      duration: durationInHours,
    }
  }

  return null
}

export const getStartOptions = memoize(() => {
  const beginning = getDateTime('now').startOf('day')
  const startOptions = Array.from({ length: 288 }).map((_, index) => {
    const minutes = index * 5
    return {
      value: minutes,
      label: beginning
        .plus({ minutes })
        .toLocaleString({ hour: 'numeric', minute: 'numeric' }),
    }
  })
  return startOptions
})

export const getDurationOptions = memoize(() => {
  const beginning = getDateTime('now').startOf('day')
  return Array.from({ length: 48 }, (_, i) => i + 1).map(index => {
    const time = beginning.plus({ minutes: index * 30 })
    const duration = time.diff(beginning).as('hours')
    return {
      value: duration,
      label: duration,
    }
  })
})

const startDateMessage = 'Please choose a start date'
const startTimeMessage = 'Please choose a lights on time'
const durationMessage = 'Please set the duration'
const thresholdMessage = 'Please enter an alert threshold'
const windowMessage = 'Please enter an alert window'

export const validationSchema = {
  room: idValidator('', true, 'Please choose a room'),
  startDate: dateTimeSchema
    .required(startDateMessage)
    .typeError(startDateMessage),
  startTime: yup.number()
    .integer()
    .min(0)
    .max(24 * 60 - 1)
    .required(startTimeMessage)
    .typeError(startTimeMessage),
  duration: yup.number()
    .min(0.01, durationMessage)
    .max(24)
    .required(durationMessage)
    .typeError(durationMessage),
  alertThreshold: yup.number()
    .min(0, thresholdMessage)
    .required(thresholdMessage)
    .typeError(thresholdMessage),
  alertWindow: yup.number()
    .min(0, windowMessage)
    .required(windowMessage)
    .typeError(windowMessage),
}

export const inRoomValidationSchema = yup.object(validationSchema)

export const [validate, isValid] = getValidator(validationSchema)

export const inPhaseValidationSchema = yup.object(validationSchema).shape({
  room: yup.mixed().nullable().optional(),
})

export const getInitialValues = (lightSchedule, room) => ({
  id: lightSchedule?.id,
  room: lightSchedule?.room ?? room?.id ?? room,
  startDate: getDateTime(lightSchedule?.startDate ?? 'now'),
  endDate: lightSchedule?.endDate ? getDateTime(lightSchedule.endDate) : null,
  startTime: lightSchedule?.startTimeMinutes ?? lightSchedule?.startTime ?? null,
  duration: lightSchedule?.durationHours ?? lightSchedule?.duration ?? 12,
  alert: lightSchedule?.alert ? lightSchedule?.alert : false,
  alertThreshold: lightSchedule?.alertThreshold ?? 20,
  alertWindow: lightSchedule?.alertWindow ?? 20,
})

export const prepareApiPayload = (payload, lightSchedule, phase, recipe, harvest, isRoomLightSchedule) => ({
  ...payload,
  id: lightSchedule?.id ?? null,
  phase: phase?.id ?? null,
  room: (isRoomLightSchedule || harvest) && !recipe ? payload.room : null
})
