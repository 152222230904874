import { path, pickAll } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { DateTime, Settings as LuxonSettings } from 'luxon'
import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { getTokens } from '~/src/Lib/Auth'
import {
  createCustomAction,
  ENTITIES_RECEIVED,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { defer, EMPTY_OBJECT, shallowEquals } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { User } from '~/src/Store/Schemas'

import { createAppIsReadySelector, createAuthenticatedSelector } from '../../utils'
import { CURRENT_FACILITY_ID, NEXT_FACILITY_ID } from './currentFacility'
import { prepareData } from './shape'
import { getLSM, setLSM } from './utils'

export { getLSM, setLSM }
export { shape } from './shape'

const logger = createLogger('Store/me')

const pickFacilityDefaults = pickAll(['app', 'id', 'name', 'organization'])

const passwordActions = getAsyncActionIdentifiers('change_password', 'me')
passwordActions.types.clear = `${passwordActions.types.prefix}_CLEAR`
const accountActions = getAsyncActionIdentifiers('account_save', 'me')
const changeMembershipActions = getAsyncActionIdentifiers(
  'update_current',
  'membership'
)
changeMembershipActions.types.clear = `${changeMembershipActions.types.prefix}_CLEAR`

const DEFAULT_MEMBERSHIP_STATE = {
  saving: false,
  success: null,
  error: null,
}

const {
  actionIdentifiers: saveProfilePhoto,
  actionReducer: profilePhotoReducer,
} = createCustomAction({
  actionType: 'save', actionName: 'profile_photo', reducerKey: 'photo', loadingKey: 'saving'
})

const initializeLuxon = store => {
  const member = store.selectMemberships()?.[store.selectMyCurrentMembershipId()]
  if (store.selectChangingMembership()) {
    defer(() => store.dispatch({ type: changeMembershipActions.types.clear }), defer.priorities.low)
  }
  if (!member) {
    console.warn('[initializeLuxon] No membership set when initializing third-party services.')
    return
  }

  const facility = store.selectFacilities()?.[member.facility]
  const facilityTZIsValid = DateTime.local().setZone(facility.timezone).isValid
  // TODO: when we upgrade to luxon v3, we'll need to update this to use the new API
  // https://moment.github.io/luxon/#/upgrading?id=system-zone
  LuxonSettings.defaultZone = facilityTZIsValid
    ? facility.timezone
    : (
      typeof Intl?.DateTimeFormat !== 'undefined' && new Intl.DateTimeFormat()?.resolvedOptions()?.timeZone
    ) || 'America/Los_Angeles'
  LuxonSettings.defaultLocale = store.selectLocale()
}

const meBundle = createAsyncResourceBundle({
  name: 'me',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: async ({ apiFetch, dispatch, store }) => {
    const { current: currentFacility, next: nextFacility } = store.selectCurrentFacilityRoot()
    const { facility: cachedFacility } = getLSM()
    let output
    const fetchArgs = ['/me/']
    if (nextFacility || currentFacility) {
      fetchArgs.push(null, { headers: { 'X-Facility': nextFacility || currentFacility || cachedFacility } })
    }
    const response = await apiFetch(...fetchArgs)
    if (typeof response !== 'object') {
      output = store.selectMe()
    } else {
      const { entities, result } = normalize(response, User)
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      output = { ...response, results: result }
      if (nextFacility) {
        dispatch({
          type: 'BATCH_ACTIONS',
          actions: [{
            type: CURRENT_FACILITY_ID,
            payload: nextFacility,
          }, {
            type: NEXT_FACILITY_ID,
            payload: null,
          }]
        })
      } else if (!currentFacility) {
        const currentMembership = response.memberships.find(m => (
          cachedFacility
            ? m.facility.id === cachedFacility
            : !shallowEquals(m.facility, pickFacilityDefaults(m.facility ?? EMPTY_OBJECT))
        ))
        if (currentMembership) {
          dispatch({ type: CURRENT_FACILITY_ID, payload: currentMembership.facility.id })
        }
      }
    }
    defer(() => initializeLuxon(store), defer.priorities.low)
    return output
  },
})

const flagsHandler = {
  get: (flags, property) => {
    const prop = String(property)
    if (prop.includes('.')) {
      return Boolean(path(prop.split('.'), flags))
    }
    return prop in flags ? flags[prop] : false
  }
}

const reducer = reduceReducers(meBundle.reducer, (state, action) => {
  if (action.type && action.type.startsWith(saveProfilePhoto.types.prefix)) {
    return profilePhotoReducer(state, action)
  }

  switch (action.type) {
    case changeMembershipActions.types.prefix:
      return {
        currentMembership: action.payload,
        changingMembership: {
          ...DEFAULT_MEMBERSHIP_STATE,
          success: true,
        },
      }
    case changeMembershipActions.types.start:
      return {
        ...state,
        currentMembership: action.payload,
        changingMembership: {
          saving: true,
          success: null,
          error: null,
        },
      }
    case changeMembershipActions.types.succeed:
      return {
        ...state,
        changingMembership: {
          saving: false,
          success: true,
          error: null,
        },
      }
    case changeMembershipActions.types.clear:
      return {
        ...state,
        changingMembership: {
          saving: false,
          success: null,
          error: null,
        },
      }
    case passwordActions.types.start:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          saving: true,
          success: null,
          error: null,
        },
      }
    case passwordActions.types.fail:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          saving: false,
          success: false,
          error: action.payload,
        },
      }
    case passwordActions.types.succeed:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          success: true,
          saving: false,
        },
      }
    case passwordActions.types.clear:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          success: null,
          saving: false,
          error: null,
        },
      }
    case accountActions.types.start:
      return {
        ...state,
        account: {
          ...state.account,
          saving: true,
          success: null,
          error: null,
        },
      }
    case accountActions.types.fail:
      return {
        ...state,
        account: {
          ...state.account,
          saving: false,
          success: false,
          error: action.error,
        },
      }
    case accountActions.types.succeed:
      return {
        ...state,
        account: {
          ...state.account,
          saving: false,
          success: true,
        },
      }
    default:
      return state
  }
})

export default {
  ...meBundle,
  init: initializeLuxon,
  reducer,
  doAccountSave: data => async ({ dispatch, apiFetch }) => {
    const cleanData = prepareData(data)
    dispatch({ type: accountActions.types.start })
    try {
      const payload = await apiFetch('/me/', cleanData, {
        method: 'PUT',
        allowedCodes: [400],
      })
      if ('id' in payload) {
        const { entities } = normalize(payload, User)
        dispatch({ type: ENTITIES_RECEIVED, payload: entities })
        dispatch({ type: accountActions.types.succeed })
      } else {
        const msg = Object.keys(payload)
          .map(key => payload[key].join('\n'))
          .join('\n')
        dispatch({ type: accountActions.types.fail, error: msg })
      }
    } catch (err) {
      dispatch({ type: accountActions.types.fail, error: 'Unable to save account information.' })
    }
  },
  doChangePassword: data => async ({ dispatch, apiFetch, store }) => {
    dispatch({ type: passwordActions.types.start })
    try {
      const payload = await apiFetch('/me/password/', data, { method: 'PUT' })
      if (payload.success) {
        dispatch({ type: passwordActions.types.succeed })
        store.doAddSnackbarMessage('Successfully updated password.')
        return true
      }
      dispatch({ type: passwordActions.types.fail, payload: payload.message })
      return false
    } catch (err) {
      dispatch(passwordActions.creators.passwordFail(err))
      return false
    }
  },
  doClearChangePassword: () => ({ type: passwordActions.types.clear }),
  doEulaAccept: () => async ({ apiFetch, store }) => {
    try {
      await apiFetch.create('/eula/')
      await store.doFetchMe()
      return true
    } catch (err) {
      console.error('EULA acceptance failed with error:', err)
      return false
    }
  },
  doProfilePhotoSave: ({ membershipId, photo }) => async ({ store, dispatch, apiFetch }) => {
    dispatch({ type: saveProfilePhoto.types.start })
    let result = false
    try {
      result = await apiFetch(
        `/memberships/${membershipId}/`,
        { id: membershipId, photo },
        { method: 'PATCH' }
      )
      dispatch({ type: saveProfilePhoto.types.succeed, payload: result })
      defer(() => store.doFetchMe(), defer.priorities.low)
    } catch (error) {
      result = error
      dispatch({ type: saveProfilePhoto.types.fail, error })
    }
    return result
  },
  doCurrentMembershipSet: payload => ({ type: changeMembershipActions.types.prefix, payload }),
  selectFullMe: createSelector(
    'selectMe',
    'selectUsers',
    (me, users) => (me && me.id in users ? users[me.id] : null)
  ),
  selectCurrentMembership: createAppIsReadySelector({
    dependencies: ['selectMe', 'selectCurrentFacilityId', state => state?.entities?.memberships],
    resultFn: (me, currentFacility, memberships) => (
      memberships ? Object.values(memberships).find(m => m.user === me.id && m.facility === currentFacility) : EMPTY_OBJECT
    )
  }),
  selectMyCurrentMembershipId: createAppIsReadySelector({
    dependencies: ['selectCurrentMembership'],
    resultFn: currentMembership => currentMembership?.id,
  }),
  selectCurrentFacility: createSelector(
    state => state?.entities?.facilities,
    'selectCurrentMembership',
    (facilities, membership) => {
      if (membership?.facility && facilities?.[membership.facility]) {
        return facilities[membership.facility]
      }
      try {
        const { facility } = getLSM()
        if (facility && facilities?.[facility]) {
          logger.debug('returning facility from LSM')
          return facilities[facility]
        }
        return { id: facility }
      } catch (error) {
        console.error('error:', error)
      }
      return undefined
    }
  ),
  selectCurrentOrganization: createSelector(
    'selectCurrentFacility',
    'selectOrganizations',
    (facility, organizations) => organizations?.[facility?.organization] ?? EMPTY_OBJECT
  ),
  selectCurrentRole: createSelector(
    'selectCurrentMembership',
    'selectFacilityRoles',
    (currentMembership, roles) => roles?.[currentMembership?.role]?.key ?? 'GUEST'
  ),
  selectMyPermissions: createSelector(
    'selectCurrentMembership',
    'selectPermissions',
    (currentMembership, permissions) => (
      currentMembership && Array.isArray(currentMembership.effectivePermissions)
        ? Object.values(permissions).reduce((acc, p) => ({
          ...acc,
          [p.key]: currentMembership.effectivePermissions.includes(p.key)
        }), EMPTY_OBJECT)
        : EMPTY_OBJECT
    )
  ),
  selectMyFlags: createSelector(
    'selectCurrentFacility',
    'selectCurrentOrganization',
    (facility, org) => {
      const flags = org.flags ?? {}
      if (facility?.metrc) {
        flags.METRC = EMPTY_OBJECT
      }
      if (facility?.outdoor) {
        flags.OUTDOOR = EMPTY_OBJECT
      }
      return new Proxy(flags ?? EMPTY_OBJECT, flagsHandler)
    }
  ),
  selectIsAdmin: createSelector(
    'selectCurrentMembership',
    currentMembership => currentMembership?.admin ?? false
  ),
  selectIsMultiLicense: createSelector(
    'selectCurrentFacility',
    currentFacility => {
      if (!currentFacility) return false
      return (currentFacility.licenses?.length ?? 0) > 1
    }
  ),
  selectChangingMembership: createSelector(
    'selectMeRaw',
    me => {
      if (!me) return false
      const { changingMembership } = me ?? EMPTY_OBJECT
      if (!changingMembership) return false
      return !!changingMembership.saving
    }
  ),
  selectMeShouldUpdate: createAuthenticatedSelector({
    defaultReturnValue: false,
    dependencies: [
      'selectIsOnline',
      'selectMeRaw',
      'selectCurrentFacilityId',
      'selectNextFacilityId'
    ],
    resultFn: (online, meRaw, currentFacility, nextFacility) => {
      const { data, failedPermanently, isLoading, isStale, isWaitingToRetry } = meRaw ?? {}
      if (!online || failedPermanently || isLoading || isWaitingToRetry) return false
      if (data == null) return true

      // in addition to the usual asyncResourceBundle checks, we also check if the current facility has changed
      return Boolean(isStale || (nextFacility && nextFacility !== currentFacility))
    },
  }),
  reactMeFetch: createSelector(
    'selectAuth',
    'selectMeShouldUpdate',
    'selectMeIsLoading',
    'selectMe',
    ({ authenticated }, shouldUpdate, isLoading, me) => {
      if (authenticated && shouldUpdate) {
        logger.debug('[reactMeFetch] updating me data because it is stale')
        return { actionCreator: 'doFetchMe', priority: REACTOR_PRIORITIES.HIGH }
      }
      if (!isLoading && me != null) {
        const { filestack } = me
        if (filestack && filestack.policy) {
          // filestackExpiry is seconds since the Unix epoch
          const filestackExpiry = JSON.parse(atob(filestack.policy)).expiry
          // if the filestack policy has expired, we need to update the me data
          if ((filestackExpiry * 1000) < Date.now()) {
            logger.debug('[reactMeFetch] updating me data because filestack policy has expired')
            return { actionCreator: 'doFetchMe', priority: REACTOR_PRIORITIES.HIGH }
          }
        }
      }
      return undefined
    }
  ),
  reactCurrentMembership: createAuthenticatedSelector({
    dependencies: [
      'selectCurrentFacilityId',
      'selectNextFacilityId',
      'selectMe'
    ],
    resultFn: (currentFacility, nextFacility, me) => {
      if (nextFacility) return null
      logger.debug('reactCurrentMembership', { currentFacility, nextFacility, me })
      const lsm = getLSM()
      if (currentFacility && me && Array.isArray(me.memberships)) {
        if (lsm.facility !== currentFacility) {
          const currentMembership = me.memberships.find(m => m.facility.id === currentFacility)
          if (currentMembership) {
            const { id, facility } = currentMembership
            setLSM({ id, facility: facility.id })
          }
        }
        return null
      }
      const { facilities } = getTokens()
      if (Array.isArray(facilities) && facilities.length) {
        const [facility] = facilities.slice().sort((a, b) => a.id - b.id)
        if (facility) {
          setLSM({ facility: facility.id })
        }
      }
      return null
    }
  }),
  persistActions: [
    ...meBundle.persistActions,
    ...Object.values(changeMembershipActions.types),
  ],
}
