import { useCallback } from 'react'

import { identity } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import queryString from 'query-string'

/**
 * Entity relative URL creation function (basename pre-applied)
 * @callback getUrl
 * @param {string} basename Entity base path
 * @param {{id: 123, action: 'edit'}} source Entity or config object with id and/or action (both optional)
 * @param {?{ n: 1, s: 'foo' }} queryObj An object of GET params
 * @returns {string} The pathname and (optionally) query portions of a URL
 */
const getSchemaUrl = (basename, source = {}, queryObj = null) => {
  const baseUrl = `/${basename}`
  const { id = '', action = '' } = source
  if (!id && !action) return baseUrl
  const pathname = `${baseUrl}/${[id, action].filter(identity).join('/')}`
  return queryObj ? `${pathname}?${queryString.stringify(queryObj)}` : pathname
}

/**
 * Creates a url creation function whose pathname is anchored by the basename string
 * @param {string} basename
 * @returns {getUrl}
 */
export const getUrlFactory = basename => getSchemaUrl.bind(null, basename)

const defaultUrlsConfig = {
  list: {},
  create: { action: 'new' },
  edit: { id: ':id', action: 'edit' },
  schedule: { id: ':id', action: 'review' },
  view: { id: ':id' },
}
export const getUrlDefaults = basename => {
  const getUrl = getUrlFactory(basename)
  return Object.entries(defaultUrlsConfig).reduce(
    (urls, [name, opts]) => ({
      ...urls,
      [name]: getUrl(opts),
    }),
    { getUrl }
  )
}

/**
 * Helper to create URLs for a given facility
 * @returns {function(string): string}
 */
export const useFacilityUrlHelper = () => {
  const { currentFacilityId } = useConnect('selectCurrentFacilityId')

  const urlPrefixer = useCallback(
    urlSuffix => (
      currentFacilityId
        ? `/f/${currentFacilityId}${urlSuffix}`
        : urlSuffix
    ),
    [currentFacilityId]
  )
  return urlPrefixer
}
