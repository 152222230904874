import { isValidElement, memo, useMemo } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'

import { Check, Close, HorizontalRule } from '@mui/icons-material'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useFormikContext } from 'formik'
import { humanize } from 'inflection'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import T from '~/src/UI/Shared/Typography'

import Tooltip from './Tooltip'

const logger = createLogger('RequirementsList')

const formatFields = ['description', 'error']
const isFormatted = toTest => formatFields.every(name => name in toTest)

/**
 * A component for rendering a form's requirments
 * @component
 * @alias RequirementsList
 * @named_export Component
 * @example
 * import { Formik } from 'formik'
 * <Formik
 *   initialValues={{
 *     funcField: 'Do the funk. The funky funk.'
 *   }}
 *   validate={() => ({
 *     errorField: 'This field is in an error state.'
 *   })}
 * >
 *   <Box className="default-display" position="relative">
 *     <Box flex="1 1 auto">
 *       <RequirementsList
 *         showWithoutErrors
 *         tooltip={false}
 *         fields={{ fieldName: 'The requirement for this field' }}
 *       />
 *     </Box>
 *     <Box flex="1 1 auto">
 *       <RequirementsList
 *         fields={{
 *           funcField: values => ({
 *             description: 'We want the funk; give up the funk!',
 *             error: !String(values.funcField).match(/(funk\s*){3,}/i)
 *           })
 *         }}
 *       >
 *         <T.Subtitle>Tooltipped UI</T.Subtitle>
 *       </RequirementsList>
 *     </Box>
 *     <Box flex="1 1 auto">
 *       <RequirementsList
 *         showWithoutErrors
 *         tooltip={false}
 *         fields={{
 *           objectField: {
 *             description: 'I have not met my requirement',
 *             error: true,
 *           },
 *           fieldName: 'The requirement for this field'
 *         }}
 *       />
 *     </Box>
 *   </Box>
 * </Formik>
 */

const RequirementsList = ({
  children,
  showWithoutErrors = false,
  tooltip = true,
  fields,
  errors = EMPTY_OBJECT,
  values = EMPTY_OBJECT,
  ...tooltipProps
}) => {
  const { palette } = useTheme()
  const data = values?.password1 ?? ''

  const list = useMemo(() => (
    !fields || !Object.keys(fields).length
      ? EMPTY_ARRAY
      : Object.entries(fields).flatMap(([field, descr]) => {
        let description = typeof descr === 'function'
          ? descr(values, errors)
          : descr
        if (typeof description === 'string' && description.match(/^[a-z]/)) {
          description = humanize(description)
        } else if (description && typeof description === 'object' && isFormatted(description)) {
          return {
            ...description,
            key: description.key ?? [description.description, field].join('-')
          }
        } else if (description && Array.isArray(description)) {
          return description.every(isFormatted) ? description : null
        }
        if (!description) return null
        const error = errors[field]
        return { description, error, key: [description, field].join('-') }
      }).filter(Boolean)
  ), [values, fields, errors])

  if (!showWithoutErrors && (!list.length || list.every(({ error }) => !error))) {
    return children
  }

  const content = (
    <List>
      {list.length ? list.map(({ description, error }) => {
        let colorText = null
        if (error && data !== '') {
          colorText = 'error'
        }
        return (
          <ListItem
            key={description}
            disablePadding
            sx={{
              padding: '4px 16px',
            }}
          >
            <ListItemIcon sx={{ minWidth: '2.5rem' }}>
              {error && !data.length ? <HorizontalRule /> : null}
              {error && data !== '' ? <Close sx={{ fontSize: '1.8rem' }} color="error" /> : null}
              {!error && data !== '' ? <Check sx={{ fontSize: '1.8rem', color: 'success.main' }} /> : null}
            </ListItemIcon>
            <ListItemText>
              <T color={!error && data !== '' ? palette.success.main : colorText}>{description}</T>
            </ListItemText>
          </ListItem>
        )
      }) : (
        <ListItem>
          <ListItemIcon><Check sx={{ fontSize: '1.8rem', color: 'success.main' }} /></ListItemIcon>
          <ListItemText>
            <T color={palette.success.main}>{i18n`Good to go!`}</T>
          </ListItemText>
        </ListItem>
      )}
    </List>
  )

  return tooltip && children && isValidElement(children)
    ? <Tooltip variant="paper" {...tooltipProps} title={content}>{children}</Tooltip>
    : content
}
RequirementsList.displayName = 'RequirementsList'
RequirementsList.propTypes = {
  children: PropTypes.element.isRequired,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.object)])),
  fields: PropTypes.objectOf(PropTypes.oneOfType([
    /** { fieldName: 'The requirement for this field' } error state is controlled by errors.fieldName */
    PropTypes.string,
    /**
     * { fieldName: function(values, errors): string|{ description: string, error: bool } }
     * if the return is a string, error state is controlled by errors.fieldName
     * if the return is an object, error state is controlled by the error boolean
     */
    PropTypes.func,
    /** Completely controls the rendering for the field's requirements */
    PropTypes.shape({ description: PropTypes.string, error: PropTypes.bool })
  ])).isRequired,
  showWithoutErrors: PropTypes.bool,
  tooltip: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.shape({
    password1: PropTypes.string,
    password2: PropTypes.string
  })),
}

export const FormikRequirementsList = memo(props => {
  const { errors, values } = useFormikContext()
  return <RequirementsList errors={errors} values={values} {...props} />
})

const Memoized = memo(RequirementsList)
export default Memoized
