import { useConnect } from 'redux-bundler-hook'

import getGlobal from '~/src/Lib/getGlobal'

import { FACILITY_SEGMENT_PATTERN } from './constants'

const RedirectToFacilityPrefixed = () => {
  const { currentFacilityId, routeInfo } = useConnect(
    'selectCurrentFacilityId',
    'selectRouteInfo',
  )

  if (!FACILITY_SEGMENT_PATTERN.test(routeInfo.url ?? '')) {
    console.warn(`RedirectToFacilityPrefixed: ${routeInfo.url} does not contain a facility segment`)
    const { location } = getGlobal()
    if (location && location.href) location.href = `/f/${currentFacilityId}${routeInfo.url}`
  }

  return null
}

export default RedirectToFacilityPrefixed
