import { pick } from 'ramda'

import { inflect } from 'inflection'

import createEntityBundle, { asyncActionFactory } from '~/src/Lib/createEntityBundle'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { facilityScope as scope, Reading as schema } from '~/src/Store/Schemas'
import DataTypeName from '~/src/UI/Shared/DataTypeName'

const name = 'readings'

const allowedFields = [
  'id',
  'room',
  'zone',
  'dataType',
  'ts',
  'unit',
  'value',
  'comments',
  'cultivar',
  'start',
  'end',
  'packages',
  'plants',
  'harvestBatches',
  'plantBatches'
]
const prepareData = pick(allowedFields)

const snackbar = ({ action, status, payload, store, dispatch }) => {
  let message
  if (action === 'save') {
    if (status === 'succeed') {
      message = payload.id ? 'Updated' : 'Created'
    } else {
      message = payload.id ? 'Unable to update' : 'Unable to create'
    }
  } else {
    message = status === 'succeed' ? 'Deleted' : 'Unable to delete'
  }
  const dataType = Object.values(store.selectDataTypes()).find(
    dt => dt.id === payload.dataType
  )
  const {
    rooms: { [payload.room]: room },
    zones: { [payload.zone]: zone },
  } = store.select(['selectRooms', 'selectZones'])
  dispatch({
    actionCreator: 'doAddSnackbarMessage',
    args: [
      <>
        {message} <DataTypeName name={dataType.name} />
        {` reading in ${[room, zone]
          .filter(Boolean)
          .map(l => l.name)
          .join(', ')}.`}
      </>,
    ],
  })
}

const bulkSaveAction = asyncActionFactory({
  action: 'bulk_save',
  name,
  schema: [schema],
  snackbar: ({ status, payload, dispatch, store }) => {
    let message
    if (status === 'succeed') {
      const room = store.selectRooms()[payload[0].room]
      message = `Recorded ${payload.length} ${inflect('readings', payload.length)} in ${room?.name ?? 'unknown room'}.`
    } else {
      message = `Unable to record ${inflect('readings', payload.length)}.`
    }
    dispatch({
      actionCreator: 'doAddSnackbarMessage',
      args: [message],
    })
  },
  handler: markAnnotationsOutdatedWrapper(
    ({ payload, apiFetch }) => apiFetch('/manualReadings/bulk/', payload, { method: 'POST' }),
    { invalidateChart: true },
  ),
})

const writesConfig = { wrapper: handler => markAnnotationsOutdatedWrapper(handler, { invalidateChart: true }) }

const initialBundle = createEntityBundle({
  name,
  apiConfig: {
    prepareData,
    schema,
    snackbar,
    url: 'manualReadings',
    // handler configs
    fetch: { snackbar: false },
    save: writesConfig,
    delete: writesConfig,
  },
  scope,
})

export default {
  ...initialBundle,
  reducer: (state, action) => {
    switch (action.type) {
      case bulkSaveAction.types.start:
        return {
          ...state,
          bulk: {
            ...state.bulk,
            saving: true,
            success: null,
          },
        }
      case bulkSaveAction.types.fail:
        return {
          ...state,
          bulk: {
            ...state.bulk,
            saving: false,
            success: false,
            error: action.error,
          },
        }
      case bulkSaveAction.types.succeed:
        return {
          ...state,
          bulk: {
            ...state.bulk,
            saving: false,
            success: true,
          },
        }
      default:
        return initialBundle.reducer(state, action)
    }
  },
  [bulkSaveAction.actionName]: bulkSaveAction,
}
